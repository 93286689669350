.Catalog {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .ios & {
    position: static;
  }
  .LoadingIcon{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 999;
    height: 110px;
  }
}

$catalog-footer-height: 70px;
$dim-category: 200px;
$dim-category-margin: 5px;

.Category-001-399 .Footstone {
  color: $color-catalog-001-399;
}

.Category-400-499 .Footstone {
  color: $color-catalog-400-499;
}

.Category-500-599 .Footstone {
  color: $color-catalog-500-599;
}

.Category-600-699 .Footstone {
  color: $color-catalog-600-699;
}

.Category-700-799 .Footstone {
  color: $color-catalog-700-799;
}

.Category-800-899 .Footstone {
  color: $color-catalog-800-899;
}

.Category-CIN .Footstone {
  color: $color-catalog-CIN;
}

.Category-TBL .Footstone {
  color: $color-catalog-TBL;
}
.FilterButtons{
  float:left;
  &.sticky {
    padding-top: 60px;
  }
}

.FilterLayout{
  z-index: 999;
  color:"#fff";

  // float:left;

  .FilterChoices {
    background-color: white;
    border: 2px solid #b4b4b4;
    padding: 10px;
    margin-top: -3px;
    &.sticky {
      border:none;
      padding: 5px;
    }
  }

  /* The sticky class is added  it reaches its scroll position */
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align:center;
    background-color: white;
    opacity:0.9;
  }

  h3 {
    @extend %reset;
    color: $color-grey-6;
    font: font-default(20, normal, 25px);
    margin-bottom: 5px;
    text-align: center;
  }
  .checkbox{
    padding-left: 10px;
  }
}
@mixin getColor($color){
  margin-left:15px;width:20px;height:20px; border: none;
  border-radius: 15px;
  box-shadow: 0px 2px 4px #888886bf;
}
.GraniteColors {
  .gColors {
    background-color: white;
    border: 2px solid #b4b4b4;
    padding: 8px;
    border-radius: 4px;
    &.sticky {
      border:none;
      padding: 5px;
    }
    .colorFilter {
      @include getColor("white");
      :active {
          background-color: yellow;
          border: none !important;
          }
    }
    .AllColors {
      height: 23px; 
      background:#149edc;
      border-radius: 30px;
      color:#FFFFFF;
      font-size:12px;
      padding:0px 10px 0px 10px;
    } 
  }
}
.SearchFilter{
  ._FilterL2{
    margin-bottom: 12px;
    > div {
      display: inline-block;
      margin-right: 12px;
      margin-bottom: .5rem;
      vertical-align: top;
    }
    label {
      margin-bottom: .5rem;
      h3 {
        color: #676767;
        font: 400 2em "Cuprum", Geneva, Tahoma, Verdana, sans-serif;
        margin-bottom: 5px;
        text-align: center;
      }
    }
    ._FilterCatalog {
      ._Autocomplete {
        > div  {
          width: 250px;
          display: inline-block;
          vertical-align: bottom;
          z-index: 2;
          > div {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
          }
        }
        button.Find {
          background: #324854;
          color: white;
          border: 2px solid #b4b4b4;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 4px;
          margin-bottom: -2px;
          padding: 6px;
        }
      }
      input{
        text-transform: uppercase;
      }
    }
    .FAuto-select > div {
      font-size:16px;
      border-color: #b4b4b4;
      border-width: 2px;
      width: 152px;
    }
  }
}
.Search {
  display: block;
  margin: 0 0 20px 0;
  text-align: center;
  float: right;
  padding-right: 10px;
  h3 {
    @extend %reset;
    color: $color-grey-6;
    font: font-default(20, normal, 25px);
    margin-bottom: 5px;
  }
  .Field {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
  input.Input {
    padding-left: 40px;
    position: relative;
    vertical-align: middle;
    width: 15em;
    z-index: 10;
  }
  .material-icons {
    @include material-size(24px, 36px);
    color: $color-grey-B;
    left: 0;
    position: absolute;
    top: 0;
    vertical-align: middle;
    z-index: 15;
  }
  button.Find {
    background: $color-grey-B;
    border-color: $color-grey-B;
    color: $color-white;
    vertical-align: middle;
  }
  ._FilterCatalog {
    ._Autocomplete {
      width: 250px;
      display: inline-block;
    }
    .wrapper {
      border-radius: 0;
      z-index: 1;
      border: 2px solid #b4b4b4;
      color: #303030;
      font: 400 1.6em "Cuprum", Geneva, Tahoma, Verdana, sans-serif;
      padding: 8px 10px 8px 0;
    }
    input{
      text-transform: uppercase;
    }
  }
}
.MonumentSearchBox {
  height: 100%;
  min-height: 750px;
  overflow: hidden;
  position: relative;
  z-index: 100;
  //margin-bottom:100px;

  @media only screen and (max-width: $dim-category * 6) {
    height: auto;
  }

  .Categories {
    @extend %columns;
    margin: (-710px/2) auto (710px/2) auto;
    padding: 10px;
    position: relative;
    top: 50%;
    width: $dim-category * 6;
    box-sizing: unset;

    @media only screen and (max-width: $dim-category * 6) {
      margin: 20px auto;
      padding: 10px;
      position: static;
      top: 0;
      width: $dim-category * 4;
    }

    @media only screen and (max-width: $dim-category * 4) {
      width: $dim-category * 3;
    }

    @media only screen and (max-width: $dim-category * 3) {
      width: $dim-category * 2;
    }

    @include smartphone {
      width: 100%;
      margin: 0;
      box-sizing: border-box;
    }
    .Count {
      position: absolute;
      right: 0;
      top: 5px;
      margin-right: 20px;
      font-size: 17px;
      margin-top: 5px;
    }

    .ios & {
      top: 0;
      margin: 20px auto;
    }



    figure {
      color: $color-white;
      height: $dim-category - ($dim-category-margin * 2);
      @include smartphone {
        height: 160px;
      }
      margin: $dim-category-margin;
      position: relative;
      overflow: hidden;
      border-radius: $dim-dashboard-radius;
    }
    img {
      height: auto;
      position: relative;
      @include transition(opacity 0.35s ease, top 0.35s ease);
      width: 100%;
    }
    .figcaption {
      bottom: 0;
      left: 5%;
      padding: 10px 0;
      position: absolute;
      @include transition(all 0.35s ease);
      width: 90%;

      @include smartphone {
        font-size: 10px;
      }
    }
    h2 {
      @extend %reset;
      display: block;
      font: font-alt(14px, bold, 140%);
      @include transition(all 0.35s ease);
    }
    p {
      @extend %reset;
      display: block;
      font: font-alt(14px, normal, 140%);
    }
    .Category-001-399,
    .Category-New,
    .Category-Favorite {
      h2 {
        position: absolute;
        right: 0;
        bottom: 50%;
        width: 50%;
        @include transform(translateY(50%));
        @include smartphone {
          left: 0;
          top: 10%;
          bottom: unset;
          width: 100%;
        }
      }
      &:hover {
        h2 {
          width: 100%;
          @include smartphone {
            top: 40%;
          }
        }

        .figcaption {
          bottom: calc(50% - 40px);
        }
      }
    }
    .Category-New, .Category-Favorite {
      figure img {
        transform: scale(1.3) translate(13px, 33px);
      }
    }
    @include smartphone {
      .Category-New {
        figure img {
          transform: scale(1.3) translate(30px, 30px);
        }
      }
      .Category-Favorite {
        figure img {
          transform: scale(1.3) translate(40px, 30px);
        }
      }
    }
    .Category-001-399 {
      width: $dim-category * 2;
      @include smartphone {
        width: 100%;
      }
    }
    .Largecat {
      width: $dim-category * 3 / 2;
      @include smartphone {
        width: 100%;
      }
      @media only screen and (max-width: $dim-category * 3) {
        width: $dim-category * 2;

        figure img {
          height: 100%;
          width: auto;
        }
      }
    }
    .Category-001-399 figure {
      background-color: $color-catalog-001-399;
    }
    .Category-400-499 figure {
      background-color: $color-catalog-400-499;
    }
    .Category-500-599 figure {
      background-color: $color-catalog-500-599;
    }
    .Category-600-699 figure {
      background-color: $color-catalog-600-699;
    }
    .Category-700-799 figure {
      background-color: $color-catalog-700-799;
    }
    .Category-800-899 figure {
      background-color: $color-catalog-800-899;
    }
    .Category-CIN figure {
      background-color: $color-catalog-CIN;
    }
    .Category-TBL figure {
      background-color: $color-catalog-TBL;
    }
    .Category-New figure {
      background-color: $color-new;
    }
    .Category-Favorite figure {
      background-color: $color-favorite;
    }
    .Category-Exclusive figure {
      background-color: $color-catalog-700-799;
    }
    .Category-SEM figure {
      background-color: #cc6e9b;
    }
    .Category-ACC figure {
      background-color: #dd7a89;
    }
    .Category-PLT figure {
      background-color: #7878a3;
    }
  }
}
.FilterButtons{
  @include smartphone {
    width: 100%;
  }
  div {
    @include column(left);
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    @include transition(transform 0.35s ease);
    width: $dim-category;
    @include smartphone {
      width: 50%;
    }
    &:hover {
      // @include transform(scale(1.02));
      img {
        @include opacity(0.10);
        top: 0;
      }
      .figcaption {
        bottom: 50%;
        @include transform(translateY(50%));
      }
    }
  }
}

@include keyframes(spin) {
  0% {
    transform: rotateZ(0deg)
  }
  100% {
    transform: rotateZ(360deg)
  }
}

.Catalog {
  .ListTitle {
    font: font-default(20, normal, 24px);
    text-transform: uppercase;
    margin: 0 0 0 20px;
    color: $color-grey-6;

    &-Featured {
      color: $color-blue;
    }
  }
  .container{
    max-width: 100% !important;
  }
  .ListView {
    // Permet d'avoir toujours le même scroll quand la liste de résultats
    // fait moins de 100% de haut
    min-height: 100%;
    padding-bottom: 65px;
    z-index: 200;
    .AccessoryCard{
      min-height: 252px;
    }
  }

  .FilterAccessories {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 2px;
    box-shadow: 0 2px 5px #2a30323d;
    margin-bottom: 50px;
    margin-left: 35px;
    margin-right: 35px;
    .FilterOption {
      font-size: 20px;
      background-color: #FFFFFF;
      margin-right: 10px;
      margin-left: 10px;
      padding: 10px 20px 10px 20px;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: grey;
      height: 65px;
      cursor: pointer;
      i{
        margin-right: 10px;
      }
      &:hover{
        color: #149EDC;
        border-bottom: 4px solid #149EDC;
      }
      &.active{
        color: #149EDC;
        border-bottom: 4px solid #149EDC;
      }
    }
  }
}

.MonumentCard {
  @extend %list-item;

  &.Unavailable {
    position: relative;
    .UnavailableMessage {
      @extend %vertically-centered-container;
      bottom: 0;
      display: table-cell;
      left: 0;
      @include opacity(0);
      position: absolute;
      right: 0;
      top: 0;
      @include transition(all 0.4s ease);
      z-index: 650;
      p {
        color: $color-blue;
        display: inline-block;
        font: font-default(16, bold, 20px);
        margin: 0 20px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }
    .Monument {
      cursor: not-allowed;
      &:hover {
        .UnavailableMessage {
          @include opacity(1);
        }
        img {
          @include opacity(0.7);
        }
      }
    }
  }

  div.Monument {
    @extend %image-only;
    cursor: pointer;
    display: block;
    min-height: 120px;
    position: relative;
    text-decoration: none;
  }

  // Les images ne doivent pas redimensionner les chunks les contenant
  // quand elles s'affichent, car la hauteur des chunks ne correspondrait plus avec
  // celle mesurée. Il faut fixer leur hauteur.
  figure {
    @include figure-height-relative(640/360);
  }

  .Infos {
    left: 0;
    padding: 10px;
    position: absolute;
    top: 0;
    @include single-transition();
    width: 55%;
    @include smartphone {
      width: 100%;
    }
    z-index: 700;
  }
  .Footstone {
    display: block;
    font: font-default(18, bold);
    text-transform: uppercase;
  }
  .Pattern {
    @include smartphone {
      display: none;
    }
  }
  .Headstone, .Pattern, .Description, .Price {
    color: $color-grey-6;
    font: font-default(16);
  }
  .Description {
    display: block;
  }
  .Price {
    font: 500 15px "Roboto";
    .Priceht{
      display: block;
      color: #D20000;
    }
    .Pricettc{
      display: block;
      color: #149EDC;
    }
    small {
      font-size: 70%;
    }
  }

  .FavoriteStar {
    color: $color-grey-9;
    cursor: pointer;
    position: absolute;
    right: 0;
    @include single-transition();
    text-decoration: none;
    top: 0;
    z-index: 750;
    &:hover {
      color: $color-grey-3;
    }
    .icon {
      @include material-size(24px, 48px);
    }
    &.active {
      color: #b79d00;
      &:hover {
        color: darken(#b79d00, 10%);
      }
    }
    &.spinning .icon {
      @include animation(spin 0.5s linear infinite);
    }
    @include smartphone {
      display: none;
    }
  }

  .Featured {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 60px;
    height: 60px;
    z-index: 750;
    @include smartphone {
      display: none;
    }
  }
}

.MonumentCarousel {
  h1 {
    @extend %reset;
    font: font-default(20, normal);
  }
  .Infos {
    @include column(left);
    margin: 15px;
  }
  .Footstone {
    @extend %reset;
    display: inline;
    font-weight: font-weight-default(bold);
  }
  .Headstone, .Pattern, .Description {
    @extend %reset;
    color: $color-grey-3;
    display: inline;
    margin-left: 0.5em;
  }
  .Granite {
    @extend %reset;
    color: $color-grey-9;
    font: font-default(16);
  }
  .FullSize {
    bottom: $catalog-footer-height;
  }
  .InitConfiguration {
    @include button-text-colors($color-blue, $color-white);
  }
  .FavoriteStar {
    &.active {
      background: $color-white;
      border: 1px solid darken($color-white, 20%);
      color: $color-grey-6;
      .icon {
        color: #b79d00;
      }
      &:hover {
        border-color: darken($color-white, 25%);
        color: $color-grey-3;
        .icon {
          color: darken(#b79d00, 10%);
        }
      }
    }
    &.spinning .icon {
      animation: spin 0.5s linear infinite;
    }
  }
  .Price {
    font: 500 15px "Roboto";
    .Priceht{
      color: #D20000;
      padding: 5px;
    }
    .Pricettc{
      color: #149EDC;
      padding: 5px;
    }
    small {
      font-size: 70%;
    }
    .dot {   
      height: 5px;
      width: 5px;
      background-color: #5A5A5A;
      border-radius: 50%;
      display: inline-block;
      margin: auto 6px;
    }
  }
}

.CatalogFooter {
  @extend %reset, %columns;
  background-color: $color-grey-3;
  height: $catalog-footer-height;
  bottom: 0;
  left: 0;
  overflow: visible;
  position: fixed;
  text-align: center;
  right: 0;
  z-index: 800;
  p {
    @extend %reset;
    color: $color-grey-B;
    font: font-default(16);
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    width: 400px;
  }
  &-ScrollToTop {
    @include column(left);

    @include smartphone {
      display: none;
    }

    &-Button {
      @extend %reset;
      //@include column(right);
      text-align: left;
      width: 180px;
      color: $color-grey-6;
      cursor: pointer;
      display: block;
      font: font-default(16px, normal, 16px);
      padding: (($catalog-footer-height - 32px)/2) 0;
      overflow: hidden;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        small {
          color: $color-white;
        }
      }
      span {
        color: $color-white;
        display: block;
        // margin-right: $catalog-footer-height;
        white-space: nowrap;
      }
      .icon {
        @include material-size(24px, 36px, left);
        @include border-radius(100%);
        color: $color-white;
        margin: -2px (($catalog-footer-height - 36px)/2);
      }
      small {
        color: $color-grey-9;
        display: block;
        font: font-default(1400%/18, 14px);
        @include single-transition();
      }
    }
  }
  .Actions {
    @include column(right);

    .ResumeConfiguration {
      @extend %reset;
      @include column(right);
      color: $color-grey-6;
      cursor: pointer;
      display: block;
      font: font-default(18, normal, 18px);
      padding: (($catalog-footer-height - 32px)/2) 0;
      overflow: hidden;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        small {
          color: $color-white;
        }
      }
      span {
        color: $color-blue;
        display: block;
        margin-right: $catalog-footer-height;
        text-align: right;
        white-space: nowrap;
      }
      .icon {
        @include material-size(24px, 36px, right);
        @include border-radius(100%);
        background: $color-blue;
        color: $color-white;
        margin: -2px (($catalog-footer-height - 36px)/2);
      }
      small {
        color: $color-grey-9;
        display: block;
        font: font-default(1400%/18, 14px);
        @include single-transition();
      }
    }
    .Separator {
      @extend %reset;
      @include column(right);
      border-right: 1px solid $color-grey-6;
      height: 50px;
      margin: 10px 15px;
    }
    .CreateVariant {
      @include button-text-colors($color-grey-E, $color-grey-3);
      margin: 14px -1px;
    }

    @include keyframes(preview-enter) {
      0% {
        @include opacity(0);
        @include transform(translateY(-50px));
      }
      30% {
        @include opacity(0);
        @include transform(translateY(-50px));
      }
      40% {
        @include opacity(1);
        @include transform(translateY(0));
      }
      90% {
        @include opacity(1);
        @include transform(translateY(0));
      }
      100% {
        @include opacity(0);
        @include transform(translateY(50px));
      }
    }

    .ConfigurationPreview .Details{
      @include smartphone {
        font-size: 12px!important
      }          
    }
    .ConfigurationPreview {
      @extend %border-radius;
      background: $color-white;
      bottom: $catalog-footer-height + 15px;
      @include box-shadow(0 0 8px rgba(0, 0, 0, 0.3));
      display: block;
      @include opacity(0);
      overflow: visible;
      pointer-events: none;
      position: absolute;
      right: 15px;
      @include transform(translateY(50px));
      @include transition();
      width: 450px;
      z-index: 2000;

      @include smartphone {
        bottom: 140px;
        // width: calc(100vw - 30px);
        bottom: 100px;
        width:40%;
      }
      h1{
        @include smartphone {
          font-size: 12px!important
        }

      }

      &.entering {
        @include animation(preview-enter 5s ease forwards);
      }

      .Footer {
        background: $color-grey-3;
        color: $color-white;
        padding: 10px;
        .FamilyName {
          @extend %reset;
          color: $color-white;
          font: font-default(24, normal, 26px);
        }
        .Details {
          @extend %reset;
          color: $color-grey-9;
          font: font-default(16, normal, 26px);
          width: auto;
        }
      }

      &:before {
        border: 10px solid;
        border-color: $color-grey-3 transparent transparent transparent;
        bottom: -20px;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        right: 10px;
        width: 0;
      }
    }

    &:hover .ConfigurationPreview.enter {
      @include opacity(1);
      @include transform(translateY(0));
    }

  }

}

.ReactModalPortal {
  .CatalogModalOverlay {
    top: 0;
    .ReactModal__Content {
      left: 30px;
      right: 30px;
    }
  }
}

button.scrollToTopButton {
  @extend %reset;
  position: fixed;
  right: 0;
  bottom: 3em;
  z-index: 999;

  bottom: 20px;
  background: rgba(#000000, 0.8);
  border: none;
  @include border-radius(20px);
  color: $color-white;
  display: block;
  @include opacity(0);
  padding: 5px 20px;
  position: fixed;
  right: 20px;
  text-align: center;
  text-decoration: none;
  transform: translateY(20px);
  @include transition(all 250ms ease);
  visibility: hidden;

  &.active {
    transform: translateY(0);
    visibility: visible;
    @include opacity(0.5);
  }

  &.offset {
    bottom: $catalog-footer-height  + 20px;
  }

  &:hover {
    background: rgba(#000000, 0.8);
    @include opacity(1);
  }

  strong {
    display: block;
    font: font-default(12px, bold, 12px);
    text-transform: uppercase;
  }
  small {
    display: block;
    font: font-default(12px, normal, 12px);
    text-transform: lowercase;
  }
}

div.showPrices {
  @extend %reset;
  position: fixed;
  left: 0;
  bottom: 3em;
  z-index: 999;

  font-family: Roboto;
  bottom: 20px;
  background: rgba(#000000, 0.8);
  border: none;
  @include border-radius(5px);
  color: $color-white;
  display: block;
  @include opacity(1);
  padding: 15px 20px;
  position: fixed;
  left: 20px;
  text-align: center;
  // text-decoration: none;
  font-size: 18px;
  transform: translateY(20px);
  @include transition(all 250ms ease);
  visibility: hidden;
  
  transform: translateY(0);
  visibility: visible;
  bottom: $catalog-footer-height  + 20px;
  
  background: rgba(#000000, 0.8);

  a {
    text-transform: uppercase;
    color: #92ddff !important;
    cursor: pointer;
    padding: 0 20px;
  }
  
  i {
    vertical-align: middle;
    color: #fed95a;
    margin-right: 15px;
  }
}
