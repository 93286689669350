  // Acivités
.Sactivity {
  background-color: #F1F4F7;
  border-top: 1px solid #5D92E2;
  border-left: 1px solid #5D92E2;
  border-right: 1px solid #5D92E2;
  border-radius: 0 10px 0 0;
  padding-left: unset;
  padding-right: unset;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.25);
  @media screen and (max-width: 576px) {
    border-radius: unset;
    border-left: unset;
    border-right: unset;
    max-width: unset;
    width: 100%;
    z-index: 12;
    //margin-left: -15px;
    background-color: #F1F4F7;
    margin-right: unset;
  }
  //width: 25%;
  bottom: 0;
  position: fixed;
  z-index: 11;
  max-width: 320px;
  min-width: 285px;
  hr{margin-top: 0; margin-bottom: 0;}
  .arrows {
    padding-right: 5px;
    margin-left: auto;
    svg {
      font-size: 26px;
    }
  }

  /*@include smartphone{ background:none;border:none;position: absolute;right:16px;top: 59px;!*margin-top:60px*!;width:unset;
    .dropdown-toggle::after {margin-left:15px}
    button{font-size:18px;border-radius:20px;width:130px;}
    .MainPart{
      background: #ffffff;
      position:absolute;
      right: -38px;
      width: 97vw;
    }

 }*/

  .Title {
    cursor: pointer;
    text-align: left;
    font: normal normal bold 20px/23px Cuprum;
    letter-spacing: -0.3px;
    color: #3B4752;
    opacity: 1;
    line-height: 40px;
    margin-left: 5px;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    .badge {
      margin-left: 60px;
    }
    .ChatIcon{
      display: inline-block;
      width: 60px;
      //margin-top: -1rem;
    }
    .SubTitle{
      display: inline-block;
      padding-left: 10px;
      p{
        font-size: 33px;
        @media screen and (max-width:576px){
          font-size: 40px;
        }
        margin-bottom: unset;
        line-height: 24pt;
        font-family: 'Cookie', cursive;
        font-weight: initial;
      }
      a{
        text-decoration: none;
        font-size: 18px;
        color: #9BA7B7;
        &:hover{
          color: #3568BA;
          text-decoration: underline;
        }
      }
      .MembersBlock{
        position: absolute;
        background-color: white;
        padding: 15px;
        width: 52%;
        border-radius: 0 0 10px 10px;
        margin-top: -7px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, .2);
        max-height: 200px;
        overflow-y: auto;
        .member{
          font-size: 14px;
        }
      }
      .MembersBlock::-webkit-scrollbar {
        width: 12px;               /* width of the entire scrollbar */
      }
      .MembersBlock::-webkit-scrollbar-track {
        background: #FFFFFF;        /* color of the tracking area */
        border-radius: 0 0 10px 0;
      }
      .MembersBlock::-webkit-scrollbar-thumb {
        background-color: rgb(194, 194, 194);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 3px solid #FFFFFF;  /* creates padding around scroll thumb */
      }
    }
  }

  .Events{
    height: calc(100vh - 524px);
    @media screen and (min-width: 375px) and (max-width: 576px){
      height: 83vh;
    }
    @media screen and (max-width: 375px){
      height: 69vh;
    }
    overflow-y:auto;

    .EventName{font-size: 16px;color:#9BA7B7;/*font-weight: bold;*/}
    .EventLike{ margin:20px 5px 5px 15px;float:left;clear: both;
      .EventLikeBody{font-size: 16px;color:$fc-darkgrey;
        .blue{color:var(--fcnavyblue)};}
      }
      .EventComment{ 
        margin:20px 5px 5px 15px;
        clear: both;
        float: left;
        .EventCommentBody{
          font-size: 16px;
          color:$fc-darkgrey;
          .blue{
            color:var(--fcnavyblue)
          }
        }
      }
      &.MyComment{float: right;.Comment{text-align: right;}}
      &.OtherComment{float: left;.Comment{text-align:left;}}
    }
    .Events::-webkit-scrollbar {
      width: 12px;               /* width of the entire scrollbar */
    }
    .Events::-webkit-scrollbar-track {
      background: #F1F4F7;        /* color of the tracking area */
      border-radius: 0 0 10px 0;
    }
    .Events::-webkit-scrollbar-thumb {
      background-color: #5D92E2;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
      border: 3px solid #F1F4F7;  /* creates padding around scroll thumb */
    }

    .EventImage {
      margin: 15px;
      padding-top: 15px;
      font-size: 13px;
      color: $fc-darkgrey;
      clear: both;

      .theImage {
        cursor: pointer;
        height: 80px;
        margin-bottom: 15px;
      }
      .MyImage {
        float: right;
      }
      .OtherImage {
        float: left;
      }

      .textImage {
        margin-bottom: 10px;
      }
    }

    .EventMessage {
      margin: 15px;
      width: 250px;
      font-size: 16px;
      clear: both;

      .Message {
        /* border-radius: 10px 10px 0px 10px; */
        padding: 15px;
      }
      &.MyMessage {
        float: right;

        .Message {
          background-color: $fc-whitegrey;
          border-radius: 15px 15px 15px 0px;
          color: $fc-blackish;
        }
      }
      &.OtherMessage {
        float: left;

        .Message {
          background-color: #5D92E2;
          border-radius: 15px 15px 0px 15px;
          color: white;
        }
      }
    }

    .EventMember{font-size: 16px;color:$fc-darkgrey;float:left;clear: both;margin-left:15px;margin-top:10px;
      .Name{color:#9BA7B7;/*font-weight: bold;*/}}
    .EndScroll{float:left; clear: both}


  }
  .messages {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #e6e6e6;
    background-color: #FFFFFF;
    display: flex;
    input {
      width: 69%;
      height: 35px;
      outline: none;
      border: none;
      border-radius: 20px;
      background-color: #FFFFFF;
      color: #8C8E8E;
      font: italic normal normal 16px/18px Cuprum;
      letter-spacing: -0.24px;
      display: inline-block;
      margin-top: auto;
      margin-bottom: auto;
    }
    .addImage {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 55px;
    }
    .sendImage {
      font-size: 55px;
      cursor: pointer;
      color: #3568BA;
      width: 45px;
      height: 45px;
      padding: 8px;
      display: inline-block;
      margin-top: auto;
      margin-bottom: auto;
      background-color: #F4F4F4;
      border-radius: 50%;
    }
    .fileUpload{display:none}
    button {
      /* margin-top: 5px;
      width: 8%;
      height: 27px;
      line-height: 27px;
      text-align: center;
      font-weight: bold; */
      /* background-color: $fc-blueish; */
      padding-left: 5px;
      border: none;
      /* align-items: center; */
      /* display: flex; */
      background-image: linear-gradient(to left, #3669BB, #5A8FDF);
      width: 60px;
      height: 50px;
      border-radius: unset;
      margin-top: -11px;
      display: inline-block;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      img{
        height: 24px;
        width: 24px;
      }
      /*@include smartphone{ width:10%;font-size:13px;}*/
    }
}

.chatActivity-Notif{
  position: fixed;
  right: 8px;
  bottom:52px;
  display: flex;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  background-color: #D32F2F;
  z-index: 13;
  font-size: 14px;
  color: white;
  font-weight: bold;
  border: 2px solid #f0f0f0;
}
.chatActivity-Button {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  right: 14px;
  font-size: 16px;
  color: white;
  /* background-image: linear-gradient(to right, #4472c4 , #0095e8); */
  background-color: var(--f-main-color);
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14),
  0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    color: var(--f-font-color);
  }

  //opacity: 0.8;
  transition: 0.3s;
  &:hover{
    /* background-image: linear-gradient(to left, #4472c4 , #0095e8); */
    background-color: var(--f-font-color);
    opacity: 1;
    svg {
      color: var(--f-main-color);
    }
  }
  svg{
    //margin-right: 5px;
    width: 24px;
    height: 24px;
    &.CloseIcon{
      font-size: 22px;
    }
  }
  .buttonTitle{
    display: none;
  }
}
.chatActivity-Container{
  position: fixed;
  bottom: 80px;
  right: 14px;
  width: 350px;
  @media screen and (max-width: 420px){
    width: 300px;
  }
  min-height: 400px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid lightgrey;
  box-shadow: 0 5px 10px grey;
  z-index: 15;
  animation: fadeIn ease 0.15;
  -webkit-animation: fadeIn ease 0.15s;
  -moz-animation: fadeIn ease 0.15s;
  -o-animation: fadeIn ease 0.15s;
  -ms-animation: fadeIn ease 0.15s;
  .chatActivity-title{
    /* background-image: linear-gradient(to right, #4472c4 , #0095e8); */
    background-color: var(--f-main-color);
    font-family: 'Cookie', cursive;
    font-size: 14pt;
    font-weight: bold;
    width: 100.5%;
    margin-left: -1px;
    height: 60px;
    margin-top: -14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    h1{
      color: var(--f-font-color);
      font-size: 1.8em;
    }
    &:hover{
      /* background-image: linear-gradient(to left, #4472c4 , #0095e8); */
      background-color: var(--f-font-color);
      h1{
        color: var(--f-main-color);
      }
    }
  }
  hr{
    margin-bottom: 5px;
    margin-top: unset;
  }
  .chatActivity-Events{
    padding-top: 10px;
    height: 400px;
    overflow-y: auto;
    transition: height 2s;
    .chatActivity-Event{
      padding: 10px;
      border-radius: 5px;
      //margin-bottom: 10px;
      margin-left: 5px;
      margin-right: 5px;
      min-height: 45px;
      font-size: 14px;
      display: flex;
      align-items: center;
      &.Incoming-Msg{
        background-color: #e1e1e1;
        color: #000000;
        width: 100%;
      }
      &.OutGoing-Msg{
        /*
        background-image: linear-gradient(to right, #4472c4 , #0095e8);
        color: #FFFFFF;
        */
        background-color: var(--f-main-color);
        color: var(--f-font-color);;
        width: 100%;
      }
      &.EventElement{
        font-size: 14px;
        color: #2A3032;
        min-height: 25px;
        .EventName{
          color: var(--fcnavyblue);
          font-weight: bold;
        }
        .projectName{
          color: var(--fcnavyblue);
        }
      }
      &.MyMessage{
        margin-left: auto;
      }
      &.EventMessage{
        display: block;
        width: 80%;
        .EventName{
          color: grey;
        }
      }
    }
  }
  .chatActivity-Events::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  .chatActivity-Events::-webkit-scrollbar-track {
    background: #F1F4F7;        /* color of the tracking area */
    border-radius: 0 0 10px 0;
  }
  .chatActivity-Events::-webkit-scrollbar-thumb {
    background-color: #5D92E2;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #F1F4F7;  /* creates padding around scroll thumb */
  }
  .chatActivity-AddMsg{
    display: flex;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    height: 50px;
    padding-left: 5px;
    input{
      width: 100%;
      border: unset;
      border-radius: 5px;
      height: 40px;
      padding: 0 0 0 5px;
      &.focus-visible{
        border: 1px solid #0095e8;
      }
    }
    .fileUpload{
      display: none;
    }
    .chatImg{
      width: 45px;
      height: 40px;
      /* color: #0095e8; */
      color: var(--f-main-color);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover{
        /* color: var(--fcnavyblue); */
      }
      svg{
        font-size: 30px;
      }
    }
    .sendMsg{
      width: 45px;
      height: 40px;
      /* color: #0095e8; */
      color: var(--f-main-color);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover{
        /* color: var(--fcnavyblue); */
      }
      svg{
        font-size: 20px;
      }
    }
  }
}
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
