.Ffinalization{position: relative;width: 100%;height:100%;background: white;
    .TopBar{ padding-top:55px;padding-left:22px;height:125px;width:100%;
        @include smartphone {padding-top:10px;height:80px}
        .StepAction{float:left;margin-right:10px;width: unset;
            &.Grey{background-color: gray;color:white}
            @include smartphone {height:30px;border-radius:15px }
            span{@include smartphone {position:relative;top:8px;left:0px;font-size:13px}}}
        .StepButton{margin-left: 10px;float: left;margin-top: 5px;@include smartphone {display:none}}
        .StepLabel{padding-left: 10px;padding-right: 10px; }
    }
    .ThreeDMode{top:25px;@include smartphone {top:-225px;}}
    .ViewerLoadingIcon{top:70px;left:85px;@include smartphone {top:310px}}
    .LowerPart {background: white;height: 757px;}
    .ContentFin{position: absolute;margin-right:20px;margin-left: 20px;margin-bottom: 97px;
        box-shadow: 0px 0px 30px #00000029;background: #FFFFFF 0% 0% no-repeat padding-box;
    .Pdf{height: 757px;.Close{top:0px}}
    .Loading{ position: absolute;top:300px;left:400px; font-size: 24px; width:200px;text-align: center;}}
    .monumentImg{float:left;width:80%;@include smartphone {float: none;width:unset}}
    .Fquote {position:absolute;width:350px;right:0px;overflow: hidden;background:white;
            text-align: left;padding: 20px 40px 0px 40px;border-left:1px solid #D3D3D3;
            @include smartphone {padding:0;width:300px;height:unset;border:none;padding-left: 20px;margin-top: 10px;}
        .required:after {content:" *";color:#a51522;}
        .FquoteTitle{margin-top:10px;margin-bottom:30px;position: relative;
            img{
              border-radius: 50%;&.square{border-radius:0px}
              max-width: 104px;
              max-height: 104px;
            }
            .titleText{font-size: 24px;color:$fc-deepgrey;position: absolute;margin-left: unset;}
            .TheSupplier{font-size:16px;margin-left: 115px;position: absolute;
                a{cursor: pointer;}}
        }
        .FquoteHelp{top: 30px; color: var(--fcdarkstlblue);font-size:16px;font-weight:bold;margin-bottom:10px;position: relative;cursor: pointer;
        span{margin-left:45px;position:absolute;text-decoration: underline;}}
        .FquoteHelpBottom{color:black;font-size:13px;margin-bottom:20px;margin-left: auto;}
        label{color: $fc-darkgrey;font-size:16px;}
        input{font-size:14px;margin-bottom:5px;width:293px;background: #F5F6FC 0% 0% no-repeat padding-box;
            border: 1px solid #E4E6F0;border-radius: 2px;}
        .FquoteForm{display: block; margin-top: 40px;font-size:18px;.FrenchAddress{font-size:16px}input:invalid {
            border: 2px solid red;
          }}
        textarea{height:100px;}
        .FquoteSend{
            /* 
            background: var(--fcdarkstlblue);
            background: var(--f-main-color);  
            color: white;
            display: block;
            font-size:16px;
            font-weight:bold;
            border-radius: 50px;
            padding: 10px 20px;
            */ 
            width: 100%;
            margin-top:20px;
        }
        .FquoteErrorMail{color:red;margin-top:5px}
        .OutsideFrance{background: white;color:var(--fcdarkstlblue);border:1px solid var(--fcdarkstlblue);font-size:12px;border-radius: 20px;
                       float:right;margin-right: 5px;}
        .FquoteError{margin-top: -30px;color:red;}
        .messageSent{font-size: 18px;font-weight: bold;}
    }
        .Close{position:absolute;right:150px;top:90px;z-index:900;
            @include smartphone{right: 40px;top: 80px;}}
        .Zoom{top:80px;@include smartphone {top:310px}}
        .leaflet-container { width: 100%; height: 70vh;}
}
.FamilyHelpText{
    font-size:16px;
    font-weight: bold;
    background: #EFEFEF;
    padding:15px;
    width:360px;
    position: absolute;
    right: calc( 100% + 20px);
    top: 50%;
    transform: translateY(-50%);
    border-radius: 8px;
    font-family: $sukhumvitFont;
    @media screen and (max-width:768px){
        left: 0;
        width: 80%;
        margin-right: 10%;
        margin-left: 10%;
        z-index: 1005;
        transform: translateY(-50%) !important;
        top: 50%;
    }
    @include smartphone{right:unset}
    .arrow{
        border: solid 10px transparent;
        border-left-color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(-100%) rotate(-90deg);
        @media screen and (max-width: 768px){
            display:none;
        }}
    .modal-content{background: #EFEFEF;border:none}
    .headText{/* color:var(--fcorange)*/ color:var(--f-main-color)}
    .itemText{color:$fc-darkgrey;font-size: 14px;font-weight: normal;list-style: none;
        li::before {content: "\2022"; font-size: 20px; /*color:var(--fcorange);*/ color:var(--f-main-color); font-weight: bold; display: inline-block;
            width: 1em;margin-left: -1em; }
        }
    &.Fquote-customize {right: unset;left: 400px;top:140px;position: absolute;
        .arrow{border:solid 10px transparent;border-right-color:#EFEFEF;position:absolute;margin:85px 0 0 -35px;}
    }
  &.FQuoteSuccess{
    top: 272px;
    right: 14px;
    margin: 0;
    &.fsp-quote-success {
        top: 210px;
    }
    .arrow{
        top: unset;
        bottom: 45px;
    }
  }
}
.FamilySupplierMap {
    position: fixed;top: 90px;left: 20px;width: 80%;
    .Close{ position:absolute; right:10px;top: 10px;cursor: pointer;
        i{position: relative;top: 7px;}
            }
    }
.directPdfDisplay{ position:fixed; height:100%;width:100%; font-size: 42px;background: white ;text-align: center;padding-top: 20%;
img{position:fixed;width:200px;top:50%;left:45%}}
