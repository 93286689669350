.Family{
  .fTopBarContainer{
    font-family: $RalewayFont;
    font-size: 24pt;
    font-weight: bold;
    &.orangeBgContainer{
      /* background-color: var(--fcorange); */
      background-color: var(--f-main-color);
      height: 70px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        position: fixed;
        width: 100%;
        z-index: 999;
      }
      span{
        /* color: var(--fcdarkstlblue); */
        color: var(--f-font-color);
        font-size: 22px;
        text-transform: uppercase;
        margin-left: 25px;
        @media screen and (max-width: 768px) {
          font-size: 18px;
          font-weight: bold;
        }
        @media screen and (max-width: 320px) {
          font-size: 16px;
          margin-left: 10px;
        }
      }
    }
  }
  .engraving{
    .accessory-actions{
      @include smartphone{
        bottom:120px;
        background: none;
      }
    }
    .PatternEngravingZoneB {
      @media screen and (max-width: 768px){
        top: 71px;
      }
      .CanvasContainer{
        margin-left: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
          margin-left: unset;
        }
      }
      .closeIcon{
        display: block;
        z-index: 99999;
        top: 38px;
        position: absolute;
        background: unset;
        border: unset;
        right: 10px;
        svg{
          path{
            fill: var(--fcdarkstlblue);
          }
        }
        &:hover{
          svg {
            path{
              fill: var(--fcorange);
            }
          }
        }
      }
    }
  }
.ConfigurationPatternsEngravings .TitrePage{ margin-top: 50px;z-index: 9;
                                    @include smartphone {display:none}}
.TopFParams.hidden{visibility: hidden}
.TopFParams{
  .SeeResultOnMonument{
    display:none;
    background: unset;
    border: unset;
    position: absolute;
    right:  2px;
    top: 0;
    svg{
      path{
        fill: var(--fcdarkstlblue);
        &:hover{
          fill: var(--fcorange);
        }
      }
    }
    &.modify{
      padding: 10px;
      border-radius: 16px;
      background-color: #D0E6F2;
      cursor: pointer;
      svg{
        path{
          fill: var(--fcdarkstlblue);
        }
      }
      &:hover{
        background-color: #E3DFFF;
      }
    }
    @media screen and (max-width: 768px) {display:block; &.modify{position: fixed;top:unset;bottom: 100px;left: 10px;visibility: visible;}}}}
.ModalFengraving{width:277px;height:345px;position: absolute;}
.PatternEngravingZone .flexible-modal{border:none}
.FengravingParameters{position: absolute; top:25%;left: 15%;cursor: move;
        background: #F3F3F3; padding-left:20px;
        box-shadow: 0px 0px 10px #00000029;width:277px;
        font-size:14px;color:$fc-lightgrey;font-style: italic;height: auto;
        @include smartphone {top:0px;left:5px;z-index: 2;}
    .EnterEngraving{padding-top:21px;}
    .EngravingLine{width:250px;height: 30px;font-size: 16px;color: #2A3032;margin-top:5px;
        @keyframes blink { 50% { border-color:var(--fcblue) ; } };
        &.blink{animation: blink .5s step-end infinite alternate;}
      @media screen and (max-width: 768px){
        width: 100%;
      }
    }
    .AddLine,.AddText{color:var(--fcblue);text-decoration: underline;text-align: right;
            margin-top:5px;margin-right:8px;cursor: pointer!important;}
    .ChooseTypo{padding-top:10px;padding-bottom:5px;}
    .FontPicker{padding-left:15px;text-align:center}
    .FontStyle{width: 50px;height: 50px;border: 1px solid #D5D5D5;
            border-radius:100%;font-size:28px;font-style:normal;padding-top:5px;
            margin-right: 8px;cursor: pointer;margin-bottom:5px;
            &.active{color:var(--fcblue)}
        }
    .TitleColor{float:left;padding-top:13px}
    .TitleAlign{float:right;padding-top:13px;padding-right: 5px}
    .ColorPicker{display:flex;padding-top:5px;float:left;}
    .Color{width: 30px;height: 30px;border: 1px solid $fc-grey;
        border-radius:100%;margin-right: 10px;cursor: pointer;
        &.active{
          border: 2px solid var(--fcblue);
          outline: 4px solid var(--f-main-color) !important;
        }}
    .AlignPicker{display:flex;padding-top:5px;float:right;padding-right: 25px}
    .Align{.icon{font-size: 36px} 
      &.active{
        // color:var(--fcblue);
        color: var(--f-main-color) !important
      };cursor: pointer
    }
    .AddText{width: 89px;margin-right:0px;visibility: visible;}

    .BottomLine {
      padding-top: 10px;
      display: flex;
      float: right;
      margin-right: 5px;

      @include smartphone {margin-right:80px}
    }
    .bottomLineB {
      display: inline-table;
      width: 277px;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: -15px;
      visibility: visible;

      .button-engraving-action {
        width: 70%!important;
        height: 40px;
        display: block;
        margin: auto;
        margin-top: 10px;
        font-family: Cuprum;
        font-size: 18px;
        border-radius: 50px;
        background: var(--fcblue) 0% 0% no-repeat padding-box; //CHANGED #0095E8
        border-style: none;;
      }
    }
    .ButtonAddText, .ButtonDelete {width: 30px;height: 30px;border:none;
        margin-left:10px;margin-top: 10px;cursor: pointer;}
    .ButtonAddText{background:transparent url('../../images/Groupe_191.svg') ; visibility: visible}
    .ButtonAddTextB{
      background:transparent url('../../images/plus-icon.svg');
      visibility: visible;
      height: 31px;
      width: 31px;
      outline: none;
      border: none;
      margin: 10px 0px 0px 10px;
    }
    .ButtonDelete{background:transparent url('../../images/Groupe_190.svg') ; padding-right:5px;}

    }
    .FengravingParametersB {
      position: fixed!important;
      left: 0;
      top: 85px!important;
      padding-bottom: 25px;
      z-index: 8000;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      border-radius: 8px;
      left: 24px;
      top: 90px !important;
      @media screen and (max-width: 500px){
        left: 0;
      }
      .Color{

        &.active{
          outline: 4px solid #ff9d1e;
          border: 0;
        }
      }
      .AlignPicker{
        .Align{
          &.active{
            color: #ff9d1e;
          }
        }
      }
      .AddLine{
        color: #324854;
      }
      .FontPicker{
        .FontStyle {
          &.active{
            /* color: #ff9d1e; */
            color: var(--f-main-color);
          }
        }
      }
      @media screen and (max-width: 768px){
        padding-right: 120px;
        padding-left: 120px;
        padding-top: 10%;
        width: 100%;
        height: 100%;
        top: 70px !important;
      }
      @media screen and (max-width: 480px){
        padding-left: 10px;
        padding-right: 10px;
      }
      @media screen and (max-width:320px){
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .Error{color:$fc-darkgrey;font-size:24px;position: absolute;top:50%;width:100%;left:30%}
}
