.UpdateNotification {
  @extend %reset, %border-radius, %box-shadow;
  @include animation(notification-leave 0.5s ease forwards);
  background: var(--background-catalog-cards);
  font-family: 'Raleway';
  // order: 1px solid $color-grey-D;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  @include border-bottom-radius(0);
  bottom: 70px;
  color: var(--text-dark);
  display: none;
  left: 50%;
  margin: 0 -125px;
  @include opacity(0);
  padding: 10px;
  position: fixed;
  text-decoration: none;
  @include transition(all 0.65s ease);
  border-radius: 4px;
  z-index: 8000;
  .notif-content {
    margin-right: 20px;
  }

  @include smartphone {
    top: 70px;
    height: 45px;
  }
  @include keyframes(notification-enter) {
    0% {
      bottom: 70px;
      @include opacity(0);
    }
    100% {
      bottom: 80px;
      @include opacity(1);
    }
  }
  @include keyframes(notification-enter-mobile) {
    0% {
      bottom: 33vh;
      @include opacity(0);
    }
    100% {
      bottom: calc(33vh + 10px);
      @include opacity(1);
    }
  }

  &.show {
    @include animation(notification-enter 0.5s ease forwards);
    @include smartphone {
      @include animation(notification-enter-mobile 0.5s ease forwards);
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //  &:before {
  //    border-left: 1px solid $color-grey-D;
  //    content: '';
  //    right: 40px;
  //    margin: 5px 0;
  //    position: absolute;
  //    top: 0;
  //    height: 30px;
  // }

  h5 {
    color: var(--text-dark);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  p {
    @extend %reset;
    color: var(--text-dark);
    font: font-default(13, normal, 14px);
    text-align: center;
  }
  .icon {
    // @include material-size(24px, 30px, right);
    // color: $color-green;
  }
  &.error {
    .icon {
      color: $color-red;
    }
  }
}

.NoConfig{
  display:flex;
  justify-content: center;
  align-items:center;
  font-size:25px;
  height:100vh;
}