.label-content {
  display: none;
}

.cfg-steps {
  height: 4.688rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // cursor: pointer;
  .logoContainer{
    margin-top: unset;
    margin-left: 5px;
    margin-right: 5px;
  }

  a {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    width: 220px;
    height: 51px;
    margin: 0 20px;
    position: relative;
    z-index: 2;
    background-color: var(--background-header);
    color: var(--text-dark);
    text-decoration: unset;
    .step-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: -10px;
      .label {
        line-height: normal;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
      }
    }

    .icon {
      margin: 0 8px 0 0;
      vertical-align: middle;
      background-color: var(--text-dark);
    }

    &.disabled{  cursor: not-allowed;}

    &:before { /* Arrow */
      content: "";
      display: inline-block;
      background: transparent;
      width: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      right: 30px;
      border-left: 12px solid;
      position: relative;
      z-index: 2;
    }

    &:after {
      background-color: var(--text-dark);
      content: "";
      height: 1px;
      left: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 1;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &.Last {
      margin-right: 0px;
      &:after {
        display: none;
      }
    }

    &.active {
      background-color: var(--secondary1);
      color: var(--text-dark);
      & ~ a {
        border: 1px solid var(--border-color);
        color: var(--text-medium);
        .icon {
          background-color: var(--text-medium);
        }
      }

      &:after {
        background-color: var(--secondary1);
      }
      
      .step-content {
        font-weight: 700;
      }

      .icon {
        background-color: var(--text-dark);
      }

      & ~ a {
        &:before {
          opacity: 0;
        }
      } 
    }

    &:not(.active):hover {
      color: var(--text-light);
      .icon {
        background-color: var(--text-light);
      }
      &:before {
        color: var(--text-dark);
      }
    }
  }
}

@media screen and (min-width: 490px) and (max-width: 1113px){
  .Header {
    .cfg-steps {
      .step-content {
        span.icon {
          display: none;
        }
        span {
          font-size: 15px;
          margin-left: 25%;
          line-height: normal;
        }
      }
    }
  }
}

@include smartphone {
  .Configuration {
    padding-top: 0 !important;
  }

  #dropstate {
    display: none;
    z-index: 999999;
    position: relative;
  }
  
  #dropstate[type="checkbox"]:checked + .Header {
    // height: 200px;
    .label-content {
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' transform='rotate(180)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.50581 9.00581C4.77917 8.73244 5.22239 8.73244 5.49576 9.00581L12.0008 15.5108L18.5058 9.00581C18.7792 8.73244 19.2224 8.73244 19.4958 9.00581C19.7691 9.27917 19.7691 9.72239 19.4958 9.99576L12.4958 16.9958C12.2224 17.2691 11.7792 17.2691 11.5058 16.9958L4.50581 9.99576C4.23244 9.72239 4.23244 9.27917 4.50581 9.00581Z' fill='%23FF9D1C'/%3E%3C/svg%3E%0A");
      }
    }
  }
  
  #dropstate[type="checkbox"]:checked + .Header > .cfg-steps {
    display: block;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .Header { // .dropdown
    position: fixed;
    width: 100%;
    // height: 55px;
    box-sizing: border-box;
    overflow: hidden;
    transition: 200ms ease-in;
    color: var(--text-light);
    background-color: var(--secondary2-hover);
    z-index: 999 !important;
    flex-direction: column;
    .label-content {
      // position: absolute;
      display: block;
      width: 100%;
      height: 52px;
      padding: 15px;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 0;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      .icon {
        margin-right: 15px;
        vertical-align: middle;
        background-color: var(--text-light);
      }
      &:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.50581 9.00581C4.77917 8.73244 5.22239 8.73244 5.49576 9.00581L12.0008 15.5108L18.5058 9.00581C18.7792 8.73244 19.2224 8.73244 19.4958 9.00581C19.7691 9.27917 19.7691 9.72239 19.4958 9.99576L12.4958 16.9958C12.2224 17.2691 11.7792 17.2691 11.5058 16.9958L4.50581 9.99576C4.23244 9.72239 4.23244 9.27917 4.50581 9.00581Z' fill='%23FF9D1C'/%3E%3C/svg%3E");
        vertical-align: middle;
        float: right;
      }
    }
    .cfg-steps {
      position: absolute;
      // top: 35px;
      // left: 20px;
      width: 100%;
      height: 100%;
      display: none;
      transition: 1s ease-out;
      a { // .menu
        margin: -1px auto;
        width: 100%;
        height: 52px;
        padding: 0 16px;
        border-radius: 0%;
        // font-size: 1.5em;
        .step-content {
          justify-content: left;
          &:after { /* Arrow fill */
            left: 4% !important;
            width: 92% !important;
          }
        }
        &:before { /* Arrow border */
          display: none;
        }
    
        &:after { /* Arrow fill */
          display: none;
        }
      }
    }
    .AccessoriesGranitePickerTabs {
      width: 100%;
      justify-content: center;
      > .Accessory > .Description {
        display: none;
      }
    }
  }
}

@include tablet-portrait {}

.Family{
  .Header{
    &.cfg-steps{
      display: flex;
    }
  }
}
