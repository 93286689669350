@charset "UTF-8";

@font-face {
  font-family: "gpg-granit";
  src: url("../../fonts/gpg-granit.eot");
  src: url("../../fonts/gpg-granit.eot?#iefix") format("embedded-opentype"),
  url("../../fonts/gpg-granit.woff") format("woff"),
  url("../../fonts/gpg-granit.ttf") format("truetype"),
  url("../../fonts/gpg-granit.svg#gpg-granit") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "gpg-granit" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "gpg-granit" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-category-cineraires:before {
  content: "\e910";
}
.icon-category-classiques:before {
  content: "\e911";
}
.icon-category-collection:before {
  content: "\e912";
}
.icon-category-contemporains:before {
  content: "\e913";
}
.icon-category-doubles:before {
  content: "\e914";
}
.icon-category-musulmans:before {
  content: "\e915";
}
.icon-category-plats:before {
  content: "\e916";
}
.icon-category-tombales:before {
  content: "\e917";
}
.icon-accessories-misc:before {
  content: "\e900";
}
.icon-accessory-box:before {
  content: "\e901";
}
.icon-accessory-granite-switch:before {
  content: "\e902";
}
.icon-accessory-vase:before {
  content: "\e903";
}
.icon-base-height:before {
  content: "\e904";
}
.icon-double-arrow:before {
  content: "\e905";
}
.icon-frame-granite-switch:before {
  content: "\e906";
}
.icon-frame:before {
  content: "\e907";
}
.icon-full-granite-switch:before {
  content: "\e908";
}
.icon-headstone-granite-switch:before {
  content: "\e909";
}
.icon-headstone-switch:before {
  content: "\e90a";
}
.icon-headstone:before {
  content: "\e90b";
}
.icon-layout-size:before {
  content: "\e90c";
}
.icon-monument-granite-switch:before {
  content: "\e90d";
}
.icon-veneer-granite-switch:before {
  content: "\e90e";
}
.icon-veneer:before {
  content: "\e90f";
}
