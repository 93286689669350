.stockMessage {
  position: fixed;bottom: 90px;left: 20px;z-index: 100;
  font-size: 18px;color: white;
  padding: 15px;border-radius: 20px;
  background:var(--fcblue);
  span{cursor:pointer;text-decoration: underline;}
}
.StockFrameInfo {
  font-size: 14px;  color: red;  font-style: italic;
  padding-left: 80px;
}
.left-buttons{ &.Stock {
  top: 330px!important;
}}
.NoTopBar{
  figure.FullSize {
    padding-bottom: 70px;
    top: 0px!important;
    z-index: 10;
  }
}

.LoadingMessage {
  @include animation(delayShow 1s);
  background: var(--background-catalog-cards);
  font-family: 'Raleway';
  // @include border-radius(25px);
  border-radius: 4px;
  color: var(--text-dark);
  // font: font-default(16, bold, 20px);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  left: 50%;
  margin: -100px -140px auto -140px;
  padding: 10px;
  top: 50%;
  text-align: center;
  position: absolute;
  vertical-align: middle;
  width: 320px;
  z-index: 10;

  &:after {
    @include animation(ellipsis steps(4, end) 1s infinite);
    content: "\2026"; // Caractère ellipsis
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }

  @keyframes delayShow {
    0% {
      @include opacity(0);
    }
    50% {
      @include opacity(0);
    }
    100% {
      @include opacity(1);
    }
  }
  @keyframes ellipsis {
    from {
      width: 0;
      margin-right: 14px; // Ce margin est utilisé pour avoir une largeur de bloc fixe
    }
    to {
      width: 14px;
      margin-right: 0;
    }
  }
}

.Configuration {
  padding: 70px 0 0;
  #dropstate {
    opacity: 0;
  }
  .Header {
    z-index: 400;
  }

  @include keyframes(message-delayed) {
    0% {
      @include opacity(0);
    }
    33% {
      @include opacity(0);
    }
    100% {
      @include opacity(1);
    }
  }

  .Loading, .Error {
    @include animation(message-delayed 2s ease forwards);
    color: $color-grey-9;
    font: font-default(24, bold, 30px);
    left: 0;
    line-height: 30px;
    margin-top: -15px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
  }
  .Error {
    color: $color-red;
  }
  .NoHeadStoneError{
    width: 60%;
    margin-left: 20%;
    top: 30%;
  }

  figure.FullSize {
    top: 70px;
    z-index: 10;
    @include smartphone {
      top: 50px;
    }
  }

  .SquareButton {
    // @extend %square-button;
    margin-bottom: 5px;
    cursor:pointer
  }

  .left-buttons, .right-buttons {
    padding: 15px 0;
    @include smartphone {
      padding: 1px 0;
    }
    position: fixed;
    top: 70px;
    z-index: 300;
    hr {
      @extend %reset;
      clear: both;
      height: 10px;
    }
  }

  .left-buttons {
    left: 0;
    text-align: left;
    z-index: 310;
    top: 100px;
    width: 144px;
    @include smartphone {
      width: 90px;
    }

    .left-buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      hr {
        width: 100%;
        height: 1px;
        background: var(--secondary1);
        margin-bottom: 44px;
      }
    
      .btn-config-tertiary {
        // background: #6A7782;
        // border: 2px solid #6A7782;
        // color: #EAEEF2;
        padding: 0;
        border: 1px solid transparent;
        &:not(.disabled):hover, &.active {
          // border-color: #324854;
          // background: #324854;
          background-color: var(--secondary2-hover) !important;
        }
        .SquareButton {
          // color: #EAEEF2;
        }
        &:disabled {
          cursor: not-allowed !important;
          opacity: 0.5 !important;
        }
        .icon {
          background-color: var(--text-light);
          -webkit-mask-repeat: no-repeat;
          background-position: center;
        }
      }

      .btn-config-secondary {
        // background: #FFF;
        // border: 2px solid #324854;
        // color: unset;
        padding: 0;
        &:not(.disabled):hover {
          color: var(--text-light);
          box-shadow: none;
          background-color: var(--secondary3-hover);
        }

        &.active {
          color: var(--text-dark) !important;
          box-shadow: none;
          background-color: var(--secondary1) !important;
        }

        &:disabled {
          cursor: not-allowed !important;
          opacity: 0.5 !important;
          &:hover, &.active {
            .icon {
              background-color: var(--text-dark);
            }
          }
        }
        .icon {
          background-color: var(--text-dark);
        }
        &:hover {
          -webkit-transition: all 0.35s;
          // border: 2px solid var(--secondary3-hover) !important;
          .icon {
            background-color: var(--text-light);
          }
        }

        &.active {
          -webkit-transition: all 0.35s;
          border: 2px solid var(--secondary1) !important;
          .icon {
            background-color: var(--text-dark);
          }
        }
      }

      .MainButton, .OptionPanel, .btn-wrapper {
        width: 100%;
        height: 64px;
        margin-bottom: 44px;
        transition: all 0.35s;
        transition-delay: 0s;
        // ovveride OptionPanel
        clear: unset;
        float: unset;
        min-height: unset;
        position: relative;
      }

      .ModalButton, .btn-config {
        width: 100%;
        // margin-bottom: 24px;
        transition: all 0.35s;
        transition-delay: 0s;
        height: 64px;
      }

      .SquareButton {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        gap: 2px;
        // background: unset;
        // border: unset;
        border-radius: 0 10px 10px 0;
        height: 64px;
        font-family: Raleway;
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
        .icon {
          width: 32px;
          height: 32px;
          flex-shrink: 0;
          float: none;
          font-size: unset;
          line-height: unset;
          margin: 0;
        }

        .monuments-icon {
          width: 2rem !important;
          height: 1.455rem !important;
        }
        div {
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: none;
          @include smartphone {
            font-size: 10px;
          }
        }
      }

      // override
      .SquareButton {
        &:hover {
          box-shadow: none;
        }
      }

      .open-tutorial {
        background-color: $color-grey-6;
        cursor: pointer;
        z-index: 3000;
      }

      .info-tutorial {
        text-align: center;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
      }
    }

    .ShowValue {
      text-align: center;
      font-family: "Raleway", cuprum, sans-serif !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      z-index: 9999;
      position: relative;
    }
  }

  .right-buttons {
    right: 40px;
    text-align: left;
    z-index: 310;
    top: 100px;
    width: 100px;
    .right-buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    
      .btn-config-tertiary {
        // background: #6A7782;
        // border: 1px solid #6A7782;
        // color: #EAEEF2;
        border-radius: 6px;
        border: 1px solid transparent;
        &:not(.disabled):hover {
          // border-color: #324854;
          // background: #324854;
        }
        .SquareButton {
          // color: #EAEEF2;
        }
        .icon {
          // display: contents;
          width: 2rem !important;
          height: 2rem !important;
          margin: 0;
          background-color: var(--text-light);
          &.view3d-icon {
            width: 2rem !important;
          }
        }
      }

      .btn-config-secondary {
        // background: #FFF;
        // border: 1px solid #6A7782;
        border-radius: 6px;
        &:not(.disabled):hover {
          // color: #EAEEF2;
          // background: #6A7782;
          // border-color: #6A7782;
          // override
          box-shadow: none;
        }
        .icon {
          // display: contents;
          width: 2rem !important;
          height: 2rem !important;
          margin: 0;
          background-color: var(--text-dark);
        }
        &:hover {
          .icon {
            -webkit-transition: all 0.35s;
            background-color: var(--text-light);
          }
        }
      }
    }

    .btn-wrapper {
      width: 100px;
      height: 54px;
      margin-bottom: 44px;
      -webkit-transition: all 0.35s;
      transition: all 0.35s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      clear: unset;
      float: unset;
      min-height: unset;

      .ModalButton, .btn-config {
        width: 100%;
        transition: all 0.35s;
        transition-delay: 0s;
        height: 54px;
        .SquareButton {
          height: 100%;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Raleway;
          box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        }
        .additional-info {
          color: #6A7782;
          text-align: center;
          font-family: Raleway;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.0625rem;
          text-transform: capitalize;
        }
      }
    }

    /*
    .SquareButton {
      @include border-right-radius(0);
      clear: right;
      float: right;
      &.Flowers{width:70px;height:74px;background:$color-grey-E;
        border: 1px solid $color-grey-D;border-radius: 3px 0px 0px 3px;
       img{padding: 13px 20px 5px 24px}
       &.disabled{cursor: not-allowed;}
       &[disabled]{opacity:0.25}
     }
    }
    > .ModalButton {
      @include smartphone {
        .SquareButton > div {
          &:before{
            content: "Prix";
            font-size: 12px;
            color: $color-grey-6;
          }
          color: transparent;
          font-size: 0px;
        }
      }
    }
    .View3D {
      @include transform(perspective(1000px));
      @include keyframes(rotate-3d) {
        from {
          @include transform(rotateY(0deg));
        }
        to {
          @include transform(rotateY(360deg));
        }
      }
      button:hover {
        .icon {
          @include animation(rotate-3d 0.5s linear infinite);
        }
      }
      .SquareButton {
      @include square-button-colors($color-green);
      }
    }
    .Documents {
      @include smartphone {
        display: none;
      }
      .SquareButton {
        .icon {
          // Marges négatives pour pouvoir faire passer le "Espace documents" sur deux
          // lignes
          margin-bottom: -15px;
          margin-top: -20px;
          color: #e1a100;
        }
      }
    }
    .UndoRedo{
      button{margin-top:5px;cursor:pointer;color:#676767;line-height:30px;
      &[disabled]{opacity:0.5;cursor: not-allowed;}}
      .Annule{float:left}
      .Restaure{float:right}
    }
    */
  }

  .backToAdmin{position: fixed;right: 0px;top: 170px;z-index: 4000;
    width: 72px;height: 72px;background: green;color: white;}
    
  .Footer {
    display: flex;
    @extend %columns;
    bottom: 0;
    height: 70px;
    position: fixed;
    z-index: 200;
    overflow: visible;
    justify-content: space-between;
    @include smartphone {
      height: auto;
      &.without-navigation-buttons {
        button {
          width: 100px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            height: 30px;
            -webkit-mask-size: contain;
            width: 33px;
          }
          &.btn-config-tertiary {
            span {
              background-color: var(--text-light);
            }
          }
          &.btn-config-secondary {
            span {
              background-color: var(--text-dark);
            }
          }
        }
        .additional-info {
          font-size: 15px;
        }
      }
    }

    // @include smartphone {
    //   z-index: 200;
    //   height: 100px;
    // }

    .PreviousStep, .NextStep {
      // @include column(left);
      flex-basis: 33%;
      button {
        text-transform: none;
      }
      // a {
      //   span {
      //     display: block;
      //     white-space: nowrap;
      //     color: #324854;
      //   }
      //   .icon {
      //     width: 20px;
      //     height: 20px;
      //     max-width: none;
      //   }
      // }
    }

    .PreviousStep {
      left: 40px;
      bottom: 20px;
      position: relative;
      // span {
      //   margin-left: 15px;
      // }
    }

    .NextStep {
      right: 40px;
      bottom: 20px;
      position: relative;
      // text-align: right;
      // span {
      //   margin-right: 15px;
      // }
      display: flex;
      justify-content: flex-end; /* Aligne le contenu à droite */
      align-items: flex-start; /* Aligne le contenu en bas */
    }

    .Headline {
      flex-basis: 33%;
      // height: 40px;
      // padding: 15px 0;
      flex-grow: 1;
      text-align: center;
      @include smartphone {
        padding:0;
      }
      h1 {
        color: #324854;
        text-align: center;
        font-family: Raleway;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.0938rem;
        text-transform: uppercase;
        margin: 0 0 0.625rem;
        font-variant-numeric: lining-nums proportional-nums;
      }
      .Footstone {
        color: var(--text-dark);
        font: font-weight-default(bold);
      }
      .Headstone, .Pattern, .Description {
        padding-left: 0.5em;
      }
      .Granite, .Face {
        color: var(--text-medium);
        text-align: center;
        font-family: Raleway;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.0938rem;
        text-transform: uppercase;
      }
    }

    .acc-headstone {
      flex-basis: 100% !important;
      display: flex;
      gap: 0.9375rem;
      justify-content: center;

      .accessory-name {
        display: flex;
        gap: 0.9375rem;

        .Footstone {
          color: #324854;
          text-align: center;
          font-family: Raleway;
          font-size: 1.3rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.0938rem;
          text-transform: uppercase;
          margin: 0 0 0.625rem;
          font-variant-numeric: lining-nums proportional-nums;
        }

        .granite {
          padding-top: 0.625rem;
          color: var(--text-medium);
          text-align: center;
          font-family: Raleway;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.0938rem;
          text-transform: uppercase;
        }

        
      }

      .separator {
        padding-top: 0.25rem;
        font-size: 1.1875rem;
        color: #324854;
      }
    }
  }

  .ConfigurationCompletion {
    .Footer {
      .PreviousStep {
        display: flex;
        flex-basis: unset;
        bottom: 0;
        left: 0;
        width: 70px;
        align-items: center;
        justify-content: center;
        a {
          border: 0;
          justify-content: center;
          &:hover {
            background-color: unset
          }
          i {
            width: auto;
            height: auto;
            color: #676767;
          }
        }
      }
      .Headline {
        flex-basis: unset;
      }
    }
  } 

  .PriceInfo {
    color: $color-grey-9;
    font: font-default(20, bold, 20px);
    font-weight: 400;
    text-transform: uppercase;
    span {
      color: $color-blue;
      margin-right: 30px;
      text-align: right;
    }
    margin-bottom:20px;
  }
}

.btn-disabled {
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }
}

.cfg-switch {
  position: relative;
  cursor: pointer;
  height: 35px;
  .text-content {
    text-align: center;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  label {
    cursor: pointer;
    margin: 0;
  }
  span {
    display: grid;
  }
  input {
    display: none;
  }

  span:before,
  span:after {
    content: "";
  }

  span:before {
    width: 70px;
    height: 36px;
    background: var(--secondary2);
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  span:after {
    margin: 4px;
    width: 28px;
    height: 28px;
    background: var(--background-page);
    border-radius: 2px;
    position: absolute;
    left: 0;
    // background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.37728 11.9219L5.4716 11.0162C4.97141 10.516 4.97141 9.70505 5.4716 9.20486L9.09432 5.58214C9.59451 5.08195 10.4055 5.08195 10.9057 5.58214L14.5284 9.20486C15.0286 9.70505 15.0286 10.516 14.5284 11.0162L13.6227 11.9219M6.37728 8.07942L5.4716 7.17374C4.97141 6.67355 4.97141 5.86258 5.4716 5.36238L9.09432 1.73966C9.59451 1.23947 10.4055 1.23947 10.9057 1.73966L14.5284 5.36238C15.0286 5.86258 15.0286 6.67355 14.5284 7.17374L13.6227 8.07942M5.4716 14.6389L9.09432 18.2617C9.59451 18.7619 10.4055 18.7619 10.9057 18.2617L14.5284 14.6389C15.0286 14.1387 15.0286 13.3278 14.5284 12.8276L10.9057 9.20486C10.4055 8.70467 9.59451 8.70467 9.09432 9.20486L5.4716 12.8276C4.97141 13.3278 4.97141 14.1387 5.4716 14.6389Z' stroke='%23324854' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  input:checked + span:after {
    left: 32px;
  }

  input:checked + span:before {
    background-color: var(--secondary2-hover);
  }

  span.flowers:after {
    background-image: var(--unchecked-flower-icon)
  }
  input:checked + span.flowers:after {
    background-image: var(--checked-flower-icon)
  }

  span.granite:after {
    background-image: var(--unchecked-granite-icon);
  }
  input:checked + span.granite:after {
    background-image: var(--checked-granite-icon);
  }

  span.euro:after {
    background-image: var(--unchecked-euro-icon);
  }
  input:checked + span.euro:after {
    background-image: var(--checked-euro-icon);
  }
}

.engraving-alert {
  width: 500px;
  height: fit-content;
  top: 50% !important;
  left: 50% !important;
  padding: 10px 20px 15px 20px !important;
  background-color: var(--background-page) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  p {
    color: var(--fcdarkstlblue);
    font-family: $RalewayFont;
    font-size: 14pt;
    line-height: 1.5em;
    margin-top: 30px;
    text-align: center;
  }
  button {
    margin: 0 auto;
  }
}

.height-98vh {
  height: 98vh !important;
}

.height-100 {
  height: 100% !important;
}

.ConfigurationDetails {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;

  .detailsList {
    &::-webkit-scrollbar {
      width: 0.5rem;
      padding-right: 0.3125rem;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 0.3125rem;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
  
}
