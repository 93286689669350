.tooltip {
    position: relative;
    color: rgb(106, 101, 94);
    font-size: 18px;
    //   padding: 5px;
    //   color: rgb(0, 0, 0);
    opacity: 1;
    z-index: 9;
  }
  .tooltip.colorInfo{
    margin-top: 3px;
  }

  .tooltip:hover::before {
      /* top: 100%; */
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
  }

  .tooltip::before {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      min-width: max-content;
      background: rgb(27, 25, 25);
      color: #FFF;
      padding: 6px;
      border-radius: 4px;
      font-size: 15px;
      content: attr(tooltip-text);
      transition: all 0.3s ease;
  }

  .tooltip.top::before {
      bottom: 150%;
      left: 43px;
      transform: translateX(-90%);
  }

  .tooltip.right::before {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
  }

  .tooltip.bottom::before {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
  }

  .tooltip.left::before {
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
  }