.ConfigurationPatternsEngravings {
  .AddPattern, .AddEngraving, .AddPatternOptionPanel > .SquareButton {
    @include square-button-colors($color-bluegreen);
  }
  .fTopBarContainer{
    .backArrowContainer{
      cursor: pointer;
    }
  }
  .engraving{
    .accessory-actions{
      @media screen and (max-width: 768px){
        display: none;
      }
    }
  }
  .addElemB{
    font-family: $RalewayFont;
    font-weight: bold;
    background-color: white;
    position: absolute;
    bottom: 130vh;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 5px;
    padding: 16px 24px 16px 16px;
    border: 1px solid var(--fcdarkstlblue);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 241px;
    height: 45px;
    color: var(--fcdarkstlblue);
    cursor: pointer;
    @media screen and (max-width: 480px){
      /* position: absolute;
      top: 50px;
      bottom: unset;
      margin-bottom: unset; */
      position: fixed;
      bottom: 5px;
    }
    .IconAddBlocIcon{
      margin-right: 5px;
      svg{
        path{
          fill: var(--fcdarkstlblue);
        }
      }
    }
  }
  .addElemA{
    background-color: var(--fcblue);
    color: #FFFFFF;
    position: absolute;
    bottom: 130vh;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 5px;
    padding: 10px 24px 10px 16px;
    border: 1px solid var(--fcblue);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 241px;
    height: 45px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    &:hover{
      background-color: #FFFFFF;
      color: var(--fcblue);
      .IconAddBlocIcon{
        svg{
          path{
            fill: var(--fcblue);
          }
        }
      }
    }
    .IconAddBlocIcon{
      margin-right: 5px;
      svg{
        path{
          fill: #FFFFFF;
        }
      }
    }
  }
  .AddPatternOptionPanel {
    .OptionPanelPopup {
      margin-bottom: -1px;
      margin-top: -1px;
      position: fixed;
      top: 116px;
      button {
        width: 100px
      }
    }
  }
  .OptionPanelPopup {
    // left: 80px;
    margin-bottom: -1px;
    margin-top: -1px;
    position: fixed;
    top: 332px;
    border-radius: 10px;
    & > div:first-child button {
      border-radius: 10px 0 0 10px;
    }
    & > button:last-child {
      border-radius: 0 10px 10px 0;
    }
    button {
      width: 100px;
    }
  }

  .AddEngravingReservation {
    display: block;
  }

  .AddPatternOptionPanel,
  .AddReservationOptionPanel {
    @include smartphone{display:none}
    margin-bottom: -1px;
    .ModalButton {
      display: inline;
    }
    .OptionPanelSquareItem {
      font-size: 1.3em;
      line-height: normal;
    }
  }
  .OtherPiece{@include smartphone{display: none}}

  .Footer > .button-navigation {
    &.bottom50 {
      bottom: 50px;
    }

    &.bottom58 {
      bottom: 58px;
    }
  }
}

// @include smartphone {

// }

.EngravingParameters {
  @include transition(left 0.5s ease);
  // background-color: #fff;
  // border: 1px solid $color-grey-D;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  // top: 44px;
  left: -290px;
  padding: 5px 8px;
  position: absolute;
  width: 290px;
  z-index: 300;
  border-radius: 10px;
  .Label {
    color: var(--text-dark);
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-block;
    margin: 0.25rem 0;
  }

  label {
    margin-bottom: unset !important;
  }

  &.active {
    left: 155px;
    @include smartphone{
      left: 105px;
      top: 60px;
    }
  }

  .Row {
    @extend %columns;
  }
  .Headline {
    display: flex;
    align-items: center;
    margin: -5px -8.5px 0px -8.5px;
    padding: 8px;
    overflow: hidden;
    border-bottom: 2px solid #e2e2e2;
    h5 {
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
      color: var(--text-dark);
    }
    .Close {
      @extend %reset;
      @include column(right);
      color: $color-grey-6;
      font: font-default(14, normal, 32px);
      text-align: right;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: auto;
      @include transition();
      .icon {
        background-color: var(--secondary2-hover);
      }
    }
  }

  label {
    width: 100%;
    &.FontSize, &.TextAlign {
      // width: 50%;
    }
    &.Color {
      width: 100%;
    }
    &.Kind {
      select {
        cursor: pointer;
        // height: 30px;
        // border: 0;
      }
    }
    &.FontSize {
      .range-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        .range {
          width: 100%;
          position: relative;
          padding: 6px 0;
          -webkit-appearance: none;
          vertical-align: middle;
          outline: none;
          border: none;
          &::-webkit-slider-runnable-track {
            height: 6px;
            border-radius: 3px;
            border: 1px solid transparent;
          }
          &::-webkit-slider-thumb {
            -webkit-appearance: none !important;
            border-radius: 10%;
            background-color: var(--secondary2-hover);
            border: 2px solid var(--secondary2-hover);
            height: 18px;
            width: 30px;
            margin-top: -7px;
            position: relative;
            z-index: 9999999;
          }
        }
        .range-value {
          margin-left: 10px;
          border-radius: 4px;
          border: 1px solid #6A7782;
          padding: 8px;
          font-family: Raleway;
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 14px;
          font-weight: 500;
          width: 44px;
          text-align: center;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
  }
  
  .Field {
    @extend %columns;
    @include box-sizing(border-box);
    padding: 2px;
    select {
      outline: var(--border-color) auto 0.5px;
      border-radius: 6px;
      height: 2.25rem;
    }
  }
  input[type=number], select {
    @extend %border-radius;
    @include box-sizing(border-box);
    color: $color-grey-6;
    font: font-default(16, normal, 22px);
    padding: 2px 5px;
    width: 100%;
  }

  .Unit {
    color: $color-grey-9;
    font: font-default(18);
    padding-left: 0.5em;
  }
  .ColorPicker {
    display: flex;
    margin-right: -4px;
    height: 55px;
    .Color {
      flex: 1;
      @extend %border-radius;
      border: 2px solid $color-grey-B;
      cursor: pointer;
      margin: -2px 4px 0 -2px;
      padding: 2px;
      @include single-transition();
      color: var(--text-dark);
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 14px;
      &:hover {
        border-color: $color-grey-6;
      }
      &.active {
        border-color: $color-blue;
      }
      &:last-child {
        color: var(--text-light);
      }
    }
  }
  .FamilyPicker {
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
    @extend %border-radius;
    // height: 332px;
    overflow-y: auto;
    .Family {
      @extend %columns;
      color: var(--text-dark);
      cursor: pointer;
      font-size: font-size(18);
      @include single-transition();
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 0 6px;
      &:hover {
        // color: #324854;
      }
      label {
        cursor: pointer;
      }      
      &.active {
        // color: $color-grey-3;
      }

      .round {
        position: relative;
        width: 20px;
        input[type="checkbox"] {
          visibility: hidden;
        }
        input[type="checkbox"]:checked + label {
          background-color: #fff;
          border-color: var(--text-dark);
        }
        input[type="checkbox"]:checked + label:after {
          opacity: 1;
          background-color: var(--text-dark);;
        }
        label {
          background-color: #fff;
          border: 1px solid var(--text-medium);
          border-radius: 50%;
          cursor: pointer;
          height: 20px;
          left: 0;
          position: absolute;
          top: 0;
          width: 20px;
          &::after {
            opacity: 1;
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            top: 3px;
            left: 3px;
            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .AlignPicker {
    .Align {
      @include column(left);
      color: $color-grey-9;
      cursor: pointer;
      margin-right: 2px;
      @include single-transition();
      text-decoration: none;
      &:hover {
        color: $color-grey-3;
      }
      &.active {
        color: $color-blue;
      }
      .icon {
        @include material-size(24px, 28px);
      }
    }
  }

  /*
    label {

      .AlignPicker {
        i {
          color: grey;
          margin-right: 5px;

          &.active {
            color: deepskyblue;
          }
        }
      }

      > span {
        font-size: 14px;
        margin-left: 5px;
      }
    }*/
}

.PatternEngravingZone {
  background: $color-white;
  bottom: 0;
  left: 0;
  padding-bottom: 70px;
  position: absolute;
  right: 0;
  top: 70px;

  .Footer {
    background:var(--secondary2-hover);
    border-top: 1px solid $color-grey-D;
    bottom: 0;
    height: 70px;
    @include smartphone {
      display: none;
    }
    left: 0;
    position: fixed;
    right: 0;
    z-index: 900;
    .FullSize {
      bottom: 70px;
    }
    .Infos {
      @include column(left);
      margin: 15px;
    }
    .Actions {
      // @include column(right);
      margin: ((70px - 36px) / 2) 14px;
      display: flex;
      a, button {
        margin: 0 1px;
      }
    }
    .EditPattern, .EditText {
      @include button-text-colors($color-blue, $color-white);
    }
  }
}
.PatternEngravingZone{
  /* @include smartphone{position:relative;top:unset} */
  @media screen and (max-width: 480px){
    position: absolute;
    top: 15px;
  }
}
.Engraving {
  h1 {
    @extend %reset;
    font: font-default(16, normal);
  }
  .Kind {
    @extend %reset;
    // color: $color-grey-9;
    color: var(--text-light);
    display: inline;
    font-size: font-size(1400%/16);
    text-transform: uppercase;
  }
  .Text {
    @extend %reset, %text-overflow;
    // color: $color-grey-3;
    color: var(--text-light);
    font: font-default(20);
    max-width: 30em;
  }
}

.Pattern {
  color: var(--text-dark);
  h1 {
    @extend %reset;
    font: font-default(16, normal);
  }
  .Name {
    @extend %reset;
    display: inline;
  }
  .Kind {
    @extend %reset;
    display: inline;
    font-size: font-size(1400%/16);
    margin-left: 0.5em;
    text-transform: uppercase;
  }
  .Description {
    @extend %reset, %text-overflow;
    font: font-default(20);
    max-width: 30em;
  }
  .PatternSize {
    font-size: 18px;
    margin-left: 10px;
  }
}

.FaceCard {
  @extend %list-item;

  div.Face {
    @extend %image-only;
    cursor: pointer;
    display: block;
    min-height: 120px;
    position: relative;
    text-decoration: none;
  }
  img {
    position: relative;
    @include single-transition();
    z-index: 10;
  }

  .Infos {
    left: 0;
    padding: 10px;
    position: absolute;
    text-align: left;
    top: 0;
    @include single-transition();
    width: 40%;
    z-index: 700;
  }
  .Name {
    color: $color-grey-6;
    display: block;
    font: font-default(16);
    text-transform: uppercase;
  }
}

.FacePickerPattern {
  .ListViewChunk {
    clear: both;
    text-align: center;
    .ListViewItem {
      width: 33.3333%;
    }
  }
  figure {
    @include figure-height-relative(400/300);
    img {
      @include smartphone {
        transform: scale(0.8) translate(0%, 7.5%);
      }
    }
  }
}

.PatternPickerModal {
  .ListViewItem {
    width: 16.666666%;
    @include tablet() {
      width: 50%;
    }
    @include smartphone {
      width: 100%;
    }
  }
  .PatternCard {
    @extend %list-item;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
    figure {
      @include figure-height-relative(1);
      img:not(.configfLoader) {
        max-height: 95%;
      }
    }
  }

  &.pattern {
    .Header {
      // height: 150px;
      @include smartphone {
        height: unset;
      }
    }
    .ScrollListView {
      top: 160px;
      @include smartphone {
        top: 240px
      }
    }
    form {
      text-align: center;
      input {
        width: 25em;
        max-width: 100%;
      }
    }
    .Keywords {
      @extend %list-reset;
      text-align: center;
      li {
        display: inline-block;
        padding: 10px;
      }
      div {
        // color: $color-blue;
        cursor: pointer;
        font: font-default(16);
        text-decoration: underline;
      }
    }
    .More {
      margin-bottom: 20px;
      text-align: center;
      button {
        // @include button-text-colors($color-blue, $color-white);
        // padding: 15px 30px;
        // text-transform: uppercase;
      }
    }
  }
}

.ReactModalPortal {
  .PatternModalOverlay {
    @extend %border-radius;
    background: rgba(0, 0, 0, 0.8);
    bottom: 10px;
    left: 10px;
    position: fixed;
    right: 10px;
    top: 80px;
  }
  .PatternModal {
    background: $color-white;
    bottom: 60px;
    left: 60px;
    right: 60px;
    top: 60px;
    .Pattern{
      figure.LazyImage.FullSize{
        background:#707070;
      }
    }
    .CloseModal{
      span{color:white}
      i{color:white}
    }
  }
  .Pattern {
    .Infos {
      @include column(left);
      margin: 15px;
      @media screen and (max-width: 1113px) {
        margin-bottom: 0;
      }
    }
    .FullSize {
      bottom: 70px;
    }
    .AddPattern {
      @include button-text-colors($color-blue, $color-white);
    }
  }
}
