.Canton3DStyle{
  .Viewer3D {
    position: static!important;
    height:500px;
  }
  .Viewer3DContainer {
    position: static;
    height:500px;
  }
  .LoadingMessage {
    font: font-default(0.5em, bold, 12px);
    top:500px!important

  }
  .ViewerLoadingIcon {
    font: font-default(0.7em, bold, 12px)!important;
    top:400px!important
  }
  .Zoom {
    top:400px!important
  }
}
.CantonGraniteStyle{
  &,.ScrollListView {
    position: static!important;
    height:300px;
  }
 
  .GenericTextureCard{
     height:150px!important;
  } 
  .ListViewItem{
    width:150px;
    height:150px;
  }   
  .ListViewChunk{
    overflow:visible
  }
}
.Viewer3DContainer {
  &, .Viewer3D {
    background: $color-white;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }
  .Viewer3D {
    z-index: 100;
    > div {
      z-index: 50;
    }
  }
  .ViewerLoadingBar {
    background: $color-blue;
    height: 5px;
    left: 0;
    @include opacity(0);
    position: absolute;
    @include single-transition(all, 1s, ease);
    top: 0px;
    width: 0%;
    z-index: 200;
  }
  .ViewerLoadingIcon {
    background: transparent url("../../images/viewer3d/3d-icon.png") no-repeat center top;
    color: $color-grey-9;
    font: font-default(16);
    left: 75px;
    padding-top: 40px;
    position: absolute;
    text-align: center;
    top: 15px;
    width: 80px;
    z-index: 200;
    @include mdpi {
      background-image: url("../../images/viewer3d/3d-icon@3x.png");
      background-size: 80px 40px;
    }
  }
  .No360Message {
    color: $color-grey-9;
    font: font-default(16);
    left: 75px;
    position: absolute;
    top: 20px;
    z-index: 200;
  }
  .LoadingMessage {
    background: transparent url("../../images/viewer3d/loader.gif") no-repeat center top;
    background-size: 64px 64px;
    height: 100px;
    left: 50%;
    margin: -50px -150px;
    @include opacity(0);
    padding-top: 64px;
    position: fixed;
    text-align: center;
    top: 50%;
    @include transition(all 1s ease);
    width: 300px;
    z-index: 1;
    strong {
      color: $color-blue;
      display: block;
      font: font-default(2em, bold, 30px);
    }
    small {
      color: $color-grey-9;
      display: block;
      font: font-default(1.4em, normal, 20px);
      text-transform: uppercase;
    }
  }
  &.ImageMode .ViewerLoadingIcon {
    display: none;
  }
  .advice-message {
    position: absolute;
    color: grey;
    height: 40px;
    margin-left: 70px;
    visibility: visible;
    display: block;
    margin-top: 70px;
    font-size: 22px;
    z-index: 99999;

    @include smartphone {
      font-size: 12px;
    }
  }

  .Zoom {
    @extend %border-radius;
    border: 1px solid $color-grey-D;
    left: 10px;
    margin: -1px;
    position: absolute;
    top: 10px;
    width: 50px;
    z-index: 300;
    &:before {
      background: $color-white;
      bottom: -1px;
      content: '';
      display: none;
      left: -1px;
      @include single-transition();
      @include opacity(0);
      position: absolute;
      right: -1px;
      top: -1px;
    }
    button {
      @extend %square-button;
      border: 0;
      @include border-radius(0);
      height: 50px;
      margin: 0;
      width: 50px;
      .icon {
        @include material-size(24px, 50px);
      }
      &:first-child {
        &:before {
          border-bottom: 1px solid $color-grey-D;
          top: 50%;
          content: '';
          left: 0;
          margin: 0 5px;
          position: absolute;
          width: 40px;
        }
      }
    }
  }

  &.Loading {
    cursor: wait;
    .ViewerLoadingBar {
      @include opacity(1);
      @include single-transition(none);
    }
    .ViewerLoadingIcon {
      background-image: url("../../images/viewer3d/3d-icon.gif");
      color: $color-blue;
    }
    .LoadingMessage {
      @include opacity(0);
      @include transition(all 0s ease);
    }
    .Zoom {
      &:before {
        display: block;
        @include opacity(0.75);
      }
    }
  }
  &.LoadingFirstImage {
    .LoadingMessage {
      @include opacity(1);
      z-index: 200;
    }
  }
  @include smartphone{
    //height: 500px;
  }
}
