.FamilySpace{
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  .activationMessage {
    position: fixed;
    padding: 8px;
    text-align: center;
    font-size: 16pt;
    color: $fc-whitegrey;
    font-style: italic;
    top: 0;
    width: 100%;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.562);

    button {
      border: none;
      outline: none;
      padding-right: 10px;
    }
  }

  .LoadingIcon {height:100%;position:relative;left:unset;top:unset; /*margin-left: 20%*/;padding-top: 15%;min-height: 200px;display: flex;align-items: center;
    justify-content: center;z-index: 11;
                img{height:100px;}}
  .row { margin-right: unset;margin-left: unset;}
  hr{ margin-top: 42px;}
  .full{hr{display: none;}}
  }
.FamilySpace .RightColumn{
  /*margin-left: auto;
  margin-right: auto;*/
  padding-right: unset;
  padding-left: unset;
  //margin-right: auto;
  @media screen and (max-width:576px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .Toppest{
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: 320px) and (max-width: 576px){
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      padding-left: 20px;
      padding-right: 15px;
    }
    @media screen and (max-width: 320px){
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      height: 50px;
      margin-top: 5px;
      margin-bottom: unset;
    }
    .load-project-wrapper{
      @media screen and (max-width: 320px){
        margin-bottom: 10px;
      }
      @media screen and (max-width: 576px){
        width: 40%;
      }
      .LeftOvalAction{
        @media screen and (max-width: 576px){
          padding: unset;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img{
          @media screen and (max-width: 576px){
            margin-right: 5px;
            margin-left: unset;
            width: 14px;
          }
        }
      }
    }
    .SsearchActions{
      @media screen and (max-width: 576px){
        margin-left: auto;
        width: 58%;
      }
    }
  }
  }
.FamilySpace{
  .listProjects::-webkit-scrollbar {
    width: 5px;                /* width of the entire scrollbar */
  }
  .listProjects::-webkit-scrollbar-track {
    background: #F0F0F0;        /* color of the tracking area */
    border-radius: 10px;
  }
  .listProjects::-webkit-scrollbar-thumb {
    background-color: #E0E0E0;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
  }
}
.FamilySpace .listProjects{
  height:calc(100vh - 170px);
  overflow-y: auto;
  padding-bottom: 30px;
  &.hide{display:none}
  .loadingBloc{
    margin: auto;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    left: 50%;
    .loadPicto{
      width: 190px;
      @media screen and (max-width: 576px){
        width: 135px;
      }
    }
  }
.TheScroll{
  display:flex;
  flex-wrap:wrap;
  margin-bottom: 75px;
  .col-md-12{
    @media screen and (max-width: 320px){
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}}
/* Search */
.topPage{
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  @media screen and (max-width: 320px){
    padding-bottom: unset;
    padding-right: 5px;
    padding-left: 5px;
  }
  .Ssearch {
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    //border: 1px solid $fc-whitegrey;
    box-shadow:  0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    height: 40px;
    @media screen and (max-width: 992px){
      margin-bottom: 10px;
    }
    width:100%;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    input{border:none;background:none;width:100%;outline: none; line-height: 27px;font-size: 1.8em;}
    i{color:$fc-lightgrey;position: relative;line-height: 30px;}
  }
  .load-project-wrapper{
    position: relative;
    display: flex;
    padding-right: unset;
    padding-left: unset;
    @media screen and (max-width:767px){padding-left: unset;}
    @media screen and (min-width: 992px){margin-left: auto;}
    .LeftOvalAction{
      float: unset;
      padding-right: 10px;
      padding-left: 10px;
      /* padding-top: unset; */
      margin-right: unset;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 98%;

      @media screen and (max-width: 992px){width: 100%;}
      &::after{
        content:'Créer un nouveau projet de monument';
        // font-size: 16px;
        padding-top: 3px;
        @media screen and (min-width: 1009px) and (max-width: 1460px){
          //font-size: 16px;
          content: 'Créer un nouveau projet';
        }
        @media screen and (min-width: 991px) and (max-width: 1009px){
          font-size: 17px;
          content: 'Créer un nouveau projet';
        }
        @media screen and (min-width: 576px) and (max-width: 991px){
          content:'Créer un nouveau projet de monument';
          font-size: 18px;
        }
        text-align: center;
        @media screen and (max-width: 576px){
          content: 'Nouveau projet';
          font-size: 15px;
        }
      }
      img{
        margin-left: unset;
        margin-right: 5px;
      }
    }
  }
}

.load-project-wrapper {
  /*position: absolute;*/
  img{width: 20px;}
  .LeftOvalAction{
    //width:350px;
    //position: absolute;
    right: 10px;
    font-weight:normal;
  }
}
.ReactModal__Overlay.familyspace-nexproject-overlay{ background: unset;
  .familyspace-newproject-modal{
    background: unset;border: none;
    //width: 300px;
    height: 150px;
    box-shadow: none;
    position: fixed;
    //right: 70px;
    left: unset;
    //top: 81px;
    right: 6%;
    top: -4px;
    @media screen and (min-width: 576px) and (max-width: 992px){
      height: 10px;
      right: 0;
      left: 34%;
      top: 47px;
      width: 62%;
    }
    @media screen and (max-width: 575px){
      left: 0;
      right: 0;
      width: 96%;
      top: 125px;
      height: 90px;
    }
  }
  .load-project-menu {
    height: fit-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000029;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    z-index: 999;
    position: fixed;
    top: 76px;
    width: 30%;
    left: unset;
    right: unset;
    @media screen and (max-width: 576px){
      top: 125px;
      width: 96%;
      left: 1.1%;
      right: unset;
    }
    @media screen and (min-width: 576px) and (max-width: 768px){
      /*
      top: 130px;
      width: 63vw;
      right: 10vw;
      left: unset;
      */
      width: 60%;
      right: 20%;
      top: 200px;
    }
    @media screen and (min-width: 768px) and (max-width: 992px){
      /* 
      top: 128px;
      width: 64vw;
      right: 1.5vw;
      left: unset; 
      */
      width: 60%;
      right: 20%;
      top: 200px;
    }
    @media screen and (min-width: 992px){
      /*
      width: 25vw;
      right: 9%;
      left: unset;
      top: 81px;
      */
      right: 29%;
      top: 160px;
    }
    ul {
      list-style-type: none; /* Remove bullets */
      padding: 10px; /* Remove padding */
      //margin: 5; /* Remove margins */

      li {
        /* text-decoration: underline;
        font: normal normal bold 18px/21px Cuprum;
        color: var(--fcnavyblue);
        cursor: pointer; */
        letter-spacing: -0.27px;
        opacity: 1;
        margin-top: 10px;
        font-size: 16px;
      }
    }
  }
}



.SsearchActions {
  margin: 6px 10px 6px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 576px){
    text-align: left;
  }
  svg {
    cursor: pointer;
    margin-right: 5px;
    color:$fc-lightgrey;
    //font-size: 18pt;
    width: 16px;
    height: 16px;
  }
  .archiveIcon{
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../images/space/archive_filled_box.svg');
    /*&:hover{
      background-image: url('../../images/space/archive_filled_box_blue.svg');
    }*/
  }
  span {
    cursor: pointer;
    margin-left: 5px;
    // color:$fc-lightgrey;
    font-size: 12pt;
    &:hover{
      //color: var(--fcnavyblue);
      // text-decoration: underline;
    }
  }

  &.back {
    //padding-right: 30px;
    @include smartphone{
      margin-left: auto;
      margin-right: 2%;
    }
    span{
      @include smartphone{
        margin-left: unset;
      }
    }
    svg, span {
      // color: #3558AC;
    }
  }
}

.archive-title {
  margin-top: 5px;
  /*@include smartphone{padding-left: 15px;}*/
  div{
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
  }
  span {
    text-align: center;
    // font: italic normal normal 22px/26px Cuprum;
    font-size: 15px;
    letter-spacing: -0.33px;
    color: #8C8E8E;
    opacity: 1;
  }

  .hr {
    border-bottom: 1px solid #D9D9D9;
  }
}

/* Project list item */
.SprojectHeader{
  .Name{ width: 75%; display: inherit;float:left;padding-left: 10px;
    @media screen and (min-width: 1100px) and (max-width: 1320px){width: 70%;}
    @media screen and (min-width: 992px) and (max-width: 1100px){width: 65%;}
    @media screen and (min-width: 768px) and (max-width: 992px){width: 80%;}
    @media screen and (min-width: 625px) and (max-width: 768px){width: 70%;}
    @media screen and (max-width: 625px){width: 65%;}
    .title{width: 100%;}
  }
  .ProjectCompare{
    padding-left: 10px;
    font-size: 16px;
    cursor: pointer;
    color:var(--fcnavyblue);
    text-decoration: underline;
    position: relative;
    z-index: 9;
    width: 70px;
  }
  .Archive-actions{
    font-size: 15px;
    float:right;
    color: $fc-lightgrey;
    /* text-decoration: underline; */
    display: block;
    margin-left: auto;
    z-index: 1;
    margin-right: 5px;
    margin-top: 10px;
        svg {
          vertical-align: text-top;
          font-size: 14pt!important;
          margin-right: 5px;
        }
        .unarchive-action {
          color: #3558AC;cursor: pointer;
          margin-bottom:2px;
        }
        .remove-action {
          color: #9D0226;cursor: pointer;
        }
  }
}
.listProjects {
  .Sproject {
    .dispContainer {
      position: relative;
      margin-top: -20px;
      top: -25px;
      .LeftOvalAction {
        /* padding: 9px 90px; */
        margin: 0 auto;
      }
    }
  }
}

/* catalog header */
.Fcatalog{
  .Header{
    .FamilyAccess{
      &.B{
        margin-top: unset;
        width: unset;
      }
      .AccessSpace{
        .LeftOvalAction{
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: unset;
          margin-right: 5px;
          margin-left: 5px;
          float: unset;
          width: 230px;
          position: relative;
          padding-right: unset;
          img{
            margin-left: unset;
          }
        }
      }
    }
  }
}
/* Project */
.FamilySpace {
    .Header{
      @media screen and(max-width: 576px){
        background-color: var(--f-main-color) !important;
        padding-top: unset !important;
        overflow: unset;
      }
      @media screen and (max-width: 992px){
        flex-wrap: nowrap !important;
        padding: 20px 0 !important;
      }
    }
  .Sproject{ 
    background-color: #FFFFFF; /*border:1px solid $fc-whitegrey;*/
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    box-shadow:  0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin:0 0 20px 0;

                          .quote{background-color: $fc-whitegrey;color:$fc-blackish;margin:5px;&:hover {background-color: #545454;color:white}}
                          .dispImage{/*padding-bottom: 10px;*/
                            text-align: center;
                            &.not-full {
                              min-width: 100%;
                              overflow:hidden;
                              height: 265px;
                              // margin-top: -45px;
                              @media screen and (max-width: 425px){
                                // height: 200px;
                              }
                            }
                            &.full {
                              min-width: 100%;
                              overflow:hidden;
                              height: 265px;
                              margin-top: -45px;
                              @media screen and (max-width: 425px){
                                height: 200px;
                              }
                            }
                            /*&.arcDispImg{
                              height: 300px;
                            }*/
                            .LoadingIcon {margin-left: unset;height:398px}
                            .ZoomProject{
                              position: absolute;
                              z-index: 10;
                              background-color: var(--f-main-color);
                              padding: 10px;
                              border-radius: 50%;
                              top: 44%;
                              -webkit-transform: translate(-50%, -50%);
                              transform: translate(-50%, -50%);
                              left: 50%;
                              cursor: pointer;
                              &:hover{
                                background-color: var(--f-font-color);
                              }
                              .ZoomIcon{
                                width: 16px;
                                height: 16px;
                              }
                            }
                          }
                          .monumentLoading{
                            background:url(../../images/icons-config-famille/loading.svg) center center no-repeat;
                            background-size: 190px 110px;
                            @media screen and (max-width: 576px){
                              background-size: 135px 75px;
                            }
                            z-index: 11;
                            position: relative;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            object-fit: contain;
                            height: 100%;
                            width: 100%;
                          }
                          .monumentImg{
                            cursor: pointer;
                            position: relative;
                            height: 100%;
                            //width: 100%;
                            object-fit: contain;
                            //padding-left: 10%;
                            &.hide{display: none;}
                          }
                          .transformImg {
                            transition: all .2s ease-in-out;
                            &:hover{
                              transform: scale(1.06);
                            }
                          }
                          .monumentImg.maxH.not-full{max-height: 348px;}
                          .monumentImg.quoteImg {
                            height: 324.203px;
                            padding: 50px;
                          }
                            span{margin-left: 5px;}
  &:hover {
    border: 1px solid var(--f-main-color);
  }
  // &.full {
  //   border: none !important;
  // }
}
}
/* Project Header */
.FamilySpace{
  .SprojectHeader{
    //padding-top: 10px;
    // padding-bottom: 3px;
    .headerUp{
      /* display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
      height: 45px;
      z-index: 9;
      position: relative; */
    }
    .headerDown{
      /* position: relative;
      padding-top: 2px;
      display: flex;
      height: 20px; */
    }
    hr{
      margin-top: unset;
      margin-bottom: unset;
    }
    .project-side {
      display: block;
      margin-left: auto;
      position: relative;
      margin-right: 5px;
      .ProjectMenuContent{
        position: absolute;
      }
    }
      .project-side-full{
        position: inherit;
        padding-left: 20px;
        margin-right: unset;
        .ProjectMenu{
          //background-color: var(--fcnavyblue) !important;
          //padding: unset;
          cursor: pointer;
          width: unset;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: unset;
          padding-right: unset;
          span{
            //color: #F1F4F7 !important;
          }
          .ProjectMenuContent{
            right: 50px !important;
           }
        }
        .pms-margin{
          margin-left: unset;
        }
        .SheaderActions{
          @media screen and (max-width: 767px){
            right: 0;
          }
        }
      }
      .ProjectMenu {
        //background-color: #F1F4F7;
        padding: 5px 10px 5px 10px;
        width: 100px;
        text-align: center;
        display: inline-block;
        border-radius: 30px;
        border: 1px solid var(--fcnavyblue);
    @media screen and (max-width: 576px){
          border-radius: unset;
          border: unset;
          background-color: unset;
          padding: unset ;
          width: unset;
          display: unset;
        }
        span {
          color: var(--fcnavyblue);
          text-decoration: none;
          font-size: 14pt;
          /*&:hover{
            color: #F1F4F7;
          }*/
        }
        svg {
          width: 24px;
          float: right;
          fill: var(--fcnavyblue);
          @media screen and (max-width: 576px){
            width: 36px;
            font-size: 36px;
          }
          /*&:hover{
            fill: #F1F4F7;
          }*/
        }
        &:hover{
          background-color: rgba(53,104,186,0.08);
          cursor: pointer;
          /*span{
            color: #F1F4F7;
          }*/
          /*svg{
            fill: #F1F4F7;
            @media screen and (max-width:576px){fill:var(--fcblue);}
          }*/
        }
      }
      .ProjectMenuContent{
        position: relative;
        width: 170px;
        //background-color: #F1F4F7;
        background-color: white;
        border-radius: 8px;
        padding: 8px 0;
        right: 2px;
        z-index: 1000;
        margin-top: 5px;
        //box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        @media screen and (max-width: 576px){
          display: flex;
          justify-content: center;
          border-radius: 12px;
          width: 460px;
          margin-top: 0;
          background-color: white;
          top: -9px;
          right: -3px;
        }
        @media screen and (max-width: 320px){width: 88vw;}
        @media screen and (min-width: 321px) and (max-width: 400px){width: 90vw;}
        @media screen and (min-width: 400px) and (max-width: 520px){width: 92vw;}
        @media screen and (min-width: 520px) and (max-width: 576px){width: 93vw;}
        &.ProjectMenuContentFull{
          @media screen and (max-width: 576px){
            top: 45px;
            width: 96.5vw;
            right: 2vw;
          }
        }
          svg{
            margin-left: auto;
            font-size: 36px;
            fill: var(--fcnavyblue);
            cursor: pointer;
            margin-top: -9px;
            margin-right: -9px;
            &:hover{
              fill: var(--fcblue);
            }
          }
          .action{
            display: block;
            cursor: pointer;
            padding: 4px 8px;
            text-decoration: none;
            font-size: 16px;
            color: var(--fcnavyblue);
            &:hover{
                /*color: var(--fcblue);*/
              background-color: rgba(0,0,0,0.08);
              }
            @media screen and (max-width: 576px){
              padding-top: 5px;
              padding-bottom: 10px;
            }
          }
      }
      .ProjectMenuContentFull{
        right: inherit;
        //right: 80%;
        border-radius: 12px;
        //box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      }
      .fade-in {
        animation: fadeIn ease 0.5s;
        -webkit-animation: fadeIn ease 0.5s;
        -moz-animation: fadeIn ease 0.5s;
        -o-animation: fadeIn ease 0.5s;
        -ms-animation: fadeIn ease 0.5s;
        }
        @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        @-moz-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
    }
  }
  .logoClient,.clientName{font-size:11px;cursor:unset;float: right;line-height: 27px;}
  .title{cursor:pointer}
  /* Project Price */
  .ProjectPrice{
    /* display: flex;
    align-items: center;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 10;
    .Topprice{
      font-size: 17px;
      color: #6A7782;
    } */
    img{padding-left:5px;cursor: pointer;}
  }
  .PriceToTheLine{
    top: 60px;
    @include smartphone{position: unset;width:200px;}
  }
/* Project Footer */
.FamilySpace{ .Sproject{.SprojectFooter{
  text-align:center;
  font-size:15px;
  color:#6A7782; 
  position: relative; 
  /* height: 45px; */
  padding: 0 5px;
  .row.d-flex { 
    padding: 10px 0px;
    div{
      padding-left: 0px;
      padding-right: 0px;
      svg:hover {
        opacity: 0.7;
      }
    }
    // div:last-child{padding-right: 5px; padding-left: 0px;}
    img {
      min-width: 24px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    svg {
      cursor: pointer;
    }
  } 
  .ask {margin: 0 !important; }  
  .block{ /* border-right:  1px solid; */ border-color:#E0E0E0;cursor: pointer;height: 45px;display: flex;align-items: center;padding: 0;width: 100%;justify-content: center; margin: 0 14px; max-width: 35px;
    &:first-child{
      &:hover{
        border-bottom-left-radius: 8px;
      }
    }
    &:hover{/*color:#234479;*/ background-color: #f5f5f5;/*border-bottom: 1px solid #E0E0E0;*/}
    &.last{
      // background-color: #3568ba !important;
      // color: white;
      // font-size: 15px;
      // border-radius: 0 0 8px 0;
      // border-right: unset;
      // &:hover{
      //   border-bottom-right-radius: 8px;
      //   background-color: #3160AB;
      //   //border-bottom: 1px solid #3160AB;
      // }
    }
    &.col-sm-4{
      span{
        /*@media screen and (max-width: 576px) {
          display: none;
        }*/
      }
    }
    &.ask{
      background-color:unset;
      color:unset;
      &:hover{
        background-color: #f5f5f5;
        border-bottom-right-radius: 10px;
        //border-bottom: 1px solid #E0E0E0;
        //border-right: 1px solid #E0E0E0;
      }
    }
    &.top-left {
      top: -600px;
      right: 50%;
    }
    div{
      //display: inline-block;
      //padding-top: 2%;
      width: 100%;
      padding: 10px 0;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img{margin-left: 1px;}
      span{
        text-align: center;
      }
    }
  }
  .comments{padding-top: 9px;padding-bottom: 9px;}
  hr {margin: 0}
  }

}}

/* Menu */
.FamilySpace{
  .LeftArea {padding-left: unset; padding-right: unset;}
  .Smenu{
    /* max-width:250px; */
    /* border-radius: 0px 10px 10px 0px; */
    background-color:var(--f-main-color);
    @media screen and (max-width: 576px){
      height: 70px;
      padding-top: 18px;
      margin: unset;
    }
    font-size:18px;color:var(--f-font-color);font-weight: bold;margin:10px 0px 10px 0px;
                     .actionMenu{
                       height: 56px;
                       padding-top: 7px;
                       padding-bottom: 7px;
                       cursor: pointer;
                       align-items: center;
                       display: flex;
                       border-top-style: solid;
                       border-top-color: $fc-whiteish;
                       border-top-width: 1px;
                       &:first-child{border-top-right-radius: 10px;}
                       &:last-child{border-bottom-right-radius: 10px;}
                      &.active{
                        font-size:22px;
                        background-color: rgba(0,0,0,.24);}
                      &:hover{background-color: rgba(0,0,0,.08);}}
                     img{
                       margin-left:24px;
                       margin-right:12px;
                       @media screen and (min-width: 576px) and (max-width: 600px){
                         margin-left: 5px;
                         margin-right: 5px;
                       }
                       @media screen and (max-width: 576px){
                         margin-left: 0;
                         margin-right: 12px;
                       }
                     }
  }}

/* Header*/
.LeftOvalAction.Blue.Add{@include smartphone{position:absolute;margin-top:65px;z-index: 1000;
                                              &.ManualQuote{right:2px}}}
 .LeftOvalAction {
   // width: fit-content;
   // font-weight: bold;
   // font-size: 18px;
   // cursor: pointer;
   // padding: 10px;
   float: right;
   height: 42px;
   border-radius: 50px;
   margin: 0 10px;
   border: unset;
  
   @include smartphone{
     float:unset;margin: unset;
     margin-top: 5px;
     padding-top: 11px;
     font-size: 15px;
   }
  img{
    margin-left:17px;
    margin-right:12px;
    @include smartphone{
      margin-left: 12px;
    }
  }
  &.Grey{color: $fc-blackish;background-color:$fc-whitegrey;&:hover {background-color: #545454;color:white}}
  &.Blue{color: #F5F5FF;background-color:var(--fcnavyblue);&:hover {background-color: #234479}}
  &.BlueA{color: #F5F5FF;background-color:var(--fcblue);height:35px;display: flex;align-items: center;justify-content: center;margin-left:auto;margin-right:auto;padding-top:unset;font-size:16px;&:hover {background-color: #234479};
    img{margin-left:10px;width:27px;}}
 }
.FamilySpace .Header{
  //background-color:$fc-darkgrey;
  background-color: #fff;
  height:73px;
  margin-left: 0;
  height: 90px;
  padding: 20px 90px;
  display: flex;
  flex-wrap: wrap;
  @include smartphone{
    padding-top: unset;
    flex-wrap: unset;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  .logoContainer{
    margin-top: 1.45px;
    margin-left: 10px;
  }
  .Back{@include smartphone{display:none}}
  /*img{margin-top: 0;}*/
  .logobg{cursor: pointer;}
  .FamilyInfo {
    top: 0;
    padding-top: 18px;
    height: 73px;
    background: #222B33;
    padding-left: 10px;
    padding-right: 10px;
    right: 0;
    position: absolute;
    font-size: 16px;
    color: #FFFFFF;
    width: 290px;
    display: flex;
    @media screen and (max-width: 576px){
      position: relative;
      display: flex;
      width: 55%;
      margin-left: auto;
      height: 90px;
      padding-top: 26px;
    }
    .familyName{text-transform: uppercase;font-weight: bold;}
    .familyEmail{
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 9pt;
    }
    .familyInfoDetails{
      width:80%;
      @include smartphone{
        padding-left: 2%;
      }
    }
    .familyInfoIcon{
      width: 20%;
    }
    //div{display:inline-block;}
    img{margin-right:5px;margin-bottom: 15px;}
  }
}
.Sprice .FbigDetailB{ position:fixed;right: 20vw;}

/* Access */
.FamilyAccess{
  position: fixed;
  right: 165px;
  top: 0px;
  width: 165px;
  height: 70px;
  background: #222B33;
  padding-top:15px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  img{float: left;margin-right: 8px;margin-left: 10px}
    &.A{right:0!important;max-height:50px;padding-top:7px;@include smartphone {right:unset;left:100px}}
    .Icon {img{width:30px} div{padding-top:3px}}
    &.A{
      height: unset;
      padding-top:  15px;
      width: unset;
      background-color: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

/* Full image */
.Sproject.full {
  border:none ;
  box-shadow: unset;
  margin-right: 5px;
  margin-left: 5px;
  background-color: unset;
  .shortIcons {justify-content: center !important;}
  .iFrameDoc{background:url(../../images/loader-config-500-new.gif) center center no-repeat;
    background-size: 25% 25%;}
  iframe {min-height: 610px}
  .monumentImg{
    height: 100%;
    @media screen and (max-width: 1200px){object-fit: contain;}
  }
  .SprojectHeader {
    // display: flex;
    // /* align-items: center; */
    // flex-direction: column;
    // position: absolute;
    // padding-left: 15px;
    // background-color: #fff ;
    @media screen and (max-width: 767px){
      display: block;
      padding-bottom: 10px;
      width: 100%;
    }
    .SheaderActions {
      @media screen and (max-width: 576px){right: 1.7vh;}
    }
    .BackToProjects{
      display: flex;
      align-items: center;
      cursor: pointer;
      @media screen and (min-width: 576px) and (max-width: 767px){
        padding-top: 5px;
        padding-bottom: 5px;
      }
      @media screen and (max-width: 576px){
        padding-top: 15px;
        padding-bottom: 5px;
      }
      span{
        font-size: 12pt;
        color: var(--fcnavyblue);
        font-weight: bold;
        padding-left: 5px;
      }
    }
    .headerUp{
      @media screen and (max-width: 767px){
        width: 100%;
        position: relative;
        z-index: 9999;
      }
      .Name{

        .title{
          padding-bottom: unset;
        }
      }
    }
    .headerDown{
      /* display: flex;
      align-items: center;
      padding-top: unset; */
      @media screen and (max-width: 767px){
        width: 100%;
        height: 12px;
      }
      .project-side-full{
        margin-left: unset;
      }
    }
    .full{pointer-events: none;cursor: default;}
    .Name{width: unset;
      .title{
        /* width: 100%;
        height: 100%;
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
        padding-bottom: 15px; */
      }
    }
    .ProjectPrice{
      //z-index:10;
    }
    // background-color:$fc-whiteish;
    .archive-action {
      color: $fc-lightgrey;
      text-decoration: underline;
      font-size: 17px;
      letter-spacing: 0px;
      line-height: 27px;
      float: right;
      margin-right: 15px;
      cursor: pointer;

      svg {
        vertical-align: sub;
        font-size: 14pt!important;
        margin-right: 5px;
      }
    }

    .arrowback{cursor: pointer;float:left}
  }
  .SprojectHeaderActions {padding-top:5px;padding-bottom:0;border-top: 1px solid $fc-whitegrey;}
  .dispImage {
    padding-left: unset;
    padding-right: unset;
    height: 100%;
    .Viewer3DContainer{
      width: 93%;
      margin-left: 5%;
    }
    .monumentImg{
      cursor: unset;
      padding-top: 20px;padding-bottom:20px;
      width: 100%;
    }
  }
  .shortcutsContainer{
    @media screen and (max-width: 767px){
      margin-top: 10px;
    }
    .docIcons{
      .scroll-slider{
        @media screen and (max-width: 767px) and (min-width: 375px){
          display: flex;
          align-items: center;
          justify-content: left;
          margin-bottom: 12vh;
        }
        @media screen and (max-width: 375px){justify-content: start; display: flex; margin-bottom: 12vh;}
        .thumbnail-wrapper {
          .docTitle{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
            text-align: center;
          }
          @media screen and (max-width: 767px) {
            height: 100px;
            background-color: white;
            border-radius: 10px;
            padding: 10px;
            margin: 5px 0 5px 5px;
            border: 1px solid #E6E6E6;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 3px #e0e0e0;
            cursor: pointer;
          }
          @media screen and (min-width: 715px) and (max-width: 767px) {
            width: 32.25%;
            height: 135px;
          }
          @media screen and (min-width: 684px) and (max-width: 714px) {
            width: 32.2%;
            height: 130px;
          }
          @media screen and (min-width: 585px) and (max-width: 683px) {
            width: 32%;
            height: 115px;
          }
          @media screen and (min-width: 576px) and (max-width: 584px) {
            width: 31.9%;
            height: 114px;
          }
          @media screen and (min-width: 498px) and (max-width: 575px) {
            width: 32.3%;
            height: 140px;
          }
          @media screen and (min-width: 420px) and (max-width: 497px) {
            width: 32.2%;
            height: 118px;
          }
          @media screen and (min-width: 385px) and (max-width: 455px) {
            width: 32%;
            height: 115px;
          }
          @media screen and (min-width: 336px) and (max-width: 384px) {
            width: 31.8%;
            height: 114px;
          }
          @media screen and (max-width: 336px) {
            width: 31.5%;
            height: 110px;
          }
          @media screen and (max-width: 320px) {
            height: 85px;
          }
          .trash-wrap {
            margin-top: 80px;
          }
        }
        .shortIcons{
          .thumbnail-wrapper{
            .img-wrap {
              @media screen and (max-width: 767px){
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &.mainImage{
                .thumbnail-img {
                  @media screen and (max-width: 767px){
                    margin-left: 8px;
                    object-fit: contain;
                  }
                }
              }
            }
          }
          .AddBlock{
            cursor: pointer;
            @media screen and (max-width: 767px){
              height: 100px;
              width: 32.25%;
              padding: 6px 5px 0 5px;
              margin: 5px 0 5px 5px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            @media screen and (min-width: 715px) and (max-width: 767px){width: 32.25%;height: 135px;}
            @media screen and (min-width: 684px) and (max-width: 714px){width: 32.2%;height: 130px;}
            @media screen and (min-width: 585px) and (max-width: 683px){width: 32%;height: 115px;}
            @media screen and (min-width: 576px) and (max-width: 584px){width: 31.9%;height: 114px;}
            @media screen and (min-width: 498px) and (max-width: 575px){width: 32.3%; height: 140px;}
            @media screen and (min-width: 420px) and (max-width: 497px){width: 32.2%; height: 118px;}
            @media screen and (min-width: 385px) and (max-width: 455px){width: 32%; height: 115px;}
            @media screen and (min-width: 336px) and (max-width: 384px){width: 31.8%; height: 114px;}
            @media screen and (max-width:336px){width: 31.5%; height: 110px;}
            .AddBlockContain{
              @media screen and (max-width: 767px){
                /*height: 85px;*/
                width: 95%;
                margin-left: auto;
                margin-right: auto;
              }
              .AddIcon{
                @media screen and (max-width: 767px){
                  width: 24px;
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: 5px;
                  color: var(--fcnavyblue);
                }
                svg{
                  @media screen and (max-width: 767px){
                    font-size: 20px;
                  }
                }
              }
              .AddText{
                @media screen and (max-width: 767px){
                  text-align: center;
                  color: var(--fcnavyblue);
                  font-size: 12pt;
                  font-weight: bold;
                }
              }
            }
            &:hover{
              background-color: red;
            }
          }
        }
      }
    }
  }
  .slider {
    // border-left: solid 1px;
    // border-color: lightgray;
    //background-color: #FFFFFF;
    padding: 0;
    height: 100%;
   .docIcons{
    .AddBlock {
      cursor: pointer;
      display: flex;
      width: 100%;
      color: var(--fcnavyblue);
      align-items: center;
      justify-content: center;
      padding: 3px 10px 3px 10px;
      .AddText{
        width:100%;
        font-size: 16px;
        letter-spacing: -0.23px;
        padding-left: 5px;
        margin-bottom: unset;
      }
      .AddIcon{float:left;width:24px;padding-top: 5px;cursor: pointer;
        svg {font-size: 22pt; }
      }
      &:hover{
        background-color: #f5f5f5;
        border-top-right-radius: 10px;
      }
    }
    .SeparationLine { margin: 3px 0px 0px 0px ;border-top: 1px solid rgba(0,0,0,.1);}
    .scroll-arrow {
      z-index: 11;
      position: absolute;
      width: 48px;
      height: 48px;
      left: 50%;
      transform: translateX(-50%);
      .arrow-icon { @include smartphone { display: none;}
        padding: 10px;
        border-radius: 50%;
        border: 1px solid #bdc3c7;
        &:hover{
          background-color: rgba(255,255,255,0.5);
          cursor:pointer;
        }
      }
      .arrow-up-icon{
        transform: rotate(90deg);
        width: 40px;
        height: 40px;
      }
      .arrow-down-icon{
        transform: rotate(-90deg);
        width: 40px;
        height: 40px;
      }
    }
    .arrow-down{
      top: 59vh;
      @media screen and (max-height: 680px){
        top: 58vh;
      }
      @media screen and (max-height: 500px){
        top: 56vh;
      }
    }
    .arrow-up{
      padding-top: 5px;
    }
    .scroll-slider {
      overflow-y: hidden;
      overflow-x: hidden;
      height: 57vh;
      text-align:center;
      padding-top: 52px;
      /*@media screen and (max-width:819px){height: 237px;}*/
      @include smartphone {height:unset;padding-top:0;}
      .thumbnail-wrapper {
        .docTitle{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          padding-left: 5%;
          padding-right: 5%;
          text-align: center;
        }
        height: 130px; @include smartphone {height:unset;};
        width: 80%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #0000001A;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        /* margin-left: 10%; */
        display: block;
        align-items: center;
        justify-content: center;
        &:active{border: 1px solid #1F82C4};
        position: relative;
        &.mainImage{
          padding-top: unset !important;
          padding-bottom: unset !important;
        }
        .img-wrap {text-align: center;cursor: pointer;width: 100%;height: 100%;padding: 5px;display:flex;align-items:center; @include smartphone{height:unset;}
          &.pdf,&.doc,&.devis,&.gravure,&.vue3D,&.dessin{
            .thumbnail-img{
              margin-left: unset;
            }
          }}
          /*.img-wrap.vue3D{.thumbnail-img {width:100px;height:unset}}*/

        .thumbnail-img {margin-left: 3px;cursor: pointer;height: 70%;width: 100%;object-fit: contain;
          @include smartphone{height: unset;min-width: 100px;}}
          .mainImage{.thumbnail-img{max-height: unset}}
        .trash-wrap {
          background: #F2F2F2 0 0 no-repeat padding-box;
          opacity: 1;
          width: 36px; height: 36px;
          text-align: center;
          border-radius: 20px;
          position: absolute;bottom: 10px;right: 10px;
          img {
            margin-top: 8px;
            cursor: pointer;
            max-height: 150px;
            height: auto;
          }
        }
        &:first-child{
          .img-wrap{
            .thumbnail-img{
              height: 100%;
            }
          }
        }
      }
    }
    .scroll-slider::-webkit-scrollbar {
      width: 12px;               /* width of the entire scrollbar */
    }
    .scroll-slider::-webkit-scrollbar-track {
      background: #FFFFFF;        /* color of the tracking area */
      border-radius: 0 0 10px 0;
    }
    .scroll-slider::-webkit-scrollbar-thumb {
      background-color: var(--fcnavyblue);    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
      border: 3px solid #F1F4F7;  /* creates padding around scroll thumb */
    }
  }
}
  .SprojectFooter{
    border-top: 1px solid #E0E0E0;
    background-color: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    /* border-bottom: 1px solid #E6E6E6; */
    /* border-left: 1px solid #E6E6E6; */
    .block{
      padding-top: unset;
      padding-bottom: unset;
      &.last{
        //border-bottom: unset;
        // border-bottom: 1px solid #E0E0E0;
        // border-right: 1px solid #E0E0E0;
      }
      &:hover{
        background-color: #f5f5f5;
        border-bottom: 1px solid #E0E0E0;
      }
    }
  }
  .dispContainer{
    height: 65vh;
    @media screen and (max-width: 767px){
      height: 276px;
    }
    @media screen and (max-width: 425px){height: 200px;}
    background-color: #FFFFFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border-top: 1px solid #E0E0E0; */
    /* border-left: 1px solid #E0E0E0; */
    /* border-right: 1px solid #E0E0E0; */
  }
}
/* Invite  */
.Sinvite{
  //min-height:315px;
  // width: 530px;
  background:#F4F4F4;
  border-radius: 20px;
  padding: 32px;
  border: 1px solid #F4F4F4;
  min-width: 350px;
  @media screen and (max-width: 576px){
    min-width: 300px;
    height: 100%;
    border-radius: unset;
  }
    .modalBlock{
      @media screen and (max-width: 576px){
        margin: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        left: 50%;
        width: 95%;
      }
      .inviteDoneBlock{
        width: 100%;
        margin-top: 50px;
        svg{
          width: 48px;
          height: 48px;
          margin-left: auto;
          margin-right: auto;
          display: block;
          margin-bottom: 15px;
          color: #27ae60;
        }
        .inviteDoneTxt{
          text-align: center;
          font-size: 20px;
          color: #27ae60;
        }
        .inviteDoneImg{
          width: 35px;
          margin-left: 47%;
          margin-bottom: 15px;
        }
        .LeftOvalAction{
          margin-top: 50px;
          float: unset;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
  .addOne{
    /* color:var(--fcnavyblue);
    text-decoration: underline;
    cursor: pointer;
    width: 220px; */
    font-size: 16px;
    margin:10px 20px 10px 20px;
  }
  .ActionButtons{width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    @media screen and (max-width: 425px){
      display: block;
      width: 94%;
    }
  }
  .LeftOvalAction{
    text-transform: none;
    margin:9px;
    outline:none;
    width:206px;
    // &.cancel {
    //   background: #E6E6E6;
    //   color: #0E0E0F !important;
    // }
    @media screen and (max-width: 425px){
      width: 100%;
      margin-top: unset;
    }
  }

  .error {
    text-align: center;
    font-size: 14pt;

    span {
      font-weight: bold;
    }
  }
}
.ModalInvite{
  top: 50% !important;
  position: fixed !important;
  left: 50% !important;
  transform: translate(-50%,-50%) !important;
  @media screen and (max-width: 576px){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: unset;
  }
  .modal-content{
    border: unset;
    border-radius: unset;
    background-color: unset;
    @media screen and (max-width: 576px){
      height: 100%;
    }
  }
}

/* Common project */
// .FamilySpace{
//   .SprojectCommon{  
//       flex:50%;/*padding-left: 15px;padding-right: 15px;*/
//       @include smartphone{padding-left: unset; padding-right: unset;}
//       &.full{
//         flex:100%;
//         max-width: unset;
//         height: calc(100vh - 80px);
//         overflow-y: auto;
//         margin-top: 20px;
//         .LeftOvalAction {
//           position: absolute;
//           top: 105px;
//           padding: 10px 50px;
//           @media screen and (max-width:576px){
//             top: 85px;
//             left: 25px;
//             padding: 10px 10px;
//           }
//         }
//         .prices {
//           .LeftOvalAction {
//             bottom: 10px;
//             left: 0;
//           }
//         } 
//       }
//       &.full::-webkit-scrollbar {
//         width: 5px;               /* width of the entire scrollbar */
//       }
//       &.full::-webkit-scrollbar-track {
//         background: #F0F0F0;        /* color of the tracking area */
//         border-radius: 10px;
//       }
//       &.full::-webkit-scrollbar-thumb {
//         background-color: #E0E0E0;    /* color of the scroll thumb */
//         border-radius: 10px;       /* roundness of the scroll thumb */
//       }
//     .title{font-size: 27px; /* font-weight: bold; padding-left:20px*/;color:$fc-deepgrey;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
//   }
// }


.SchangeName{
  width:530px;
  top:25%;
  z-index:10000;
  @include smartphone{width:unset;}
  .SsingleInput{
    width: 460px;
    @include smartphone{width: unset;}
  }
  .modal-content{background: #F4F4F4;}
  .actions{
    text-align: center;
    margin:10px 35px 22px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LeftOvalAction{display: inline-block;width:150px;padding: 10px;}
}
/**
* LOGIN
*/
.sfamilyspace-login {
  padding-top: 45px; @media screen and (max-width: 576px){padding-top: 5px;}
  .alert-danger,.alert-success{font-size:14px}
  .clientLogo {
    height: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-30)/var(--unnamed-line-spacing-35) var(--unnamed-font-family-cuprum);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-2a3032);
    text-align: center;
    // font: normal normal normal 30px/35px Cuprum;
    letter-spacing: 0px;
    color: $fc-grey;
    opacity: 1;
    margin: 50px auto 50px auto;
    @media screen and (max-width: 576px){
      margin: 0 auto 0 auto;
    }
  }
  .alert{
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: unset;
    left: unset;
    width: 422px;
    span{
      width: 100%;
      display: block;
      text-align: center;
    }
  }
  form {
    width: 422px;@include smartphone{width:300px;}
    margin: auto;
  }
  label {
    font-size: 16px;
    margin-top: 10px;
    text-align: left!important;
    font-weight: bold!important;
    letter-spacing: 0px!important;
    color: $fc-grey !important;
    opacity: 1;
  }
  input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E6F0;
    border-radius: 2px;
    opacity: 1;
    height: 40px;
    font-size: 14px;
  }
  .login {
    /*
    background: var(--unnamed-color-3568ba) 0% 0% no-repeat padding-box;
    background: var(--fcnavyblue) 0% 0% no-repeat padding-box;
    */
    border-radius: 50px;
    background: var(--f-main-color);
    color: var(--f-font-color);
    opacity: 1;
    margin-right: 5px;
  }

  .sfamilyspace-login-buttons {
    margin-top: 25px;
    margin-bottom: 10px;
    text-align: center;
    // display: flex;
    button {
      text-transform: capitalize;
      width: 100%;
      &:first-child {
        margin-bottom: 10px;
      }
      @media screen and (max-width: 567px){
        width: 100%;
        margin-bottom: 5px;
      }
      height: 40px;
      &.Grey{
        &:hover{
          background-color: #545454;
          color: #FFFFFF;
        }
      }
    }



    .cancel {
      background: var(--unnamed-color-e6e6e6) 0% 0% no-repeat padding-box;
      background: $fc-whitegrey 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      color: $fc-blackish;
      //margin-left: 5px;
    }
  }
  .renewPassword{display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    input{width:300px}
    button{margin-top:15px;width:300px}
    .WaitRenew{font-size: 12px;}}
    .sfamilyspace-forgot-password {
    margin-top: 10px;
      text-align: center;
  }

  .sfamilyspace-login-footer {
    margin-top: 25px;
    text-align: center;
  }

  .login-links,.lostpassword {
    font-size: 16px;
    color: var(--fcnavyblue);
    text-decoration: underline;
    cursor: pointer;
    text-align: left;
  }
  .invalid-feedback{
    font-size: 10pt;
    font-style: italic;
  }
}

  .familyspace-members {
    max-height: calc(100vh - 143px);
    overflow-y: hidden;
    overflow-x: hidden;
    @media screen and (max-width: 425px){
      width: 95%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .members-header {
      display: flex;
      align-items: center;
      padding: 15px 0px 15px 0px;
      @media screen and (max-width: 767px){
        justify-content: center;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 576px){
        padding-bottom: unset;
        button {
          margin: 5px 0;
          width: 100%;
        }
      }
      img{ width: 40px; height: 40px; border-radius: 50%;margin-left: 5px;}
      span {
        text-align: left;
        font: normal normal 24px/27px Cuprum;
        letter-spacing: 0px;
        color: #8C8E8E;
        opacity: 1;
        vertical-align: middle;
        margin-right: 60px;
      }

      .avatar {
        height: 50px;
        margin-left: 20px;
      }
      .membersButton{
        @media screen and (max-width: 576px){
          width: 100%;
          margin-left: unset;
        }
      }
      .remove {
        padding: 0 10px;
      }
    }
    .membersButton {
      height: 40px;
      //width: 250px;
      margin-left: 15px;
      background: $fc-whitegrey 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      border-color: $fc-whitegrey;
      color: $fc-blackish;
      text-align: center;
      font: normal normal normal 18px/21px Cuprum;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: unset;
    }
    .members-list::-webkit-scrollbar {
      width: 5px;                /* width of the entire scrollbar */
    }
    .members-list::-webkit-scrollbar-track {
      background: #F0F0F0;        /* color of the tracking area */
      border-radius: 10px;
    }
    .members-list::-webkit-scrollbar-thumb {
      background-color: #E0E0E0;    /* color of the scroll thumb */
      border-radius: 10px;       /* roundness of the scroll thumb */
    }
    .members-list {
      .table-members {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        //border: 1px solid $fc-whitegrey;
        border-radius: 10px;
        opacity: 1;
      }
      .members-block{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        .member-block{
          border-top: 1px solid #dee2e6;
          div {text-align: center}
        }
        .member-block:first-child{
          border-top: 0;
        }
      }
      .membersRow{
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row;
        height: 50px;
        @media screen and (max-width: 1009px){
          height: 110px;
          border-bottom: 1px solid #f9f9f9;
        }
        @media screen and (max-width: 900px){
          display: block;
          height: unset;
          padding-top: 10px;
        }
      }
      .icon_email_contain{
        width: 50%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 900px){
          width: 100%;
          justify-content: center;
        }
      }
      .icon {
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
      }
      .mail {
        text-align: left;
        // font: normal normal normal 18px/21px Cuprum;
        font-size: 18px;
        letter-spacing: -0.27px;
        color: var(--fcnavyblue);
        opacity: 1;
        @media screen and (max-width: 500px){
          font-size: 14px;
        }
      }
      .role {
        text-align: left;
        font: italic normal normal 18px/21px Cuprum;
        letter-spacing: -0.27px;
        color: $fc-lightgrey;
        opacity: 1;
        margin-left: auto;
        margin-right: auto;
        width: 10%;
        padding-left: 10px;
        @media screen and (max-width: 900px){
          width: 100%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        @media screen and (max-width: 500px){
          font-size: 16px;
        }
      }
      .options {
        text-align: right;
        margin-left: auto;
        margin-right: 5px;
        width: 65%;
        @media screen and (min-width: 901px) and (max-width: 1009px){
          width: 45%;
        }
        @media screen and (max-width: 900px){
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .membersButton{
          //width: 160px;
          margin: 5px;
          @media screen and (max-width: 500px){
            font-size: 14px;
          }
        }
      }
    }
    
    .btn-edit-info {
      padding-left: 10px;
      padding-right: 10px;
    }

    .invite {
      padding: 0 10px;
    }

    .members-actions {
      margin-top: 15px;
      text-align: center;
      @media screen and (max-width: 576px){
        margin-top: unset;
      }
      button {
        width: unset;
        text-align: center;
        font: normal normal bold 18px/21px Cuprum;
        letter-spacing: -0.27px;
        color: #F5F5FF;
        padding-left: 15px;
        padding-right: 15px;
        @media screen and (max-width: 576px){
          width: 100%;
          margin-left: unset;
        }
        svg {
          margin-right: 8px;
          vertical-align: bottom;
        }
      }
      .invite {
        background: var(--f-main-color) 0% 0% no-repeat padding-box;
        color: var(--f-font-color);
        /* &:hover {background-color: #234479} */
        border-radius: 50px;
        opacity: 1;
        float: left;
        margin-left: 0;
      }
      .remove {
        //background: #A04C4B 0% 0% no-repeat padding-box;
        background-color: #A04C4B;
        border-radius: 50px;
        opacity: 1;
        &:hover{
          //background-color: #c62828;
        }
      }
      .LeftOvalAction{
        &.Blue, &.Red{
          box-shadow:  0px 1px 5px 0px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 3px 1px -2px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

/* Really Common stuff especially for modals */
.Sclose{font-size: 15px;font-style: italic;color:$fc-lightgrey;position:absolute;right:10px;top:18px;cursor:pointer;
  i{font-weight: bold;vertical-align: middle;}}
.SBigTitle{
  font-size:28px;
  color:$fc-deepgrey;
  padding:54px 20px 20px 20px;
  text-align: center;
  span {
    display: block;
    color: var(--fcnavyblue);
    font-weight: bold;
    font-size: 20pt;
  }
}
.SBigTitleInvite{font-size:20px;color:$fc-deepgrey;margin:10px 20px 10px 20px;
  @media screen and (max-width: 576px){
    font-size: 28px;padding: 20px;text-align: center;
  }
}
.modalBlock .inputEmail {
  display: flex;
  .errorMsg{
    width: 100%;
  }
}
.delEmailButton{
  background: unset;
  border: unset;
}
.SsingleInput{width: 100%;height:40px;margin:10px 20px 10px 20px;padding: 6px;background: #FFFFFF;border-radius: 2px;border: 1px solid #E4E6F0;@media screen and (max-width: 576px){margin: 10px 0 10px 0;width: 100%;}
}
.errorMsg{color: red; font-size: 12px;text-align: left; width: 80%; margin-left: auto; margin-right: auto;margin-bottom: unset;}

.familyspace-addQuote-modal {
  width: 530px!important;
  margin-top: 50px!important;
  height: fit-content!important;
  margin: auto!important;
  padding-bottom: 15px!important;
  background: var(--unnamed-color-f4f4f4) 0% 0% no-repeat padding-box!important;
  background: #F4F4F4 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1!important;
  .error {
    width: 100%;
    text-align: center;
    color: red;
    font-size: 11pt;
    margin-top: 15px;
  }

  .error-input {
    border: 1px solid red;
  }

  .block {
    width: 422px;
    //margin: auto;
    //margin-top: 10px;
    margin: 10px auto;
    @media screen and (max-width: 576px){
      width: 280px;
    }
  }

  .block.files {
    @media screen and (max-width: 576px) {
      margin-bottom: 60px;
      margin-top: 0;
    }
    label {
      margin-top: 10px;
    }
    input {
      background-color: #F4F4F4;
      border: none;
    }
  }
  .fileImages{
    margin-top: 35px;
  }

  .block.actions {
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    .add-document {
      &:hover:not([disabled]) {
        background-color: var(--f-font-color);
        color: var(--f-main-color);
      }
    }
    @media screen and (max-width: 576px){
      height: unset;
    }
    button {
      outline: none;
      border-style: none;
      // width: 206px;
      width: 100%;
      text-transform: capitalize;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 576px){
        width: 100%;
        margin-bottom: 10px;
      }
      height: 40px;
    }
    .add {
      /*background: var(--fcnavyblue) 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      font: normal normal normal 18px/21px Cuprum;
      letter-spacing: 0px;
      color: #FFFFFF;*/
    }
    .cancel {
      /*background: #E6E6E6 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      color: #0E0E0F;
      float: right;
      font: normal normal normal 18px/21px Cuprum;
      letter-spacing: 0px
      background: unset;
      color: var(--fcnavyblue) !important;
      background: #E6E6E6;
      color: #0E0E0F !important;
      */
      &:hover{
        /* background-color: rgba(53, 104, 186, 0.08) */
      }
    }
  }

  input {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E6F0;
    border-radius: 2px;
    opacity: 1;
    outline: none;
  }

  .title {
    margin-top: 35px;
    margin-bottom: 20px;
    text-align: center;
    // font: normal normal normal 22px/30px Cuprum;
    letter-spacing: 0px;
    color: #2A3032;
    opacity: 1;
    font-size: 28px;
    @media screen and (max-width: 576px){
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }

  label {
    text-align: left;
    // font: normal normal bold 16px/18px Cuprum;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    color: #3B4752;
    opacity: 1;
  }

  .preview {

    .previewImg {
      width: 25%;
      max-height: 105px;
    }
  }
  .MuiCircularProgress-root {
    margin-right: 83px;
    margin-left: 83px;
  }
}

.familyspace-addQuote-modal.family-space-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin: unset;
  right: unset;
  bottom: unset;
  @media screen and (max-width: 576px){
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100% !important;
    width: 100% !important;
    border-radius: unset !important;
    margin: unset !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .modalBlock{
    @media screen and (max-width: 576px){
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      left: 50%;
      width: 95%;
    }
    .img-input-block{
      @media screen and (max-width: 576px){
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .image {
    input {
      background-color: #F4F4F4;
      border: none;
    }

    img {
      height: 30px;
      width: 30px;
    }

    svg {
      font-size: 36px;
      margin-right: 10px;
      padding-top: 2px;
    }

    .file-type {
      width: 90%;
      float: right;
    }

    .error {
      width: 100%;
      text-align: center;
      color: red;
      font-size: 14pt;
      margin-top: 15px;
    }
  }
}

.react-confirm-alert-overlay {
  background: #2a30327a 0% 0% no-repeat padding-box;
}

.react-confirm-alert {
  .confirm-alert-arhive-modal {
    width: 530px;
    padding: 25px;
    background-color: #F4F4F4;
    border-radius: 20px;
    text-align: center;
    @media screen and (max-width: 576px){
      width: 100%;
      height: 100%;
      border-radius: unset;
    }
    .confirm-alert-block{
      @media screen and (max-width: 576px){
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        left: 50%;
        width: 95%;
      }
      .actions{
        display: flex;
        button{
          @media screen and (max-width: 576px){
            width: 100%;
          }
          &:first-child {
            flex-grow: 1;
          }
        }
      }
    }
    .close-action {
      text-align: right;
      cursor: pointer;

      svg {
        font-size: 16px;
        vertical-align: bottom;
        margin-left: 5px;
        color: #8C8E8E;
        stroke: #8C8E8E;
        stroke-width: 2px;
      }

      span {
        color: #8C8E8E;
        font: italic normal normal 15px/17px Cuprum;
        letter-spacing: 0px;
      }
    }

    h2 {
      margin-top: 10px;
      // font: normal normal normal 30px/35px Cuprum;
      letter-spacing: 0px;
      color: #2A3032;
      opacity: 1;
      margin-bottom: 10px;
      @media screen and (max-width: 576px){
        font-size: 28px;
      }
    }

    .message {
      text-align: left;
      // font: normal normal 16px/18px Cuprum;
      letter-spacing: 0px;
      color: #8C8E8E;
      margin-bottom: 15px;
      font-size: 14px;
      span {
        font-weight: bolder;
      }
    }

    .actions {
      button {
        /* 
        margin-top: 10px;
        width: 150px;
        font-size: 18px;
        border-radius: 2px;
        border-radius: 50px;
        text-transform: unset;
        outline: none;
        border-style: none;
        */
        width: 150px;
        padding: 10px;
        margin: 0 10px;
        /* &.validate {
          background-color: var(--f-main-color) !important;
          color: var(--f-font-color);
          margin-right:16px
        }
        &.cancel {
          background: #E6E6E6;
          color: #0E0E0F;
        } */
      }
    }
  }
}
.errorMessage{
  width:300px;height:200px;margin-left: auto!important;margin-right: auto!important;margin-top:50px!important;
  text-align: center;
  p{margin-top:30%;font-size: 16px;color:red!important;}
}

.ModalAskQuote{
  margin-top: 35vh;
  margin-left: 36vw;
  @media screen and (max-width: 576px){
    margin-top: 0;
    width: 100%;
    margin-left: unset;

  }
  .modal-content{border-radius: 10px; width:525px;
    @media screen and (max-width: 576px){
      width: 100%;
    }
    background:$fc-ivory;padding: 0px 23px 30px 23px;text-align:center;
    .SBigModalTitle{font-size:28px;color:$fc-deepgrey;padding-top:54px; @media screen and (max-width: 576px){padding-top: 35%;}}
    .SModalText{font-size:17px;color:$fc-darkgrey;padding: 20px 0px 20px 0px}
    .ActionButtons{margin-left:auto;margin-right:auto;
      .LeftOvalAction{
        margin-bottom:10px;
        text-transform: none;
        padding: 10px;
        @media screen and (max-width: 576px){
          float: unset;
        }
        &.Grey{
          padding:0 50px 0 50px;
        }
      }}
  @media screen and (max-width: 576px){
    width:100%;
    height: 100vh;
    border-radius: unset;
  }
}}
.iFrameDoc-modal{border: 0; margin-top: 45px; padding: 0}
.ReactModal__Overlay.iFrameDoc-overlay-error {color: red;height: 100px;width: 400px;font-size: 16px;left:50%;top:50%;
  .Error{padding:20px}
  div.CloseModal{font-size:14px}}


.Smenu-nav{
  padding-left: 7px;
  font-size: 30px;
  color: #FFFFFF;
  margin-bottom: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: left;
  .Smenu-nav-burger{
    font-size: 40px;
    margin-right: 3px;
    color: var(--f-font-color);
  }
  .Smenu-nav-text{
    font-weight: 100;
    font-size: 35px;
    margin-bottom: unset;
    color: var(--f-font-color);
  }
}
/*.Sactivity-mobile{
  right: 50%;
  background: red;
  letf: 50%;
  bottom: 0;
  position: fixed;
  z-index: 100;
}*/

.FamilySpace {
  .collapsed-menu{
    width: 100%;
    background: var(--f-main-color);
    height: calc(100vh - 46px);
    position: absolute;
    z-index:10000;
    left: -100vw;
    transition: all 1s ease;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 26px;
    padding-bottom: 46px;
    top: 90px;
    @media screen and (max-width: 500px){
      margin-top: -1px;
    }
    div{
      border-top-style: unset !important;
    }
    .actionMenu.active{
      font-size: 29px;
      background-color: rgba(0,0,0,0.24);
    }
    .actionMenu{
      width: 100%;
      border-bottom: 1px solid #f0f0f052;
      justify-content: center;
      height: 65px;
      &:first-child{
        border-top-right-radius: 10px;
      }
      &:last-child{
        border-bottom-right-radius: 10px;
      }
    }
    img{
      height: 25px;
    }
  }
  .move{
    left: 0 !important;
  }
}
.Sadd-mobile{
  width: 180px !important;
  top: -45px;
  left: -428px;
}
.Sm{
  margin-top: 16px;
  margin-bottom: 10px;
}
.Smodal-pos{
  right: 81px !important;
  left: unset !important;
  top: 54px !important;
}

.logoContainer{
  height: 70px;
  margin-top: 5px;
  @media screen and (max-width: 768px){
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .clientLogo{
    height: 50px;
    margin-top: 10px;
  }
}

/* Google Recommended box-shadows values classes : */
.md-whiteframe-1dp {
  box-shadow:  0px 1px 3px 0px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
.md-whiteframe-2dp {
  box-shadow:  0px 1px 5px 0px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 3px 1px -2px rgba(0, 0, 0, 0.12); }
.md-whiteframe-3dp {
  box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2),
  0px 3px 4px 0px rgba(0, 0, 0, 0.14),
  0px 3px 3px -2px rgba(0, 0, 0, 0.12); }
.md-whiteframe-4dp {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  0px 4px 5px 0px rgba(0, 0, 0, 0.14),
  0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 5px 8px 0px rgba(0, 0, 0, 0.14),
  0px 1px 14px 0px rgba(0, 0, 0, 0.12); }
.md-whiteframe-6dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14),
  0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
.md-whiteframe-7dp {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
  0px 7px 10px 1px rgba(0, 0, 0, 0.14),
  0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
.md-whiteframe-8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important; }
.md-whiteframe-9dp {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
  0px 9px 12px 1px rgba(0, 0, 0, 0.14),
  0px 3px 16px 2px rgba(0, 0, 0, 0.12); }
.md-whiteframe-10dp {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
  0px 10px 14px 1px rgba(0, 0, 0, 0.14),
  0px 4px 18px 3px rgba(0, 0, 0, 0.12); }
.md-whiteframe-11dp {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
  0px 11px 15px 1px rgba(0, 0, 0, 0.14),
  0px 4px 20px 3px rgba(0, 0, 0, 0.12); }
.md-whiteframe-12dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
  0px 12px 17px 2px rgba(0, 0, 0, 0.14),
  0px 5px 22px 4px rgba(0, 0, 0, 0.12); }
.md-whiteframe-13dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
  0px 13px 19px 2px rgba(0, 0, 0, 0.14),
  0px 5px 24px 4px rgba(0, 0, 0, 0.12); }
.md-whiteframe-14dp {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
  0px 14px 21px 2px rgba(0, 0, 0, 0.14),
  0px 5px 26px 4px rgba(0, 0, 0, 0.12); }
.md-whiteframe-15dp {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
  0px 15px 22px 2px rgba(0, 0, 0, 0.14),
  0px 6px 28px 5px rgba(0, 0, 0, 0.12); }
.md-whiteframe-16dp {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
  0px 16px 24px 2px rgba(0, 0, 0, 0.14),
  0px 6px 30px 5px rgba(0, 0, 0, 0.12); }
.md-whiteframe-17dp {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
  0px 17px 26px 2px rgba(0, 0, 0, 0.14),
  0px 6px 32px 5px rgba(0, 0, 0, 0.12); }
.md-whiteframe-18dp {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
  0px 18px 28px 2px rgba(0, 0, 0, 0.14),
  0px 7px 34px 6px rgba(0, 0, 0, 0.12); }
.md-whiteframe-19dp {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
  0px 19px 29px 2px rgba(0, 0, 0, 0.14),
  0px 7px 36px 6px rgba(0, 0, 0, 0.12); }
.md-whiteframe-20dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
  0px 20px 31px 3px rgba(0, 0, 0, 0.14),
  0px 8px 38px 7px rgba(0, 0, 0, 0.12); }
.md-whiteframe-21dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
  0px 21px 33px 3px rgba(0, 0, 0, 0.14),
  0px 8px 40px 7px rgba(0, 0, 0, 0.12); }
.md-whiteframe-22dp {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
  0px 22px 35px 3px rgba(0, 0, 0, 0.14),
  0px 8px 42px 7px rgba(0, 0, 0, 0.12); }
.md-whiteframe-23dp {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
  0px 23px 36px 3px rgba(0, 0, 0, 0.14),
  0px 9px 44px 8px rgba(0, 0, 0, 0.12); }
.md-whiteframe-24dp {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
  0px 24px 38px 3px rgba(0, 0, 0, 0.14),
  0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.secondaryBtn{
  background-color: unset !important;
  &.textBtn{
    /* color: var(--fcnavyblue) !important; */
    color: #667481;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  &.outlinedBtn{
    /* color: var(--fcnavyblue) !important; */
    /* border: 1px solid var(--fcnavyblue) !important; */
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  &:hover{
    /* background-color: rgba(53,104,186,0.08) !important; */
    /* color: var(--fcnavyblue) !important; */
    color: #0e0e0f
  }
}
//**  Family Config Styling **//
.fTopBarContainer{
  .backArrowContainer{
    cursor: pointer;
    svg{
      &:hover{
        fill:  white;
      }
    }
  }
}
//////////////////////////////
//** Family Config CSS Loader Style: **//
.loaderContainer{
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .sk-cube-grid{
    margin-top: 20px;
  }
}
.sk-cube-grid {
  //width: 24px;
  //height: 24px;
  //margin: auto auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: var(--fcdarkstlblue);
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}
////////////////////////////////////////////////////////////////////////// [ESPACE FAMILLE - refonte UI V2] ////////////////////////////////////////////////////////////////////////////
/*
.fsp-button {
  background-color: var(--f-main-color);
  color: var(--f-font-color);
  cursor: pointer;
  border: 1px solid #4D616B;
  border-radius: 50px;
  margin-bottom: 0px;
  span {
    color: var(--f-font-color);
  }
  .svgContainer {
    span {
      padding-left: 3px;
    }
  }
  &.grey {
    background: #6A7782 !important;
    color: #fff;
  }
  &.outline {
    background: #fff !important;
  }
  &.lg {
    height: 50px;
    padding: 8px 15px;
    font-size: 30px;
    line-height: 24px;
    margin: 0 5px;
    font-weight: unset;
    span {
      font-weight: unset;
      font-size: 16px;
    }
  }
}
*/

.fsp-icon {
  fill: #6A7782 !important;
  &.sm {
    height: 30px;
    width: 30px;
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
  }
}

.flex-no-grow {
  flex-grow: 0;
  flex-basis: unset;
}

// Header
/* .navbar {
  position: relative;
  padding: 0;
  .navbar-container {
    background-color: #EAEEF2;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    font-family: "Raleway", cuprum, sans-serif;
    .FamilyInfo {
      color: #6A7782;
      flex-grow: 1;
      height: 60px;
      position: relative;
      top: -5px;
      .familyInfoDetails {
        .familyName {
          font-size: 28px;
          padding-bottom: 5px;
          font-weight: 600;
        }
        .familyEmail {
          font-size: 18px;
        } 
        @media screen and (max-width: 576px){
          color: #fff;
          height: 90px;
        }
      }
    }
  }
} */

// Buttons
  /*
.infoBtn {
  margin-right: 8px;
  color: #6A7782;
  cursor: pointer;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #4D616B;
  border-radius: 50px;
  font-size: 30px;
  font-weight: 500;
  height: 50px;
  p {
    margin: 0;
    svg {
      width: 24px;
      height: 24px;
      padding: 2px 3px;
    }
  }
  &.sm {
    font-size: 22px;
  }
}
*/

.container-center {
  text-align: center;
  .top-page-items-container {
    // width: 800px;
    // background-color: #5e3030;
    margin: 0px auto 50px;
    padding: 10px;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    .Ssearch, .load-project-wrapper {
      width: 30%;
      margin: 0 20px;
    }
    .Toppest {
      margin: 0 50px;
    }
    @media screen and (max-width: 992px){
      flex-direction: column;
      margin: 0px auto;
      .load-project-wrapper, .Ssearch, .SsearchActions {
        width: 100% !important;
        margin: 5px 0 !important;

      }
    }
    // @media screen and (max-width: 320px){
    //   span {color:green}
    // }
  }
}

.familyspace-members {
  margin-top: 100px;
  div:first-child {
    font-size: 20px;
    color: $fc-grey;
    
  }
  .members-header{
    padding-top: 2px;
    h3 {
      font-size: 30px;
      color: $fc-grey;
      margin-right: 30px;
    }
  }
  p {
    color: $fc-grey;
    font-size: 20px;
  }
}

.FamilySpaceBody {
  margin-top: 20px;
  .row .col {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.MuiSvgIcon-root {
  color: var(--f-main-color);
}

.FormInvite {
  position: absolute;
  top: 65px;
  width: 400px;
  background: rgb(255, 255, 255);
  right: 350px;
  z-index: 99999;
  border-radius: 8px !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  .closePanel {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    padding: 5px;
  }
  .errorMsg {
    width: unset;
  }
  .form-invite-container {
    padding: 0 20px;
    .SBigTitleInvite {
      font-size: 20px;
      color: $fc-grey;
      margin: 10px 0 20px 0;
    }
    .label-email {
      color: $fc-grey;
      font-size: 16px;
    }
    .addOne {
      height: 30px;
      line-height: 10px;
      width: 100%;
      padding: 0 5px;
      border-radius: 20px;
      /* font-size: 14px;
      color: var(--fcdarkstlblue);
      border: 1px solid var(--fcdarkstlblue); */
    }
    .ActionButtons {
      button {
        margin: 10px 0 40px;
        width: 100%;
        background-color: var(--f-main-color);
        color: var(--f-font-color);
      }
    }
    .inputEmail {
      .SsingleInput {
        width: 100%;
        height: 30px;
        padding: 6px;
        font-size: 14px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #E4E6F0;
        background: #F5F6FC 0% 0% no-repeat padding-box;
        margin: 5px 0;
      }
    }
  }
}
