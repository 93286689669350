.familyspacePot {
  .PotIcon img{width:170px; margin:10px;cursor: pointer;}
  span.ErrorPot {color: red;margin-left: 5px;}
  .commonButton{margin-left:15px;color: #F5F5FF;background-color: var(--fcnavyblue);
    height: 40px;padding:5px 15px 5px 15px;border-radius: 50px;cursor: pointer;}
  .title{font-size: 24px;font-weight: bold;padding-left:20px;color:$fc-deepgrey;
         margin-top:19px;margin-bottom: 14px; }
  .potBody{background: #FFFFFF;border-radius: 10px;border: 1px solid #E6E6E6;
          padding: 16px 20px 16px 22px ; line-height: 27px;
          font-size:18px;color:$fc-deepgrey;
    hr{margin:10px -22px 10px -20px}
    .proposePotBody{color:$fc-lightgrey}
    .potInput {
      input {height: 40px;margin-top:8px;font-size: 14px;width:300px}
      button {
        margin-left: unset!important;
        min-width: 180px;
        margin-top: 15px!important;
        vertical-align: middle;
        font: normal normal bold 18px/21px Cuprum;
        letter-spacing: -0.27px;
        color: #F5F5FF;
        line-height: 18px;

        &.Blue {
          padding-left: unset!important;
        }

        &.Red {
          background-color: #A04C4B;
        }
        &.Grey {
          background-color: #E6E6E6;
          color: #0E0E0F;
        }
        img {
          margin-left: unset!important;
          margin-right: 17px!important;
        }
        svg {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
    .accessPot{@extend .commonButton;width: fit-content;margin-top:15px;margin-left:0px;
        img{padding-right:15px}}

    .actions {
      button {
        margin-left: unset!important;
        margin-top: 15px!important;
        padding-right: 15px!important;
        vertical-align: middle;
      }
    }
  }
}
