.Family {
  .pattern {
    .Error{
      @media screen and (max-width: 576px){
        left: 0;
        text-align: center;
        padding-left: 5px;
        font-size: 16pt;
        padding-right: 5px;
      }
    }
    .PatternEngravingZone {
      @media screen and (max-width: 768px) {
        position: absolute;
        top: 71px;
        // overflow: scroll;
        // height: 100vh;
        background-color: white;
      }
    }
    .CanvasContainer {
      margin-left: 25vw !important;
      width: 100% !important;
      @media screen and (max-width: 768px) {
        margin-left: 0 !important;
      }
    }
    .addBtn {
      font-family: $sukhumvitFont;
      font-weight: bold;
      background-color: white;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      margin-bottom: 5px;
      padding: 10px 24px 10px 16px;
      border: 1px solid var(--fcdarkstlblue);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 241px;
      height: 45px;
      cursor: pointer;
      @media screen and (max-width: 480px){
        position: fixed;
      }
      .StepButton {
        font-family: $sukhumvitFont;
        background: unset;
        color: var(--fcdarkstlblue);
        margin-right: 5px;
        width: unset;
        height: unset;
        padding: unset;
        i {
          width: unset;
          height: unset;
          margin: unset;
        }
      }
    }
  }
}
.TopFpattern {
  z-index: 5;
  padding-top: 0;
  padding-left: 10px;
  position: absolute;
  top: 0;
  overflow: auto;
  height: 90vh;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  width: 38vw;
  padding-top: 20px;
  min-width: 320px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 8px;
  top: 90px;
  left : 24px;
  Height: 80%;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    position: relative;
    padding-top: 0;
    padding-left: 4px;
    z-index: 8000;
    width: 100%;
    top: 70px;
    left: 0;
  }
  .closeIcon {
    float: right;
    background-color: unset;
    border: unset;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      display: none;
    }
    &:hover {
      svg {
        path {
          fill: var(--fcorange);
        }
      }
    }
  }
  .button-actions-wrapper {
    position: fixed;
    z-index: 20;
    bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 470px;
    display: grid;
    background: rgba(250, 250, 250, 0.5);
    margin-left: -5px;

    @include smartphone {
      width: 100%;
    }

    button {
      width: 206px;
      height: 40px;
      margin: auto;
      font-family: Cuprum;
      font-size: 18px;
      margin-bottom: 10px;
      border-radius: 50px;
      background: var(--fcblue) 0% 0% no-repeat padding-box; //CHANGED #0095E8
      border-style: none;
    }
  }

  .FpatternParams {
    width: 100%;
    overflow-x: hidden;
    &.FpatternParamsB{
      .FpatternContainer{
        margin-left: 10px;
      }
    }
    .FpatterParams-title-b {
      font-family: $RalewayFont;
      font-weight: bold;
      text-align: center;
      padding-left: 12px;
    }
    .ScrollContainer {
      margin-left: 0;
    }
    .icon {
      float: right;
      color: $fc-lightgrey;
      cursor: pointer;
      font-weight: bold;
    }
    .title {
      font-size: 24px;
      color: $fc-darkgrey;
      @include smartphone {
        font-size: 16px;
      }
    }
    .subTitle {
      font-size: 14px;
      color: $fc-darkgrey;
      margin-bottom: 19px;
      @include smartphone {
        font-size: 14px;
      }
    }
    .FpatternInput {
      margin-right: 20px;
    }
    input {
      width: 100%;
      font-size: 16px;
      color: $fc-lightgrey;
      font-family: $sukhumvitFont;
      @include smartphone {
        font-size: 14px;
      }
    }
    form {
      .icon {
        position: absolute;
        margin-left: -30px;
        margin-top: 5px;
        color: $fc-lightgrey;
      }
    }
    .Keywords button {
      font-family: $sukhumvitFont;
      height: 30px;
      margin-right: 5px;
      margin-top: 10px;
      line-height: 10px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid var(--fcdarkstlblue);
      color: var(--fcdarkstlblue);
      border-radius: 0;
    }
    .imagePatterns {
      display: flex;
      margin-top: 15px;
      flex-wrap: wrap;
      padding-bottom: 120px;
      justify-content: space-around;
    }
    .FpatternCard {
      height: 214px;
      width: 45%;
      margin-right: 15px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000001a;
      border-radius: 6px;
      margin-bottom: 15px;
      text-align: center;
      &:hover {
        border: 1px solid var(--fcblue);
        box-shadow: none;
      }
    }
    .FpatternImage {
      padding-top: 5px;
      max-height: 203px;
      cursor: pointer;
      &.loading {
        background: $color-white url('../../images/loader.gif') no-repeat center
          center;
      }
    }
    .Carousel {
      height: 440px;
      width: 421px;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: auto;
    margin-right: auto;
      @include smartphone {
        width: 320px;
      }
      .Fcarousel {
        text-align: center;
        height: 100%;
        background: #707070;
        .FPatternCarousel {
          height: 100%;
        }
        .helper {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
        img {
          max-height: 420px;
          max-width: 350px;
        }
      }
    }
    .Carousel .Next,
    .Carousel .Previous {
      @include NextPrevious();
      margin-left: 0px;
      margin-right: 0px;
      top: unset;
      margin-top: 180px;
    }
    .SelectPattern {
      position: absolute;
      top: 50%;
      left: 130px;
      background-color: var(--f-main-color);
      padding: 10px;
      color: var(--f-font-color);
      font-size: 14pt;
      border-radius: 30px;
      cursor: pointer;
      &:hover{
        background-color: var(--f-font-color);
        color: var(--f-main-color);
      }
    }
    .similarPattern {
      font-size: 14px;
      color: $fc-lightgrey;
      font-style: italic;
    }
  }

  .Plus {
    color: $fc-medgrey;
    font-size: 16px;
    font-weight: bold;
    margin-top: 7px;
    border: 1px solid $fc-medgrey;
    @include smartphone {
      height: 46px;
      width: 100%;
      position: fixed;
      left: 0;
      top: 135px;
      background-color: #f4f4f4;
      border: none;
    }
    .StepButton {
      font-family: $sukhumvitFont;
      margin-left: 10px;
      float: left;
      margin-top: 5px;
      @include smartphone {
        display: none;
      }
    }
    .icon {
      font-size: 22px;
      color: #ffff;
    }
    span {
      @include smartphone {
        position: absolute;
        left: unset;
        top: 15px;
        right: 10px;
        cursor: pointer;
        border: 1px solid $fc-medgrey;
        border-radius: 22px;
        line-height: 20px;
        width: 130px;
        background-color: white;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }
  
  &::-webkit-scrollbar-track {
    background: #F3F3F3; /* color of the tracking area */
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #8C8E8E; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #F3F3F3; /* creates padding around scroll thumb */
  }
}
