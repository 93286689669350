/**
 * 	Base mixins & selectors
 */

/* RESET */
%reset {
  background: transparent none;
  border: 0;
  @include border-radius(0);
  list-style: none;
  margin: 0;
  padding: 0;
}

%list-reset {
  @extend %reset;
  li, dt, dd {
    @extend %reset;
  }
}

/* VISIBILITY */
%text-hidden {
  color: transparent;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  text-align: left;
  text-indent: -9999px;
}

%hidden {
  bottom: auto;
  font-size: 0;
  left: -9999px;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: auto;
  top: -9999px;
}

%visible {
  bottom: auto;
  clip: auto;
  font-size: inherit;
  height: auto;
  left: auto;
  line-height: inherit;
  margin: auto;
  overflow: auto;
  padding: 0;
  position: static;
  right: auto;
  top: auto;
  width: auto;
}

/* TEXT */
%text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/* IMAGES */
%responsive-image {
  height: auto;
  max-width: 100%;
}

%image-only {
  display: inline-block;
  line-height: 0;
}

@mixin background-image-with-ratio($image-url, $ratio: 4) {
  background-image: image-url($image-url);
  background-size: image-width($image-url)/$ratio image-height($image-url)/$ratio;
}

/* ALIGNMENTS */
%vertically-centered-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    font-size: 0;
    vertical-align: middle;
  }
}

%vertically-centered-block {
  display: inline-block;
  vertical-align: middle;
}

@mixin align($orientation: left) {
  display: inline;
  float: $orientation;
  text-align: $orientation;
  width: auto;
}

/* COLUMNS / GRID */
%columns {
  overflow: hidden;
  width: 100%;
}

%column {
  display: inline-block;
}

@mixin columns($width: 100%) {
  overflow: hidden;
  @if ($width != none) {
    width: $width;
  }
}

@mixin column($position: left) {
  @if $position == left {
    display: inline;
    float: left;
  }
  @if $position == right {
    display: inline;
    float: right;
  }
  @if $position == none {
    display: block;
    float: none;
  }
}

@function column-width($colCount: 1, $colsTotal: 12, $width: 100%) {
  @return $width*$colCount/$colsTotal;
}


@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* RETINA */
$xhdpi-ratio: 4;
$hdpi-ratio: 3;
$mdpi-ratio: 2;
$ldpi-ratio: 1.25;
@mixin xhdpi {
  @media (-webkit-min-device-pixel-ratio: $hdpi-ratio), (min-resolution: #{96*$hdpi-ratio}dpi) {
    @content;
  }
}

@mixin hdpi {
  @media (-webkit-min-device-pixel-ratio: $mdpi-ratio), (min-resolution: #{96*$mdpi-ratio}dpi) {
    @content;
  }
}

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: $ldpi-ratio), (min-resolution: #{96*$ldpi-ratio}dpi) {
    @content;
  }
}

@mixin mdpi {
  @media (-webkit-min-device-pixel-ratio: $ldpi-ratio), (min-resolution: #{96*$ldpi-ratio}dpi) {
    @content;
  }
}

@mixin ldpi { // == default screen resolution
  @content;
}

@mixin tablet {
  @media only screen and (max-width: 960px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media only screen and (max-width: 720px) {
    @content;
  }
}

@mixin smartphone {
  @media only screen and (max-width: 490px) {
    @content;
  }
}

@mixin smartphone-small {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

@mixin background-image-xhdpi($image-url) {
  background-image: image-url($image-url);
  background-size: image-width($image-url)/$xhdpi-ratio image-height($image-url)/$xhdpi-ratio;
}

@mixin background-image-hdpi($image-url) {
  background-image: image-url($image-url);
  background-size: image-width($image-url)/$hdpi-ratio image-height($image-url)/$hdpi-ratio;
}

@mixin background-image-mdpi($image-url) {
  background-image: image-url($image-url);
  background-size: image-width($image-url)/$mdpi-ratio image-height($image-url)/$mdpi-ratio;
}

@mixin background-image-ldpi($image-url) {
  background-image: image-url($image-url);
  background-size: image-width($image-url) image-height($image-url);
}

@mixin fsTitle{font-size: 24px;font-weight: bold;padding-left:20px;color:$fc-deepgrey}