.Family .Fabric {
  right: 0;
  height: 100%;
  width: 100%;
  left: 0;
  @media screen and (max-width: 490px){
    height: 65vh;
    left: 0;
    position: relative;
  }
}
.Family {
  .accessoryPlus {
    padding: 10px 14px 10px 10px;
    width: 200px;
    background-color: white;
    border-radius: 100px;
    border: 1px solid var(--fcdarkstlblue);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 16px;
    cursor: pointer;
    @media screen and (max-width: 320px){
      left: 30%;
      transform: translate(-22%,0);
    }
    @media screen and (max-width: 480px){
      bottom: 0;
      position: fixed;
    }
    &:hover {
      background-color: var(--fcdarkstlblue);
      color: white;
    }
    .StepButton {
      background-color: unset;
      color: unset;
      padding: unset;
      margin-right: 5px;
      font-family: $sukhumvitFont;
    }
    .StepLabel{
      text-align: center;
    }
  }

  .AccessoriesZone {
    background: $color-white;
    bottom: 0;
    position: absolute;
    right: 0;
    top: 70px;
    padding-bottom: 0;
    
    @media screen and (max-width: 768px) {
      position: absolute;
      top: 71px;
      height: 89.5%;
      overflow: scroll;
      width: 100%;
      left: 0; 
    }
    .Fabric{
      .CanvasContainer{
        .canvas-container{
          margin-left: 30%;
          @media screen and (max-width: 768px){
            margin-left: unset;
          }
        }
      }
      /* @media screen and (max-width: 480px){
        height:120%;
      } */
    }
    
  }
  .NoAccessory {
    position: absolute;
    margin-top: 100px;
    margin-left: 20px;
    font-size: 24px;
  }
}
.accessory-actions {
  position: fixed;
  bottom: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 590px;
  display: grid;
  background: rgba(250, 250, 250, 0.5);
  margin-left: -5px;

  @include smartphone {
    width: 100%;
  }

  button {
    width: 206px;
    height: 40px;
    margin: auto;
    font-family: Cuprum;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 50px;
    background: var(--fcblue) 0% 0% no-repeat padding-box; //CHANGED #0095E8
    border-style: none;
  }
}
.TopFaccessory {
  @media screen and (max-width: 500px) {height: 100% !important;}
  .ScrollListView{
    margin-top: 20px;
  }
  &.accessoriesA {
    margin-top: 35px;
    .FaccessoryParams {
      .types {
        height: auto;
        padding-bottom: 12px;

        button {
          background-color: $color-white;
          color: var(--fcdarkstlblue);
          border-radius: 10px;
          border: 1px solid var(--fcdarkstlblue);
          &:hover {
            background-color: var(--fcdarkstlblue);
            color: $color-white;
          }
        }
      }
    }
  }
  z-index: 5;
  padding: 22px 10px 0 22px;
  position: absolute;
  overflow: auto;
  height: 92.5vh;
  background: #f3f3f3 0 0 no-repeat padding-box;
  box-shadow: 0 0 10px #00000029;
  @media screen and (max-width: 768px) {
    position: relative;
    padding: 0;
    z-index: unset;
    top: 70px;
    width: 100%;
    min-width: unset;
  }
  width: 35%;
  min-width: 400px;
  .FaccessoryParams {
    width: 100%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .icon {
      float: right;
      color: $fc-lightgrey;
      cursor: pointer;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        margin-right: 5px;
      }
    }
    .title {
      font-family: $RalewayFont;
      font-size: 16pt;
      color: $fc-darkgrey;
      text-align: center;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .types {
      text-align: center;
      button {
        height: 30px;
        margin-right: 5px;
        margin-top: 10px;
        line-height: 10px;
        background: white;
        border: 1px solid var(--fcdarkstlblue);
        border-radius: 0;
        font-family: $sukhumvitFont;
      }
      .selected {
        background: var(--fcdarkstlblue);
        color: white;
      }
    }
    .ScrollListView {
      top: 90px;
      padding-bottom: 120px;
      padding: 0;
      @include smartphone {
        top: unset;
      }
      .ListView {
        margin: 0;
      }
    }
    .ListViewItem {
      width: 50%;
      @include smartphone {
        width: 100%;
      }
    }
    .AccessoryCard {
      .Infos{
        height: 2.5rem;
        padding: 0.625rem;
        margin-bottom: 0.75rem;
        h1{
          font-family: $RalewayFont;
        }
        p{
          font-family: $sukhumvitFont;
        }
        .Description{
          width: 80%;
        }
        .LazyImageB{
          .lazy-loader-image{
            margin-top: 58px;
          }
        }
      }
      figure > img {
        @include smartphone {
          margin-top: unset;
        }
      }
      .Description {
        text-transform: none;
        color: $fc-lightgrey;
        font-size: 16px;
        float: left;
      }
      .Reference {
        float: right;
        color: var(--fcdarkstlblue);
      }
      .Infos {
        width: 100%;
      }
      &.selected {
        margin: -1px;
        border: 1px solid var(--fcdarkstlblue);
      }
      &:hover {
        /* border: 1px solid var(--fcorange); */
        // border: 1px solid var(--f-main-color);
        box-shadow: none;
      }
    }
  }
  .StepAction.Plus {
    @media screen and (max-width: 768px) {
      height: 46px;
      width: 100%;
      position: fixed;
      left: 0;
      top: 70px;
      border: none;
    }
  }
  .Plus {
    color: $fc-medgrey;
    font-size: 16px;
    font-weight: bold;
    margin-top: 7px;
    border: 1px solid $fc-medgrey;
    .icon {
      font-size: 22px;
      color: #ffff;
    }
    span {
      @include smartphone {
        position: fixed;
        left: unset;
        top: 154px;
        right: 5px;
        cursor: pointer;
        border: 1px solid $fc-medgrey;
        border-radius: 22px;
        line-height: 20px;
        width: 160px;
        background-color: white;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  .StepAction {
    width: 200px;
  }
  .StepButton {
    font-family: $sukhumvitFont;
    margin-left: 10px;
    float: left;
    margin-top: 5px;
    border: none;
    @include smartphone {
      display: none;
    }
  }

  .accessoryClose {
    .StepButton {
      font-family: $sukhumvitFont;
      background-color: unset;
      color: unset;
      padding: unset;
      margin: unset;
      float: right;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      cursor: pointer;
      i {
        width: 30px;
        margin-left: auto;
        margin-right: auto;
        height: 30px;
        color: var(--fcdarkstlblue);
        &:hover {
          color: var(--fcorange);
        }
      }
    }
    .StepLabel {
      font-family: $sukhumvitFont;
      display: none;
    }
  }
  .UpdateNotification {
    .title {
      color: white;
    }
    .LeftOvalAction {
      float: unset;
    }
  }
}
.ReactModal__Overlay {
  &.FAccessoryOverlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // @include smartphone {
    //   width: 75%;
    //   height: 75%;
    // }
  }
}
.ReactModal__Content {
  &.FAccessoryGranitePickerPopupModal {
    background: #ffffff;
    border: none;
    top: 75px;
    .Header {
      background: #ffffff;
      border-bottom: none;
      .Headline {
        @include smartphone {
          font-size: 18px;
        }
      }
    }
    ._FfilterGranit{
      display: none;
    }
    .GenericGranitePicker {
      @media screen and (max-width: 576px){
        top: 30px;
      }
      h4 {
        display: none;
      }
      h3 {
        text-transform: none;
        margin-top: -10px;
      }
      .selected {
        h3 {
          color: var(--fcblue);
        }
      }
    }
    .ListView .ListViewItem {
      width: 40%;
      @include smartphone {
        width: 100%;
      }
    }
    .SuggestedGranites {
      //margin-left: 10%;
      .ListView{
        width: unset;
      }
    }
    .CloseModal {
      font-style: italic;
      text-transform: none;
      color: $fc-lightgrey;
    }
  }
}
/* .modal{
  backdrop-filter: blur(4px);
} */
