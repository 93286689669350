/*  Social elements  */
.FamilySpace{
  /*.LikeDislikes.answered{
    cursor: default;
  }*/
  .LikeDislikes {
    height: 100%;
    svg{
      height: 30px;
      width: 30px;
      @media screen and (max-width:576px){
        height: 28px;
        width: 28px;
      }
    }
    &.disabledLike,&.disabledDislike{
      /* background-color: #3568BA24; */
      svg {
        opacity: 0.5;
      }
    }
    &.disabledLike{
      border-bottom-left-radius: 8px;
    }
    .thumb {
      /*&.disabled {
        opacity: 0.5;
      }*/
    }
  }
  .SocialInfo{
    background:#222B33;
    border-radius: 10px;
    padding:14px;
    text-align: left;
    position: absolute;
    width: 200px;
    z-index:50;

    .title{color: #FFFFFF;font-size:18px;font-weight: bold;}
    .person{color: #FFFFFF;font-size:15px;}}
  .SocialBlock{margin-top:-35px; padding-left: 50px; text-align: left;@include smartphone{padding-left:0px}}
  .cursor {width: 30px;max-width: none;padding-left: 8px;}
  .Scomments{.SocialInfo{width:300px;position:absolute;bottom:40px;
    @include smartphone{width:200px;margin-left:-100px;margin-top:-120px;
      &.dropdown{button{font-size:18px; background: none; border: none;}}}
      .dropdown-menu.show{background:#222B33;width: 200px;padding-left: 5px;
                          padding-right: 5px;margin-left: -13px;}
    }
    .CommentChoice {position: absolute;top: -50px;width: 200px;left: -40px;cursor: pointer;
                    background: white;text-align: left;border:1px solid $fc-whitegrey;
          div{padding:3px}
  }
    .unreadBull{
      background-color: #D32F2F;
      height: 12px;
      width: 12px !important;
      border-radius: 25px;
      color: #D32F2F;
      position: absolute;
      top: 10px;
      left: 49%;
      border: 1px solid white;
      padding: unset !important;
    }
    svg{
      height: 30px;
      width: 30px;
      /* fill: var(--fcnavyblue); */
      @media screen and (max-width:576px){
        height: 28px;
        width: 28px;
      }
    }
  }
  .EnterComment{
    //border-top: 1px solid $fc-whitegrey;
    text-align: left;
    display: flex;
    align-items: center;
    &.fullRadius{border-radius: 8px;}
    &.topRadius{border-radius: 8px 8px 0 0;}
    background-color: white;
    border: 1px solid #E0E0E0;
    input{ width: 85% !important;font-size: 18px;color:$fc-lightgrey;border: none;margin: 15px;/*width:420px;*/outline: none;
      @include smartphone{width:unset}}
    i{cursor: pointer;}
    img{margin-left: 18px;}}
  
    /* Styling list of comments under each project */
  .CommentsContainer{
    display: block;
    position: absolute;
    width: 100%;
    z-index: 11;
    box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.12);
    margin-top: 2px;
    border-radius: 8px;
    left: 0;
  }
  .CommentsBlock{
    text-align:left;
    padding: 15px;
    color: var(--f-font-color);
    background-color: var(--f-main-color);
    border-radius: 0 0 8px 8px;
    max-height: 250px;
    overflow: auto;
    //box-shadow: 0px 5px 5px 0px rgba(0,0,0,.3);
    /*border-bottom: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;*/
  }
  .CommentBlock{
    
  }
  .CommentHead{
    font-size: 11pt;
    span{
      font-weight: bolder;
    }
  }
  .CommentText{
    background-color: #F1F4F7;
    color: #3B4752;
    border-radius: 10px 10px 10px 0;
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 10px 10px 10px 20px;
    font-size: 18px;
  }
  .CommentsBlock::-webkit-scrollbar {
	  width: 12px;               /* width of the entire scrollbar */
	}
	.CommentsBlock::-webkit-scrollbar-track {
    background: #EAEEF2;        /* color of the tracking area */
    border-radius: 0 0 10px 0;
	}
	.CommentsBlock::-webkit-scrollbar-thumb {
	  background-color: #727272;    /* color of the scroll thumb */
	  border-radius: 20px;       /* roundness of the scroll thumb */
	  border: 2px solid #6a655e;  /* creates padding around scroll thumb */
	}
}

.fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
