.ConfigurationCompletion {
  margin: 0 40px;
  @include smartphone {
    margin: 0 10px;
    > div {
      margin: 50px 0 0;
      &:first-child {
        height: 275px;
      }
    }

    .ConfigurationDetails {
      div:first-child > .col {
        .footstone-wrapper {
          display: none;
        }
        > div {
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 2.5rem !important;
        }
      }
    }

    .Actions {
      margin-bottom: 20px;
      button > span {
        display: none;
      }
    }
  }

  .ConfigurationIsoView {
    .LazyImageB {
      top:0;
    }
  }

  .Header {
    @extend %columns;
    background: $color-grey-E;
    border-bottom: 1px solid $color-grey-D;
    height: 100px;
    position: fixed;
    top: 70px;

    .CustomerInfos {
      padding: 0 20px;
      @include column(left);

      label {
        margin: 20px 10px 5px 0;
        @include column(left);

        span {
          color: $color-grey-6;
          display: block;
          font: font-default(16);
          text-transform: uppercase;
        }

        input[type=text] {
          width: 12em;
        }
      }
    }

    .Documents {
      margin-right: 70px;
      @include column(right);

      .Help {
        @extend %reset;
        background: transparent url("../../images/help-arrow.png") no-repeat right 62px;
        color: $color-grey-6;
        font: bold #{font-size(19)}/20px $font-stack-script;
        padding: 30px;
        text-align: center;
        text-transform: uppercase;
        @include column(left);
        @include retina {
          background-image: url("../../images/help-arrow@2x.png");
          background-size: 38px 12px;
        }

        small {
          display: block;
          font-size: 1em;
          text-transform: none;
        }
      }
    }
  }

  .action-buttons-container {
    z-index: 99;
    position: relative;
    .btn-wrapper {
      width: 70px;
      height: 36px;
      margin: 0 22px;
      -webkit-transition: all 0.35s;
      transition: all 0.35s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      clear: unset;
      float: unset;
      min-height: unset;
      .ModalButton {
        button {
          border-radius: 6px;
          border: 1px solid transparent;
        }
        .view3d-icon.icon {
          width: 30px !important;
          margin: 0;
        }
      }
      .ModalButton, .btn-config{
        width: 100%;
        -webkit-transition: all 0.35s;
        transition: all 0.35s;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
        height: 36px;
        .SquareButton {
          height: 100%;
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Raleway;
          .icon {
            background-color: var(--text-light);
          }
        }
        .additional-info {
          color: #6A7782;
          text-align: center;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: capitalize;
        }
      }
    }
  }

  .footstone-wrapper {
    span:first-child {
      color: var(--text-dark);
      text-align: center;
      font-family: Raleway;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      text-transform: capitalize;
      margin-right: 8px;
    }
    span:last-child {
      color: var(--text-dark);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: Raleway;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }

  .ConfigurationDetails {
    .btn-wrapper button {
      height: 3.75rem;
      width: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 0.125rem;
      border-radius: 0.625rem;
      box-shadow: 0rem 0.625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.04), 0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.10);
      .icon {
        width: 1.5rem;
        margin: 0;
        background-color: var(--text-dark)
      }

      .icon-width {
        width: 1.1875rem !important;
      }
    }

    .btn-icon {
      height: 40px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 2px;
      margin: 0px;
      box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);
      border-radius: 10px;
      transition: none;
      .icon {
        background-color: var(--text-dark);
        width: 20px;
        height: 20px;
        margin: 0px;
      }
    }

    .project-reference {
      color: var(--text-dark);
      font-family: Raleway;
      font-Size: 16px;
      font-Style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-right: 16px;
    }
  }

  .project-wrapper {
    margin-top: 16px;
    label {
      color: var(--secondary2);
      text-align: center;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .input-wrapper {
      &.error {
        border-color: #D4321D;
      }
      input {
        background-color: var(--background-page) !important;
        color: var(--primary);
      }
    }
  }

  .DocumentsHelp {
    background: transparent url("../../images/arrows/top-right.png") no-repeat right top;
    color: $color-help;
    font: font-script(22, normal, 100%);
    margin: 15px;
    margin-top: 45px;
    padding: 0px 90px 0 0;
    position: relative;
    text-align: center;
    @include column(right);
    @include mdpi {
      background-image: url("../../images/arrows/top-right@2x.png");
      background-size: 78px 22px;
    }

    strong {
      display: block;
      font-weight: font-weight-script(bold);
    }
  }

  .Field {
    &.notes-container {
      width: 70%;
    }
    &.editing{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ToggleEditButton {
      @extend %reset;
      color: $color-grey-9;
      cursor: pointer;
      height: 36px;
      text-decoration: none;
      width: 36px;
      @include column(left);

      &:hover {
        color: $color-blue;
      }

      .icon {
        @include material-size(24px, 36px);
      }
    }

    dl {
      @extend %reset;
      margin-left: 36px + 7px;

      dt {
        @extend %reset;
        color: $color-grey-9;
        display: block;
        font: font-default(14, normal, 16px);
        text-transform: uppercase;
      }

      dd {
        @extend %reset;
        color: $color-grey-3;
        display: block;
        font: font-default(18, normal, 20px);
        white-space: nowrap;
      }
    }

    .FieldContainer {
      @include smartphone {
        max-width: 100%;
      }
      @extend input;
      padding: 0;
      @include column(left);

      input, select, textarea {
        @extend %reset;
        border-left: 0;
        border-top: 0;
        display: inline-block;
        font-size: 1em;
        margin: 0 -1px -1px 0;
        padding: 9px;
      }

      .FieldUnit {
        font-size: 1em;
        padding: 0 0.25em;
      }

      button {
        @extend %reset;
        background: $color-grey-E;
        color: $color-blue;
        font-size: 0.9em;
        height: 36px;
        line-height: 20px;
        padding: 8px 0;
        text-align: center;
        text-transform: uppercase;
        width: 36px;
        @include column(right);
        @include border-left-radius(0);
      }
    }
  }

  .OrderFooter {
    // background: var(--background-navcards);
    // border-top: 1px solid $color-grey-D;
    // bottom: 70px;
    // height: 60px;
    // left: 0;
    overflow: hidden;
    padding: 1px;
    // margin: 0 150px;
    // position: fixed;
    right: 0;
    z-index: 400;
    // display: flex;
    button {
      // margin: 11px 9px;
    }
    .OrderTotals  {
      height: 36px;
      &.Prices {
        float: none;
        .SellingPrice {
          width: auto;
        }
      }
      .price-display {
        span {
          color: var(--text-dark);
          text-align: center;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-right: 8px;
        }
      }
      .price-total-wrapper {
        span {
          color: var(--text-dark);
          text-align: right;
          font-family: Raleway;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-right: 8px;
        }
        div {
          color: var(--text-dark);
          text-align: right;
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Raleway;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
      .ToggleSellingPrices {
        .icon {
          width: 40px;
          height: 45px;
        }
      }
      .ToggleBuyingPrices {
        .icon {
          width: 40px;
          height: 45px;
          background-color: #B91C1C;
        }
      }

    }
    @include smartphone {
      // position: fixed;
      // z-index: 1000;
      // display: flex;
      // padding: 0;

      .Prices {
        margin-left: auto;
      }
    }

    .AddCustomLine {
      // margin: 11px 9px;
      @include button-text-colors($color-bluegreen, $color-white);
      // @include column(left);
      float: left;
      display: flex;
      @include smartphone {
        margin: 6px 0px 6px 4px;
        border: 0.5px solid #21676e;
        border-radius: 50%;
        background-color: #21676e;
        padding: 0;
        span {
          display: none;
        }
        .icon {
          margin: 0;
        }
      }
    }
    .addAccessorie {
      margin: 11px 9px;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      font-family: "Raleway";
      line-height:normal;
      float: left;
      /* 
      display: inline-flex;
      margin: 6px 9px;
      color: #121212;
      background-color: white;
      padding: 5px 10px;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: none;
      font-size: 16px;
      height: 40px;
      border: 1px solid #d0cfcf;
      border-radius: 5px;
      */
      &:hover{
        color: var(--text-light) !important;
      }
    }

    .ProjectPaymentSettings {
      margin: 8px 9px;
      @include column(left);

      .FieldContainer input {
        border-right: 0;
        width: 3em;
      }

      .label {
        color: var(--text-medium);
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 6px;
        width: 100%;
      }

      .input-wrapper {
        width: unset;
      }

      @include smartphone {
        .Field {
          display: flex;
          align-items: center;
          padding: 4px;

          .ToggleEditButton{
            border: 1px solid #333;
            color: #333;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            .icon {
              font-size: 20px;
              line-height: 16px;
              width: unset;
            }
          }
          dl {
            font-size: 0.9rem;
            margin-left: 0;
          }
          .FieldContainer {
            display: flex;
            height: 20px;
            button {
              padding: 0;
              height: 20px;
            }
            .FieldUnit {
              line-height: 20px;
            }
          }
        }
      }
    }

    .lines {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      p {
        margin-bottom: 3px;
        color: var(--text-medium);
        text-align: center;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .line {
        background-color: var(--border-color);
        width: 100%;
        height: 1px;
        margin: 5px;
      }
    }

    .simulate-payment-wrapper {
      span {
        color: var(--text-dark);
        text-align: right;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-right: 8px;
      }
      .price {
        color: var(--text-dark);
        text-align: right;
        -webkit-font-feature-settings: lining-nums proportional-nums;
        font-feature-settings: lining-nums proportional-nums;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        small {
          top: -6px;
          position: relative;
          font-size: 8px;
        }
      }
      .info {
        color: var(--text-medium);
        font-family: Raleway;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        display: block;
        text-align: right;
        margin: 0px 40px 0;
      }
    }
  }

  .Footer {
    background: var(--secondary2-hover);
    color: $color-white;
    // left: 150px;
    // right: 150px;
    // width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &.without-navigation-buttons {
      position: relative;
      background-color: transparent;
    }

    .Actions {
      button {
        &:disabled {
          cursor: not-allowed;
          opacity: 0.6 !important;
        }
      }

      .CreateVariant {
        margin: 16px 14px;
        @include button-text-colors($color-grey-6, $color-grey-D);
        // @include column(left);
        float: left;
        display: flex;
      }

      .Order {
        margin: 11px 9px;
        /*
        @extend %button-text;
        font-size: font-size(20);
        height: auto;
        margin-top: 5px;margin-right:5px;
        width: 250px;
        @include column(right);
        @include button-text-colors($color-green, $color-white);
        @include keyframes(move-truck) {

          0% {
            @include transform(translateY(-1px));
          }

          35% {
            @include transform(translateY(0px));
          }

          75% {
            @include transform(translateY(-1px));
          }

          100% {
            @include transform(translateY(-1px));
          }
        }

        &:hover {
          color: $color-white;
        }
        &[disabled]{background: none;cursor:not-allowed}
        &.alreadyOrdered {
          background: none;
          border: none;
          color: $color-green;
          width: 170px;
        }

        .icon {
          width: 50px;
        }

        &:hover {

          .icon {
            @include animation(move-truck 0.3s linear infinite);
          }
        }

        &.disabled {

          &, &:hover {
            background: $color-green;
            color: $color-white;

            .icon {
              @include animation(none);
            }
          }
        }
        */
      }

      .SaveInfo {
        @extend %reset, %border-radius;
        background-color: $color-white;
        margin: 10px;
        padding: 8px 16px;
        // position: absolute;
        // left: 350px;
        text-align: center;
        text-decoration: none;
        height: 43px;
        display: flex;
        align-items: center;

        @include smartphone { display: none; }

        strong {
          color: $color-grey-6;
          display: block;
          font: font-default(16, bold, 20px);
          text-transform: uppercase;
        }

        small {
          color: $color-grey-9;
          display: block;
          font: font-default(13, normal, 14px);
        }

        .icon {
          // color: $color-green;
          // font-size: 30px;
          // line-height: 50px;
          // margin-left: 0.25em;
          // position: absolute;
          // right: 0;
          // top: 0;
          // width: 50px;
          margin-right: 16px;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%2322C55E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.2445 5.42085C16.4723 5.64866 16.4723 6.01801 16.2445 6.24581L7.91118 14.5791C7.68337 14.807 7.31403 14.807 7.08622 14.5791L3.75289 11.2458C3.52508 11.018 3.52508 10.6487 3.75289 10.4209C3.98069 10.193 4.35004 10.193 4.57784 10.4209L7.4987 13.3417L15.4196 5.42085C15.6474 5.19305 16.0167 5.19305 16.2445 5.42085Z' fill='%23EAEEF2'/%3E%3C/svg%3E%0A");
        }
      }
    }

    .Headline {

      h1 {
        margin: 10px 0 0;
        text-align: center;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .Details {
        // @extend %reset;
        // color: $color-grey-9;
        // font: font-default(16, normal, 26px);
        color: var(--text-light);
        text-align: center;
        font-family: Raleway;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }

  .ConfigurationDetails {
    @extend %border-radius;
    // background: $color-white;
    // border: 1px solid $color-grey-D;
    // margin: 28px 150px 65px;
    overflow: visible;
    padding: 0 1px;

    .btn-config-secondary {
      &:hover {
        .icon {
          background-color: var(--text-light) !important;
        }
      }
    }

    .Order {
      &:disabled {
        opacity: 0.6 !important;
        cursor: no-drop;
      }

      &.alreadyOrdered {
        color: var(--text-dark);
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
    }

    @include smartphone {
      // border: none;
      // border-radius: 0px;
      // position: fixed;
      // margin: 0;
      // top: 50px;
      // left: 0;
      // width: 100vw;
      // z-index: 1000;
      // height: calc(100vh - 165px);
      // overflow-y: scroll;
    }

    .Container {
      // height: 100%;
      // overflow-y: auto;
      // overflow-x: hidden;
      display: block;
      margin: 10px 0 10px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
            
      .Line {
        display: flex;
        .RemoveItem, .ToggleEditButton {
          cursor: pointer;
        }
        .ToggleEditButton {
          width: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          .edit-icon {
            background-color: var(--text-dark);
            margin: 5px 0;
            width: 24px;
            height: 24px;
          }
        }
        .RemoveItem {
          width: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          margin: 4px 5px 4px 0;
        }
        .ToggleEditButton.disabled .icon  {
          opacity: 0.5;
        }
        .RemoveItem .icon {
          background-color: $color-red;
        }
      }      
    }
  }

  .pop-up {
    position: fixed;
    background-color: #fff;
    z-index: 2000;
    bottom: 65px;
    right: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .Actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .CreateVariant, .Order, .SaveInfo, .saveFspaceButton button{
        background-color: #fff;
        border: none;
        font-size: 18px;
        text-decoration: none;
        padding-left: 5px;
        text-transform: none;
        color: #000;

        i {
          float: none;
          font-size: 18px;
          color: #000;
          line-height: 36px;
          width: 36px;
          margin: -10px;
          vertical-align: middle;
          padding:0 10px 3px 0 ;
        }
      }

      .SaveInfo {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .saveFspaceButton button {
        position: relative;
        right: 0;
        height: auto;
        margin: 0;
        width: 274px;
      }

      .Order[disabled], .Order[disabled] i{
        color: rgba(0,0,0,0.38);
      }
    }
  }

  .Footer {
    @include smartphone {
      .PreviousStep {
        margin-top: 28px;
        i {
          color: #fff;
        }
      }

      .FooterPanel {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-around;
        padding-top: 28px;
      }

      .PlusIcon {
        text-align: center;

        div {
          display: block;
          font-size: 18px;
        }
      }

      .Actions {
        display: flex;
        flex-direction: column;
        .CreateVariant, .Order {
          margin: 3px;
          padding: 5px;
          font-size: 1.4rem;
          display: flex;
          .icon {
            font-size: 1.5rem;
          }

        }
        .PreviousStep {
          width: unset;
        }
      }
    }
  }
}

  .Prices {
    .Cell {
      &.show {
        display: block;
        small {
          top: -6px;
          position: relative;
          font-size: 8px;
        }
        .price-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
      }
    }

    .Cell.Separator {
      background: transparent none;
      width: 3px;
    }

    .BuyingPrice, .SellingPrice {
      @extend %reset;
      background: none;
      display: none;
      padding: 5px 10px;
      @include smartphone {
        // width: 33%!important;
        padding: 5px 1px;
      }

      span {
        display: block;
        font-variant-numeric: lining-nums proportional-nums;
        text-transform: uppercase;
      }
      @include smartphone {
        > span {
          display: none;
        }
      }

      strong {
        display: block;
        font: font-default(16, normal, 25px);
        @include smartphone {
          font-size: 20px;
        }
      }
    }

    .BuyingPriceButton {
      display: none;
    }

    button {
      &.ToggleBuyingPrices, &.ToggleSellingPrices {
        padding: 8px 16px;
        position: relative;
        top: 2px;
        .icon {
          background-color: var(--text-dark);
          -webkit-mask-repeat: no-repeat;
          margin-top: 0px;
        }
      }

      @media screen and (max-width:576px) {
        &.ToggleBuyingPrices, &.ToggleSellingPrices {
          outline: none;
        }
      }

      &.Inlist {
        background: $color-white url("../../images/price-array.png") no-repeat center center;
        @include retina {
          background-image: url("../../images/price-array@2x.png");
          background-size: 50px 50px;
        }
      }
    }

    .BuyingPrice {
      strong {
        font-variant-numeric: lining-nums proportional-nums;
      }
    }

    .SellingPrice {

      strong {
        color: $color-sellingprice;
        font-variant-numeric: lining-nums proportional-nums;
        margin-top: 2px;
      }
    }

  }

  button {
    text-transform: none;
  }

  @mixin config3d-metadata-breakpoint {
    @media only screen and (max-width: 1500px) {
      @content;
    }
  }
  @mixin config3d-metadata-breakpoint-small {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  .ConfigurationDetails {

    .ProjectMetaData {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
      .MetaDataHeader {
        background: var(--secondary2-hover);
        color: $color-white;
        cursor: pointer;
        position: relative;
        height: 36px;
        .icon {
          @include material-size(24px, 36px, left);
        }
        .Button {
          background: var(--secondary2-hover);
          color: $color-white;
          position: absolute;
          top: 0;

          width: 32px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            // @include transform(rotate(90deg));
            width: 24px;
            display: flex;
            height: 24px;
            justify-content: center;
            align-items: center;
            background-color: var(--text-light);
          }

          &.LeftButton {
            @extend %border-radius;
            right: 100%;
            @include border-right-radius(0);
          }

          &.RightButton {
            right: 0;
          }
        }

        .Label {
          border-left: 1px solid $color-grey-D;
          font: font-default(18, normal, 26px);
          // margin: 5px -1px 5px 0;
          text-transform: uppercase;
          vertical-align: middle;
          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          height: 100%;

          @include smartphone {
            border: none;
          }

          .icon {
            margin: -5px 5px;
          }
        }
      }

      .Groups {
        height: 100%;
        @extend %columns;
        :disabled{cursor: not-allowed; }
      }

      .Group {
        width: 50%;
        @include column(left);
      }

      .ProjectGroup {
        .tooltip::before {
          min-width: 300px;
        }
        .Field {
          &.project-customer {
            width: 50%;
          }
        }
        .notes {
          height: auto !important;
          width: auto !important;
          border: 0px;
        }
        .option-project {
          padding: 12px;
          line-height: 2px;
          input {
            width: 20px !important;
            &:not(:first-child) {
              margin-left: 12px;
            }
          }
        }
      }

      .FamilyGroup {
        .tooltip::before {
          min-width: 300px;
        }
        .Field {
          &.family-contact-name {
            width: 50%;
          }
        }
        .flex-container {
          display: inline-flex;
          .FamilyEmailError{
            border-color: #D4321D;
          }
          #email-msg-error {
            color: #D4321D;
            left: 460px;
          }
        }
      }
      
      .Content {
        @extend %columns;
        background: var(--background-navcards);
        // border: 1px solid $color-grey-D;
        border-bottom-width: 4px;
        border-top: 0;
        max-height: 600px;
        @include transition(max-height 0.35s ease);

        .Fields {
          border-left: 1px solid $color-grey-D;
          margin: 5px 0 5px -1px;
          padding: 5px 0;
        }

        .FieldTitle {
          color: var(--secondary2);
          font: font-default(14px, normal, 18px);
          // text-transform: uppercase;
          padding-left: 10px;
        }

        .FieldHelp {
          font: font-script(18px, normal, 140%);
          color: $color-grey-6;
          position: absolute;
          top: 28px;
          left: 400px;
          //max-width: 500px;

          &Arrow {
            position: absolute;
            left: -20px;
          }


          @include config3d-metadata-breakpoint {
            left: 300px;
          }

          @include config3d-metadata-breakpoint-small {
            position: relative;
            width: 300px;
            left: 30px;
            top: 0;

            &Arrow {
              transform: rotate(90deg);
            }
          }

          @include smartphone {
            font-family: Verdana;
            font-size: 12px;
            width: 90%;
            left: 10px;
            &Arrow {
              display: none;
            }
          }

        }

        .alert {
          .FieldContainer, .input-wrapper {
            border-color: $color-alert;
          }
          .FieldHelp {
            color: $color-alert;
          }
        }

        .FieldContainer input,
        .FieldContainer textarea {
          box-sizing: border-box;
          width: 100%;

          &::-webkit-input-placeholder {
            color: $color-grey-D;
          }

          &:-moz-placeholder { /* Firefox 18- */
            color: $color-grey-D;
          }

          &::-moz-placeholder {  /* Firefox 19+ */
            color: $color-grey-D;
          }

          &:-ms-input-placeholder {
            color: $color-grey-D;
          }
        }

        .Empty {
          color: $color-grey-6;
        }

        ul {
          @extend %list-reset;
          padding-left: 30px;
          padding-right: 10px;

          @include config3d-metadata-breakpoint-small {
            padding-left: 10px;
            padding-right: 10px;
          }

          li {
            //@extend %columns;
            position: relative;
            clear: both;
          }
        }

        .Customer,
        .FamilyName {
          width: auto;
          // @include config3d-metadata-breakpoint {
          //   width: 260px;
          // }
          // @include config3d-metadata-breakpoint-small {
          //   width: 360px;
          // }
        }

        .FamilyStreetContainer {
          line-height: 0;
        }

        .FamilyStreet {
          height: 35px;
          max-height: 200px;
          resize: vertical;
          width: 360px;
          @include config3d-metadata-breakpoint {
            width: 260px;
          }
          @include config3d-metadata-breakpoint-small {
            width: 360px;
          }
        }

        .Cemetery,
        .FamilyCity {
          width: 240px;
          @include config3d-metadata-breakpoint {
            width: 140px;
          }
          @include config3d-metadata-breakpoint-small {
            width: 240px;
          }
        }

        .FamilyCity ,
        .FamilyPostalCode {
          border-bottom: none;
        }
        .FamilyCity {
          border-right: none;
        }


        .CemeterySpot,
        .FamilyPostalCode {
          width: 120px;

        }
        .FamilyPhone {
          width: 160px;
        }
        .FamilyEmail {
          width: 260px !important;
        }
        .FamilyTypeConcession {
          width: 100%;
          border: 1px solid var(--primary);
          border-radius: 10px;
          padding: 8px 16px;
          background: var(--background-page);
          font-family: "Raleway", cuprum, sans-serif !important;
        }
      }

      &.collapsed .Content {
        max-height: 0;
      }
    }

    .Line.AddLines{
        @include smartphone {
        display:none
      }
    }
    .Line {
      margin: 0;
      position: relative;
      width: 100%;
      @include smartphone {
        display: flex;
        // flex-direction: column-reverse;
      }
      &.Package{img{width:50%}}
      &.Presgrav{
        .SellingPrice{
          opacity: 0;
        }
        img{
          width: 56%;
        }
        
      }

      &.ShippingLine {

        & {
          border-top: 3px solid $color-grey-D;
        }

        .PricesBlock .Prices {

          .BuyingPrice,
          .SellingPrice,
          .Cell {
            // height: 56px;
          }
        }

        .Image,
        .PricesBlock .Prices .Separator {
          // height: 76px;
        }
      }

      .Actions {
        // position: absolute;
        // padding: 10px;
        right: 100%;
        top: 0;
        // box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 8px;

        @include smartphone {
          width: 30%;
          position: static;
          margin-top: -108px;
          margin-bottom: 70px;
          z-index: 100;
          button {
            border: none;
          }
        }

        button {
          // margin-bottom: 3px;
          width: 39px;
          @include border-right-radius(0);
        }

        .icon {
          margin-left: -10px;
        }
      }

      .Row {
        display: flex;
        width: 100%;
        padding: 5px 0;
        @include smartphone {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .RemoveItem {
        &.disabled {
          opacity: 0.5;
        }
      }

      .Image {
        background: $color-white;
        min-height: 170px;
        min-width: 300px;
        @include smartphone {
          width: 30%;
        }

        .FullSize {
          bottom: 0;
          height: 100%;
          left: 0;
          margin: 0;
          right: 0;
          top: 0;
          width: 100%;
        }
      }

      .Description {
        flex-grow: 1;
        font: font-default(16, normal, 135%);
        font-variant-numeric: lining-nums proportional-nums;
        padding: 10px;
        @include smartphone {
          width: 70%;
        }

        &.clickable {
          cursor: pointer;
        }
        
        p {
          @extend %reset;
          color: var(--text-medium);
        }

        strong {
          color: var(--text-dark);
          font-weight: bold;
        }

        a {
          color: var(--text-medium) !important;
          font-weight: normal;
          text-decoration: none;
        }

        em {
          color: $color-grey-6;
          font-style: italic;
        }

        .quantity{
          display: flex;
          align-items: center;
          //justify-content: center;
          margin-top: 10px;
          input{
            width: 50px;
            height: 40px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background-color: #dcdcdc;
            border: 1px solid #9c9c9c;
          }
          .btn-control{
            height: 40px;
            padding: unset;
            width: 40px;
            background-color: #dcdcdc;
            border: 1px solid #9c9c9c;
            &.minus{
              //margin-right: 5px;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            &.plus{
              //margin-left: 5px;
              border-top-right-radius: 15px;
              border-bottom-right-radius: 15px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }

      .PricesBlock {
        padding: 10px;
        min-width: 130px;
        max-width: 170px;
        .SellingPrice {
          padding: 0;
        }
        @include smartphone {
          width: 100%;
          min-height: unset;
        }
      }

      .Prices {
        height: 100%;
        margin-right: -1px;
        position: relative;
        @include smartphone {
          // display: flex;
          // justify-content: flex-end;
          // width: 100%;
          // background: white;
          // overflow: hidden;
          // height: 70px;
          // border-top: 1px solid $color-grey-D;
          .BuyingPrice {
            &.show {
              small {
                top: -6px;
                position: relative;
                font-size: 8px;
              }
            }
            width: 33%;
            height: unset;
            strong {
              font-size: 1.5rem;
            }
          }
          .SellingPrice {
            strong {
              // font-size: 2rem;
            }
          }
        }

        .BuyingPrice, .SellingPrice {
          span.Discount {
            color: var(--text-medium);
            font: font-default(14);
            font-variant-numeric: lining-nums proportional-nums;
            margin-bottom: 0.5em;
            text-decoration: line-through;
            text-transform: uppercase;

            .DiscountRate {
              background: $color-buyingprice;
              color: $color-white;
              display: inline-block;
              font: font-default(1em);
              margin-left: 0.25em;
              padding: 0 0.25em;
              text-decoration: none;
            }
          }

          span.Label {
            font-size: font-size(14);
            text-transform: unset;
          }

          span.Price {
            font: font-default(14);
            text-transform: none;
          }
        }

        .SellingPrice {
          &.show {
            .Field.viewing {
              height: 100%;
              display: flex;
              align-items: center;
            }
            small {
              top: -6px;
              position: relative;
              font-size: 8px;
            }
          }
          
          .ViewContainer {
            text-align: right;
          }

          .FieldContainer {
            border: 1px solid var(--border-color);
            border-radius: 6px;
            text-align: right;
            @include column(right);
            display: flex;
            align-items: center;
            justify-content: center;
            input {
              border-right: 0;
              color: $color-sellingprice;
              font-variant-numeric: lining-nums proportional-nums;
              height: 35px;
              width: 100%;
              @include column(left);
              @include smartphone {
                height: 36px;
              }
            }
          }
        }

        label.PriceChange {
          @extend %reset;
          background: $color-white;
          border: 2px solid $color-grey-B;
          color: $color-sellingprice;
          display: inline-block;
          font: font-default(22, normal, 20px);
          margin: -7px;
          padding: 3px 10px;
          position: relative;
          text-align: right;
          width: 130px;
          @include border-radius(20px);

          input[type=number] {
            @extend %reset;
            color: $color-sellingprice;
            font: font-default(1em);
            text-align: right;
            width: 90px;
          }

          span {
            color: $color-sellingprice;
            float: right;
            font: font-default(1em);
          }
        }
      }

      &.additionalLineFormContainer {

        .BuyingPrice, .ToggleBuyingPrices {
          opacity: 0;
        }
      }
    }

    .Veneer {
      $veneerRatio: 0.42;
      $veneerHeight: 384px;
      @extend %reset, %image-only;
      display: block;
      height: 100%;
      overflow: hidden;
      width: 100%;

      .Dimensions {
        position: relative;
      }

      .Part {
        background: transparent url("../../images/veneer.png") no-repeat center center;
        background-size: $veneerRatio*1126px $veneerRatio*$veneerHeight*2;
        display: block;
        overflow: hidden;
        position: absolute;
        z-index: 100;
      }

      .Back {
        display: block;
        text-align: center;

        .Part {
          background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 15px + 15px);
          height: $veneerRatio*(51px + 30px);
          left: 50%;
          margin-left: $veneerRatio*-(212px + 30px)/2;
          top: 0%;
          width: $veneerRatio*(212px + 30px);

          &.Double {
            background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 15px + 15px - $veneerHeight);

            &.active {
              background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 30px - 51px - $veneerHeight);
            }
          }

          &.active {
            background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 30px - 51px);
          }
        }
      }

      .Left, .Right {
        height: $veneerRatio*390px;
        margin-top: $veneerRatio*10px;
      }

      .Left {
        @include column(left);

        .Part {
          background-position: $veneerRatio*(- 15px + 15px) $veneerRatio*(- 22px + 15px);
          height: $veneerRatio*(339px + 30px);
          left: 30px;
          top: 0;
          width: $veneerRatio*(143px + 30px);

          &.Double {
            background-position: $veneerRatio*(- 15px + 15px) $veneerRatio*(- 22px + 15px - $veneerHeight);

            &.active {
              background-position: $veneerRatio*(- 30px - 143px) $veneerRatio*(- 22px + 15px - $veneerHeight);
            }
          }

          &.active {
            background-position: $veneerRatio*(- 30px - 143px) $veneerRatio*(- 22px + 15px);
          }
        }
      }

      .Right {
        @include column(right);

        .Part {
          background-position: $veneerRatio*(- 363px + 15px) $veneerRatio*(- 22px + 15px);
          height: $veneerRatio*(339px + 30px);
          right: 30px;
          top: 0;
          width: $veneerRatio*(143px + 30px);

          &.Double {
            background-position: $veneerRatio*(- 363px + 15px) $veneerRatio*(- 22px + 15px - $veneerHeight);

            &.active {
              background-position: $veneerRatio*(- 363px - 15px - 143px) $veneerRatio*(- 22px + 15px - $veneerHeight);
            }
          }

          &.active {
            background-position: $veneerRatio*(- 363px - 15px - 143px) $veneerRatio*(- 22px + 15px);
          }
        }
      }

      .Front {
        clear: both;
        display: block;
        text-align: center;

        .Part {
          background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 177px + 15px);
          bottom: 100%;
          height: $veneerRatio*(81px + 30px);
          left: 50%;
          margin-left: $veneerRatio*-(400px + 30px)/2;
          width: $veneerRatio*(400px + 30px);

          &.Double {
            background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 177px + 15px - $veneerHeight);

            &.active {
              background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 177px - 15px - 81px - $veneerHeight);
            }
          }

          &.active {
            background-position: $veneerRatio*(- 711px + 15px) $veneerRatio*(- 177px - 15px - 81px);
          }
        }
      }
    }

    .AdditionalLineForm {
      font: font-default(100%/18%);

      input.AdditionalTitle, textarea.AdditionalDescription {
        width: 100%;
        @include box-sizing(border-box);
      }

      input.AdditionalTitle {
        border-bottom: 1px solid $color-grey-D;
        font: font-default(18);
        margin-bottom: 0;
        @include border-bottom-radius(0);
      }

      textarea.AdditionalDescription {
        border-top: 0;
        font: font-default(18);
        font-style: italic;
        height: 70px;
        margin-top: 0;
        @include border-top-radius(0);
      }

      button {
        margin-top: 4px;
        @include button-text-colors($color-blue, $color-white);
      }
    }
  }

  .ConfigurationDetailsPopup {
    bottom: 0;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 70px;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: var(--background-page);
    .Header {
      height: 70px;
      left: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      z-index: 800;
    }

    + a.CloseModal {
      position: absolute;
      right: 0;
      top: 0;
    }

    .ConfigurationDetails {
      // bottom: 130px;
      left: 0;
      position: absolute;
      right: 0;
      top: 70px;
      padding: 0 20px;
      .ConfigurationDetailsScroll {
        &::-webkit-scrollbar {
          width: 10px;
          padding-right: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #888;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: #555;
        }
        .ToggleBuyingPrices {
          .calculator-icon {
            background-color: #a11525;
          }
        }
        .price-title {
          color: var(--text-dark);
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          padding: 0 30px;
          text-align: center;
        }
        .price {
          color: var(--text-dark);
          font-family: Raleway;
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
      .RemoveItem, .ToggleEditButton, .ToggleBuyingPrices {cursor: pointer; text-align: right;}
      .Container {
        .Line {
          .col-2, .col-4, .col-6 {padding: 0;}
          .Prices  {
            .BuyingPrice, .SellingPrice  {text-align: right;}
          }
          .ToggleEditButton {
            height: 24px;
            width: 24px !important;
          }
          .Field.editing  {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 36px;
            padding: 5px;
          }
          .Field.viewing {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 36px;
            margin: 5px;
            .ViewContainer {
              flex-grow: 1;
            }
          }
          .FieldContainer {
            input {
              margin: 0 -1px -1px 0;
              padding: 9px;
              background: transparent none;
              border: 0;
              font: 400 1.6em "Raleway", cuprum, sans-serif !important;
              &:focus-visible {
                outline: 0;
              }
            }
            button {
              background: #e2e2e2;
              color: #149edc;
              font-size: 15px;
              height: 36px;
              padding: 8px 0;
              text-transform: uppercase;
              width: 36px;
              border: 1px solid var(--border-color);
            }
          }
          .ToggleEditButton {
            // width: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            margin: 4px 5px 4px 7px;
            .icon {
              margin-left: 0;
              width: 24px;
              height: 24px;
            }
          }
          .ToggleEditButton.disabled .icon  {
            opacity: 0.5;
          }
          .RemoveItem {
            z-index: 9999;
            &.disabled {
              opacity: 0.5;
            }
            .icon {
              background-color: $color-red;
            }
          }
        }
      }
    }

    .ConfigurationDetailsScroll {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 20px;
    }

    .AddLines {

      p {
        @extend %reset;
        color: $color-grey-6;
        font: font-default(20);
        padding: 30px;
        text-align: center;

        a {
          text-transform: uppercase;
        }
      }
    }

    .Footer {
      background: var(--background-page);
      bottom: 0;
      height: auto;
      left: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      right: 0;
      width: auto;
      z-index: 800;

      .Actions {
        margin:  0 0 0 10px;
        @include column(left);
        @include smartphone {
          display:none
        }
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .OrderTotals  {
        height: 100%;
        width: 100%;
        padding: 0 40px;
        flex-direction: column;
        .price {
          color: var(--text-dark);
          font-family: Raleway;
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        .toggle-buying-prices {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .calculator-icon {
            cursor: pointer;
            background-color: #a11525;
          }
        }

        .row {
          margin: 0;
          &:first-child {
            height: 60px;
          }
          &:last-child {
            height: 80px;
          }
          .input-wrapper {
            width: 105px;
          }
          .price-display, .ProjectPaymentSettings {
            span {
              color: var(--text-medium);
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              margin-right: 6px;
            }
          }
        }
      }

      .Prices {
        text-align: right;
        margin-right: -1px;
      }

      .EditConfiguration {
        @extend %button-text;
        margin: 5px 9px;
        @include button-text-colors($color-blue, $color-white);
        @include column(left);
        font-weight: 700;
        display: flex;
        &:hover {
          color: $color-white;
        }
      }

      .Order:disabled {
        opacity: 0.6 !important;
        cursor: no-drop;
      }
    }

    .linkDisabled {
      cursor: not-allowed !important;
      opacity: 0.25;
    }
  }

  .ReactModalPortal {

    .PlaceOrderModalOverlay {
      background: rgba(0, 0, 0, 0.8);
      top: 0;

      .ReactModal__Content {
        background: $color-white;
        left: 25%;
        right: 25%;

        @include smartphone {
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          overflow-y: scroll;
        }
      }

      .Header {

        .HeaderContainer {
          padding: 8px 15px;
          @include smartphone {
            margin-top: 0;
            padding-top: 4rem;
          }
          h1 {
            text-align: left;
          }
        }
      }

      .Actions, .Image, .Prices {
        display: none;
      }

      .PlaceOrder {
        bottom: 200px;
        left: 0;
        overflow: auto;
        padding: 15px;
        position: absolute;
        right: 0;
        top: 54px;
        width: auto;

        @include smartphone {
          top: 110px;
        }

        section {
          margin-bottom: 20px;
        }

        h2 {
          @extend %reset;
          color: $color-grey-9;
          font: font-default(16);
          text-transform: uppercase;
        }

        a {
          color: $color-grey-3;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        p {
          @extend %reset;
          color: $color-grey-6;
          font: font-default(20);

          strong {
            color: $color-grey-3;
            font-weight: font-weight-bold();
          }
        }

        .TotalBuyingPrice {

          &, strong {
            color: $color-buyingprice;
          }
        }

        .Description {
          color: $color-grey-3;
          font-size: 2em;
          line-height: 130%;
          margin-bottom: 15px;
        }
        .uploadFile{ width:50%;float: left;margin-bottom:20px;
          .required:after {content:" *";font-size:16px;color:#a51522;}
          .uploadTitleFile{text-transform: uppercase;color: #abacb2;font-size: 16px;
            margin-bottom: 5px;margin-top: 5px;}
          input{ border: none;}
          .errorUpload {color: red;font-size: 14px;position: absolute;margin-top: -10px;}
        }

      }

      .Footer {
        height: 200px;

        .property {
          display: block;
          margin: 5px;

          .label {
            display: none;
          }

          textarea,
          input {
            font: font-default(20);
            padding: 10px;
            width: 100%;
            @include box-sizing(border-box);
          }

          textarea {
            height: 80px;
          }
        }

        .UseRegularDeliveryAddress,
        .AgreeAndProceedCheck {
          display: block;
          font: font-default(20, normal, 25px);
          margin: 0 265px 0 15px;

          input {
            font-size: 150%;
            margin-right: 15px;
          }
        }

        .UseRegularDeliveryAddress {
          margin: 15px 15px 15px 15px;
        }

        .columns {

          .col-4 {
            margin-right: -2px;
            width: 33.33333%;
            @include column(left);
          }

          .col-8 {
            width: 66.66666%;
            @include column(left);
          }
        }

        .CommentBoxContainer {

          textarea {
            height: 130px;
          }
        }

        .ValidateOrderLine {
          @extend %columns;
          height: 50px;
          position: relative;
          @include smartphone {
            overflow: visible;
          }

          .AgreeAndProceedCheck {
            @include column(left);
          }

          .ValidateOrder {
            font: font-default(20);
            margin: 0;
            position: absolute;
            right: 8px;
            top: 0;
            width: 280px;

            .icon {
              @include material-size(32px, 48px, right);
            }
          }

          @include smartphone {
            .AgreeAndProceedCheck {
              display: flex;
              align-items: center;
              width: 50%;
              input[type="checkbox"] {
                height: 6rem;
                width: 6rem;
              }
              span {
                font-size: 1.3rem;
                line-height: 1em;
                display: block;
              }
            }
            .ValidateOrder {
              width: 33%;
              font-size: 1.5rem;
              display: flex;
              align-items: center;
            }
          }
          .pbDisableHoverText{font-size:14px;color:red;position: absolute;right:90px;top:0px; width:200px;}
        }
      }
    }

  }

  .DimensionsTooBig {
    color: #d6951f;
    font-size: 16px;
    line-height: 18px;

    i {
      float: left;
      margin: -5px 5px 0 0;
    }
  }
  span.asterisque{color:#e62514!important}
 .Line .Prices  span.asterisque{display: inline }
 .FrameDimensions span{padding-right:15px}

 .custom-modal {
  overflow: auto !important;
  width: 80%;
  margin: 0 auto !important;
  background-color: var(--background-page) !important;
  @include smartphone {
    width: auto
  }
  .content {
    padding: 16px 24px;
    .input-wrapper {
      background-color: var(--background-page) !important;
      &.family-street-container {
        padding: 4px 7px;
      }
      &.error {
        border-color: $color-alert;
        #email-msg-error {
          color: #D4321D;
        }
      }
      input, textarea, select {
        background-color: var(--background-page) !important;
        color: var(--primary);
        border: 0;
        &:focus,
        &:focus-visible,
        &:hover,
        &:active {
          border: 0;
          box-shadow: none;
          outline: none;
        }
      }
    }

    .notes {
      padding-right: 0.3125rem;

      textarea {
        overflow-y: auto;
        height: 7rem;
      }
    }

    h5 {
      color: var(--text-cta);
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.25px;
      margin-bottom: 24px;
    }
    
    label {
      color: var(--secondary2);
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    .type-concession {
      height: 43px;
      select {
        border: 0;
        width: 100%;
      }
    }

    .select-address {
      width: 100%;
      font-size: 16px;
    }

    hr {
      border-color: var(--border-color);
    }

    button {
      text-transform: none;
    }
  }
 }

 .price-title-wrapper {
  display: flex;
  flex-direction: column;

  .price-title {
    color: var(--text-dark);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0.313rem;
  }
}