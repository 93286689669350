.ImageGenerationConf{font-size:18px; .important{color:red}; 
  button{font-size:18px}; p{width: 70%;margin-left: 5%;margin-bottom: 50px;}}
  .AdminMain-monuments .adminPreconfig .Monument img{height: unset;}
.adminPreconfig{ background: white;font-size:18px;width: 100%;padding-left:10px;
  button.AdminFamilyRefreshAll {font-size: 12px;position: absolute;right: 400px;top: 20px;}
  div.AdminFamilyRefreshAll {position: absolute;right: 10px;top: 5px;}
  div.AdminFamilyRefreshAll > button {font-size: 12px;}
  .MonumentGrid{  display: grid;grid-template-columns: repeat(4, 1fr);/*height:635px;overflow-y: scroll;*/} 
  .AdminFamilyHeader{margin-top:5px;margin-bottom: 15px;}
   .Monument{height:210px;margin-left: -5px;background: white;position:relative;
    img{height:210px;    position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
     .title{position:relative;top:185px;padding-left: 10px;z-index: 900;}
     .orderInput { height: 28px;max-width: 37px;display: inline;border: 2px solid #929298;border-radius: 3px;width: fit-content;z-index: 9;position: absolute;top: 5px;left: 19px;padding: 5px 3px;text-align: center;font-size: 18px;
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {  -webkit-appearance: none; }
      }  
    }
    .change,.add,.header,.delete,.refresh{position:relative;top:-10px;left:20px;font-size:32px;cursor:pointer; z-index: 800;
      margin-right:5px;          &.single{font-size:20px}; &.below{top:11px}}
      i.icon.material-icons.header {font-size:28px;}
     .change{color:orange;}
     .add{color:green}
     .delete{color:red}
     .refresh{color:grey;left:320px;font-size:24px;}    
  .generateNewImages{font-size:14px;position: absolute;right:30px;top:120px;cursor: pointer;
                     z-index: 3000;}
  .adminContainer{font-size:14px;}
  .MonumentSearchBox{height:unset;min-height: unset;overflow:unset;margin-bottom: unset;}
  .MonumentSearchBox .Categories {margin:0;.Largecat { width: 100px;}}

  .FilterLayout{display:none}
  .FilterButtons div {width:100px}
  .FilterButtons .Category-001-399 {width:200px;}
  .FilterButtons div figure figcaption p {display:none}
  .FilterButtons div figure{height:unset}
  .FilterButtons.sticky{padding-top:120px}
  .SearchFilter {font-size:8px}
  .SearchFilter h3 {display: none}
  .Search{position: fixed; right: 0px;}
  .Categories .Category-Favorite figure { height:90px;background-color:navy;
      img{transform:none;-webkit-transform: none;display:none}
  h2{width:unset;bottom:unset;line-height: 0;text-indent: -9999px;}}
  .Categories .Category-Favorite figure   h2::after {content:"Incontourn.";text-indent:0;display: block;line-height: initial;}
  .Categories .Category-New figure { height:90px;img{transform:none;-webkit-transform: none;}h2{width:unset;bottom:unset}}
  .choiceAdd{background:white;position:relative;top:-40px;left:50px;list-style: none;width:270px;cursor: pointer;z-index: 1100;
            li{border-bottom:1px solid navy;margin-top:2px;color:navy}}
}
.react-confirm-alert{
  @media screen and (max-width: 576px){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: unset;
  }
  .SelRefreshAll{font-size:24px;text-align:center;color:#900C3F;font-weight:bold;
    button{font-size:24px;}}
}
.ReactModal__Content.ReactModal__Content--after-open.refreshAll {
  text-align: center;
  font-size: 36px;
  padding-top:20%;
  background-color: white;
}

