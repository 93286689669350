/* Compare */
.FamilySpace{
  .ScompareProjects{
    border:none;
    /*height: calc(100vh - 80px);
    overflow: auto;*/


    .ScompareHeader{
      /* background-color: $fc-whiteish; */
      height: 60px;
      display: flex;
      align-items: center;
      @media screen and (min-width: 576px) and (max-width: 822px){
        height: 100px;
      }
      @media screen and (max-width: 576px){
        height: 50px;
      }
      .title {

        @media screen and (max-width: 767px){
          width: 100%;
        }
      }
      .Add {
        padding: 10px 15px;
      }
      .LeftOvalAction {
        position: relative;
        // width:315px;
        // margin-left: auto;
        margin-left: 0;
        // padding-top: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px){
          width: 100%;
          margin-top: unset;
        }
        img {
          margin-left: 12px;
          margin-right: 0;
          width: 24px;
        }
        span{
          // text-align: center;
          // font-size: 18px;
          padding-left: 3px;
        }
      }
  }
  .ScompareBox {
    height: 79vh;
    margin-bottom: 70px;
    .CompareContainer{
      width: 100%;
      /* border: 1px solid #E6E6E6; */
      background: #FFFFFF;
      border-radius: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      padding-left: unset;
      padding-right: unset;
      height: calc( 100vh - 155px );
      @media screen and (max-width: 822px){
        height: calc( 100vh - 200px);
      }
      .noProjToCompare{
        width: 100%;
        position: absolute;
        top: 50%;
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        left: 50%;
        p{
          font-size: 20px;
          color: darkgrey;
          text-align: center;
        }
      }
    }
    .CompareContainer::-webkit-scrollbar {
      width: 5px;               /* width of the entire scrollbar */
      height: 5px;
    }
    .CompareContainer::-webkit-scrollbar-track {
      background: #F0F0F0;        /* color of the tracking area */
      border-radius: 10px;
    }
    .CompareContainer::-webkit-scrollbar-thumb {
      background-color: #E0E0E0;    /* color of the scroll thumb */
      border-radius: 10px;       /* roundness of the scroll thumb */
    }
    .row{
      .col{
        width: 25%;
        flex-basis: unset;
        flex-grow: unset;
        max-width: unset;
      }  
    }
    @include smartphone{min-width:unset}
    .SprojectItemInfo {
      // width: 500px; // 309
      height: fit-content;
      //min-width: 30%;
      max-width: 392px;
      /*margin-left: 5px;
      margin-right: 5px;*/
      /*@media screen and (max-width: 576px){margin-bottom: 100px;}*/
      font-family: "sukhumvit", arial, helvetica, sans-serif;
      p {
        font-size: 14px;
        font-style: italic;
        text-align: center;
        color: #707070;
      }
      padding: 0 2rem !important;
      @media screen and (max-width: 576px){
        min-width: unset;
        max-width: unset;
      }
      hr{margin-top: unset;margin-bottom: unset;}
      .Name{
        display: inherit;
        height: 45px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        //display: inline-block;
        .title{
          font-weight: normal;
          margin-top:5px;
          overflow: hidden;
          display: inline-block;
          padding-left: 5px;
          font-size: 27px;
          font-family: "sukhumvit", arial, helvetica, sans-serif;
        }
        .delCompProject {width: 24px;display: inline-block;float: right;height: 24px; cursor: pointer;margin:15px;}
      }
      img{width:100%;margin-left: 5%;margin:0px 0px 0px 10px;/*height: 400px*/;height: 200px; object-fit: contain; @media screen and(max-width: 576px){width:100%;margin:0;height: unset;}}
      .monumentImg{min-height: 200px;}
      .monumentLoading{
        min-height: 200px;
        @media screen and (max-width: 576px){
          background-size: 135px 75px;
        }
        z-index: 8;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
      .LeftOvalAction{
        text-align: center;
        width:100%;
        // padding-right: 10px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        img {
          width: 24px;
          height: 24px;
        }
        @include smartphone{display: block; margin-left:auto;margin-right:auto;}}
      .Topprice{font-size:16px;color:#707070;margin: 0px 0px 15px 5px;height: 64px;text-align:center;}
      table{color:$fc-medgrey; font-size:16px; line-height: 30px;
      svg{margin-right: 5px;}
        td{
          padding-left: 5px;
          border-top: none;
          border-bottom: 1px solid #dee2e6;
        }
      td.Tprice {border-left: 1px solid #E5E5E5;text-align: right;padding-right: 5px;width:70px; vertical-align:middle;}}
      .LoadingIcon{padding-top:unset;img{width:100px;height:unset;}}
      .clientInfo{
        font-size: 12px;
        color: #5F6365;
        font-weight: bold;
        line-height: 16px;
        width: 77.5%;
        padding-left: 5px;
        img{
          height: 60px;
          width: 100px;
          display: block;
          margin: 0 auto;
        }
        span{
          display: block;
        }
      }
    }
  }
}}
/* Compare box mode mobile */
.ScompareTable{
  border-top: 1px solid #DDD;
  border-left: 1px solid #DDD;
  border-right: 1px solid #DDD;
  vertical-align: middle;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  tr td{
    border: 1px solid #DDD;
    background-color: #FFF;
    padding: 4px;
    width: 33.33%;
  }
  thead tr th {
    background-color: #FFF;
    position: sticky;
    top: -1px;
  }
  th,td{
    width: 33.33vw;
  }
  .lineHeader{
    height: 35px;
    td{
      background: #F0F0F0 !important;
      color: #707070;
      font-size: 16px;
      div{color: unset}
    }
    .headerText{
      color: #2A3032 !important;
    }
  }
  .centerHeader{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    svg{
      margin-right: 10px;
    }
  }
  .like{
    font-size: 16px;
    height: 35px;
  }
  .detailsList{
    text-align: left;
    .detailsItem{
      vertical-align: unset;
      color: #707070;
      font-size: 11pt;
      .detailsPrice{
        font-weight: bold;
        font-size: 12pt;
        margin-bottom: 10px;
        margin-top: 5px;
      }
    }
    .mobilePrices{
      font-size: 13px;
      padding: 5px;
      .priceListColTitle{
        p{
          margin-bottom: 5px;
        }
      }
      .priceListColPrice{
        margin-bottom: 10px;
      }
    }
  }
  .coords{
    .clientInfo{
      font-size: 12px;
      color: #5F6365;
      font-weight: bold;
      line-height: 16px;
      padding-left: 5px;
      img{
        height: 60px;
        display: block;
        margin: 0;
      }
      span{
        display: block;
      }
    }
  }
}
.SheaderTable{
  width: 100%;
  tr td{
    border: unset;
    background-color: #FFF;
    padding: 0;
    width: 33.33%;
  }
  .w33{width: 33.33%;}
  .borderAround{
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
  }
  .titleContainer{
    padding: 10px;
    display: flex !important;
    justify-content: space-between !important;
    // border-bottom: 1px solid #DDD;
  }
  .projectTitle{
    font-size: 17px;
    font-weight: bold;
    color: #2A3032;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    td{
      border-bottom: 1px solid #DDD;
    }
  }
  .price{
    color: var(--fcnavyblue);
    border-bottom: 2px solid #DDD;
    .Topprice{
      font-size: 10pt;
      margin: 5px 0 10px 0;
    }
  }
  .LeftOvalAction {
    padding: 5px 10px 5px 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    float: unset;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    font-weight: 100;
    font-size: 16px;
  }
  .addBtn{
    cursor: pointer;
  }
  .addPlus{
    font-size: 35px;
    margin-bottom: -8px;
    color: var(--fcnavyblue);
  }
  .addText{
    font-size: 14px;
    padding: 0 10px 0 10px;
    color: var(--fcnavyblue);
  }
}
/* Select projects for compare (Modal) */
.SselectProjects {
  background:$fc-ivory;
  width:985px;
  @media screen and (max-width: 992px){width: 700px;}
  @media screen and (max-width: 767px){width: 500px;}
  @media screen and (max-width: 576px){width: 103%;border: unset;border-radius: unset;height: 100vh;/*margin-top: -32px;*/top: -5vh;padding-top: 25px;}
  @media screen and (max-width: 425px){
    width: 103%;
  }
  position:absolute;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  height: calc(100vh - 80px);
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 4%);

  .title{font-size:27px;color:$fc-deepgrey;overflow: hidden; white-space: nowrap; width: 100%; text-overflow: ellipsis;
  }
  .SselectHeader {
    margin-left: unset;
    margin-right: unset;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .title {
      line-height: 35px;
      //margin-bottom: 10px;
      width: unset;
      font-size: 22px;
      @media screen and(max-width: 576px){
        text-align: center;
        width: 100%;
      }
    }
    .subTitle{
      color: grey;
      font-size: 10pt;
      padding-left: 10px;
      @media screen and (max-width: 576px){
        width: 100%;
        padding-left: unset;
        //margin-top: -10px;
        text-align: center;
      }
    }
    .actions{
      width: 400px;
      .LeftOvalAction{
        width: 45%;
        margin-left: unset;
        padding: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 576px){
          width: 100%;
        }
      }
    }
  }
    .AllProjects{
      height: calc(100vh - 195px);
      overflow-y: auto;
      margin-left: unset;
      margin-right: unset;
      @media screen and (max-width: 576px){
        height: calc(100vh - 184px);
      }
    }
  .actions{
    margin-left:auto;
    @media screen and (max-width: 576px){
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      margin-left: -11px;
      height: 60px;
      position: absolute;
      bottom: 0;
    }
    .LeftOvalAction{
     @media screen and (max-width: 576px){
       width: 48%;
       margin: 5px;
     }
      &.Blue{
        @media screen and (max-width: 576px){
          margin-left: auto;
        }
      }
    }
  }
  .LeftOvalAction{width:206px;text-align: center;display: inline-block;@include smartphone{width:150px}}
  .Ssearch{
    margin:20px 15px 20px 15px;
    display: flex;
    align-items: center;
    input{
      width: 100%;
      border-color: #e6e6e6;
      border-radius: 35px;
      padding-left: 15px;
    }
    i {
      position: absolute;
      right: 35px;
      color: grey;
     }
  }
  .SprojectSelect{/*width:470px;*/border: 1px solid $fc-whitegrey;background:#FFFFFF;border-radius:10px;margin-bottom:16px;cursor: pointer;
    .SprojectHeader{
      /*padding-bottom:10px;*/
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    hr {
      margin-top: unset;
      margin-bottom: unset;
    }
    .dispImage {
      width: 100%;
      height: 100%;
      .CompareImage{
        text-align: center;
        width: 100%;
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .monumentImg{
        object-fit: contain;
        width: 95%;
        height: 100%;
        margin-left: 5%;
        justify-content: center;
        align-items: center;
        &.monumentLoading{
          background:url(../../images/icons-config-famille/loading.svg) center center no-repeat;
          background-size: 190px 110px;
        }
      }
      .LoadingIcon{img{height:100px}}
    }
    .SselectCheck{padding-left:20px;padding-right: 20px;}
    .Topprice {
      float: left;
      color: #8C8E8E;
      font-size: 15px;
      padding-left: 5px;
      padding-right: 5px;

      &::before{
        content:' -';
      }
    }
    .title{width: unset;font-size:18px; float: left;padding-top: 5px;padding-left: 5px;padding-bottom: 5px;}
    .greyCircle{width: 20px;height: 20px;border: 1px solid $fc-medgrey;border-radius: 10px;
                position: absolute;top:15px;}
    .blueCircle{width: 15px;height: 15px;background: var(--f-main-color);border-radius: 7px;
                 position: absolute;top: 18px;left: 39px;}
    }
    .AllProjects::-webkit-scrollbar {
      width: 5px;               /* width of the entire scrollbar */
      height: 5px;
    }
    .AllProjects::-webkit-scrollbar-track {
      background: #F0F0F0;        /* color of the tracking area */
      border-radius: 10px;
    }
    .AllProjects::-webkit-scrollbar-thumb {
      background-color: #E0E0E0;    /* color of the scroll thumb */
      border-radius: 10px;       /* roundness of the scroll thumb */
    }
  }

