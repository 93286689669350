.ConfigurationMonument {
  .AddDecoration{
    background: #21676e;
    border-color: #091e20;
    color: #fff;
  }
  .Tutorial {
    bottom: 0px;
    left: 70px;
    position: absolute;
    right: 70px;
    top: 70px;
    z-index: 150;

    &:before {
      background: $color-white;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 151;
      @include opacity(0.85);
    }

    p {
      color: $color-help;
      font: font-script(22, normal, 100%);
      margin: 15px;
      position: absolute;
      z-index: 9010;

      strong {
        display: block;
        font-weight: font-weight-script(bold);
      }
    }

    .Close {
      @extend %button-text;
      left: 50%;
      margin: -20px -130px;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 240px;
      z-index: 9010;
      @include button-text-colors(lighten($color-help, 10%), $color-white);
    }

    .NavBarHelp {
      background: transparent url("../../images/arrows/top-center.png") no-repeat left top;
      left: 15%;
      padding: 65px 0 0 65px;
      text-align: center;
      top: 0px;
      @include mdpi {
        background-image: url("../../images/arrows/top-center@2x.png");
        background-size: 58px 78px;
      }
    }

    .LeftButtonsHelp {
      background: transparent url("../../images/arrows/top-left.png") no-repeat left top;
      left: 0;
      padding: 80px 0 0 30px;
      text-align: left;
      top: 120px;
      @include mdpi {
        background-image: url("../../images/arrows/top-left@2x.png");
        background-size: 82px 70px;
      }
    }

    .View360Help {
      background: transparent url("../../images/arrows/top-right.png") no-repeat right top;
      padding: 8px 85px 0 0;
      right: 0;
      text-align: right;
      top: 15px;
      @include mdpi {
        background-image: url("../../images/arrows/top-right@2x.png");
        background-size: 78px 22px;
      }
    }

    .DocumentsHelp {
      background: transparent url("../../images/arrows/top-right-2.png") no-repeat right top;
      padding: 40px 115px 0 0;
      right: 0;
      text-align: right;
      top: 95px;
      @include mdpi {
        background-image: url("../../images/arrows/top-right-2@2x.png");
        background-size: 110px 42px;
      }
    }

    .DetailsHelp {
      background: transparent url("../../images/arrows/top-right-3.png") no-repeat right top;
      padding: 38px 80px 0 0;
      right: -45px;
      text-align: right;
      top: 230px;
      @include mdpi {
        background-image: url("../../images/arrows/top-right-3@2x.png");
        background-size: 70px 53px;
      }
    }

    .NextStepHelp {
      background: transparent url("../../images/arrows/bottom-right.png") no-repeat right bottom;
      bottom: 70px;
      padding: 0 100px 50px 0;
      right: 0;
      text-align: right;
      @include mdpi {
        background-image: url("../../images/arrows/bottom-right@2x.png");
        background-size: 86px 60px;
      }
    }

    .PreviousStepHelp {
      background: transparent url("../../images/arrows/bottom-left.png") no-repeat left bottom;
      bottom: 70px;
      left: 0;
      padding: 0 0 50px 85px;
      text-align: left;
      @include mdpi {
        background-image: url("../../images/arrows/bottom-left@2x.png");
        background-size: 78px 58px;
      }
    }

    ~ * {
      @include opacity(0.5);
      @include transition();

      &.ConfigurationIsoView {
        @include opacity(1);
      }

      &:hover {
        @include opacity(1);
      }
    }
  }

  .OpenHelp {
    @include smartphone {
      display: none;
    }
    .SquareButton {
      height: 50px;
      width: 50px;
      @include square-button-colors($color-purple);

      .icon {
        line-height: 50px;
        margin: 0;
        width: 50px;
      }
    }
  }

  .OptionPanelPopup {
    margin-bottom: -1px;
    margin-top: -1px;
    position: absolute;
    display: flex;
    top:0;
    &.show {
      left: 157px;
      @include smartphone {
        left: 110px;
      }
    }

    label.title  {
      color: var(--text-dark);
      font-family: Raleway;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
      width: max-content;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      &:not(:first-child) {
        margin-top: 0.9375rem;
      }
    }

    select {
      border: 0;
    }
  }

  .HeadstoneOptionPanel {

    .SquareButton {
      @include square-button-colors($color-bluegreen);
    }
  }

  .LayoutOptionPanel {

    .OptionPanelPopup {
      // top: 224px;
      @include smartphone {
        // top: 70px!important;
      }
      &:before, &:after {
        // top: 35px;
      }

      border-radius: 10px;
      .OptionPanelSquareItem  {
        &:first-child:last-child {
          border-radius: 10px 10px 10px 10px;
        }
        &:first-child:not(:last-child) {
          border-radius: 10px 0px 0px 10px;
        }
        &:last-child:not(:first-child) {
          border-radius: 0px 10px 10px 0px;
        }
      }
    }
  }

  .BaseHeightOptionPanel {

    .OptionPanelPopup {
      border-radius: 10px;
      // top: 332px;
      @include smartphone {
        // top: 210px;
      }

      .OptionPanelSquareItem {
        &:first-child:last-child {
          border-radius: 10px 10px 10px 10px;
        }
        &:first-child:not(:last-child) {
          border-radius: 10px 0px 0px 10px;
        }
        &:last-child:not(:first-child) {
          border-radius: 0px 10px 10px 0px;
        }
        .icon {
          @include material-size(18px);
        }
      }
    }
  }



  .FrameOptionPanel {
    $standardTop: -60px;
    $customTop: -158px; 
    &.hasContent {
      &.standard .OptionPanelPopup {
        top: $standardTop; 
      }
      &.custom .OptionPanelPopup {
        top: $customTop;
      }
    }
  
    &:not(.hasContent) {
      .OptionPanelPopup {
        top: 0;        
      }
    }

    .OptionPanelPopup {
      // top: 440px;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
      border: none !important;
      border-radius: 10px;
      @media screen and (max-width:576px){
        top: 140px;
        left: 60px;
      }
      // width: 376px;
      padding: 9px;

      @media screen and (max-width: 490px) {
        top: 140px;
      }

      &:before, &:after {
        top: 46% !important;
        @include smartphone {
          top: 25px;
        }
      }

      select {
        width: 380px;
        max-width: 100%;
      }
      select, label, span, input {
        color: unset !important;
      }
      input {
        border-color: var(--secondary2);
      }
      .Label {
        // font-size: 10px !important;
        margin-top: 6px !important;
      }
    }

    &.mode {

      .OptionPanelPopup {
        // top: 440px;
        display: block;
        &:before, &:after {
          top: 60px;
        }
        @include smartphone {
          top: 140px;
          &:before, &:after {
            top: 25px;
          }
        }
      }
    }

    .CustomProperty {
      margin-bottom: 5px;
      // @include column(left);
      .Label {
        // color: $color-grey-6;
        // display: block;
        // font: font-default(13, normal, 150%);
        // text-align: center;
        // text-transform: uppercase;
        // width: 65px;
        // margin-top: 3px;
        color: var(--secondary-2);
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        @include smartphone {
          font-size: 12px;
        }
      }

      input[type=number] {
        width: 45px;
        box-sizing: unset;
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .Unit {
        color: $color-grey-6;
        font: font-default(18, normal, 36px);
        padding: 0 0.75em 0 0.25em;
      }

      &.Heights {

        .Label {
          width: 100%;
        }

        input[type=number] {
          width: 55px;
        }

        .Unit {
          padding: 0 0.25em;
        }
      }
    }

    label.radio {
      margin-right: 1em;
      @include column(left);
    }
    .TableFramePieces{font: font-default(14, normal, 150%);color: $color-grey-6;text-align: center;}
  }

  .VeneerOptionPanel {
    $veneer-input-height: 38px;
    $veneer-input-color: $color-grey-9;
    .IconView{width:25px;margin-top: -10px;
    }
    .ButtonValue{ border-radius: 22.5px;
      background-color: #149edc;
      border: 1px solid transparent;
      color: white;
      padding: 0;
      height: 25px !important;
      padding-top: 2px;
      margin-left: 2px;
      height: 20px;
      font-size: 0.9em;
      width: 70px;
      @include smartphone {
        font-size: 0.7em;
        width: 50px;
      }
    }

    .OptionPanelPopup  {
      // width: 320px;
      height: auto;
      display: block;
      border-radius: 10px;
      &.isveneer{
        left: 50% ;
        right: 50% ;
        transform: translate(-50%, 0%);
        @include smartphone {
          transform: translate(0%, 0%);

        }
        &.Step {
          top: 84px;
          // background: white;
        }
        .FootVeneer {
            width: 100%!important;
            border-radius: 0 0 3px 3px;
            background-color: #E1E2EA;
            text-align: center;
            padding-bottom: 15px;
            display: flex;
        }
        .TitleStepVeneer {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: #ffffff!important;
            color: #131313 !important;
            padding: 20px;
        }
        .VeneerDisplay{
          position: relative !important;
          @include smartphone {
            transform: scale(0.5) translate(-40%, -35%);
            height: 160px;
            .AddRemoveButton {
              transform: scale(2);
            }
          }
          height: 544px !important;
        }
      }
      &.Step{
        top: 84px;
        width: 700px;
        position: fixed;
        left: 155px;
        @include smartphone {
          position: fixed;
          left: 5%;
          z-index: 99999;
          width: 90%;
        }
        &:before, &:after {
          top: 420px;
          opacity: 0;
        }
      }

      &.NoStep {
        &:before, &:after {
          top: 30px;
          @include smartphone {
            top: 25px;
          }
        }
        .Actions {
          display: flex;
          flex-direction: column;
          button {
            height: 32px;
            margin: 0;
            border-radius: 0;
          }
          &.VeneerActions {
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
          }
        }
      }

      .StepVeneer {
        // background-color: #dddddd;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        width: 100%;
        > * {
          width: 100%;
          max-width: 100%;
        }
      }
      .TitleStepVeneer{
        font-family: Raleway;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 700px;
        // background-color: #303030;
        color: #FFFFFF;
        padding: 20px;

        @include smartphone {
          .Titles {
            margin-bottom: 0.5rem;
          }
        }
        .BigTitle{
          font-size: 1rem;
          font-weight: 600;
          @include smartphone {
            font-size: 1.8rem;
          }
          line-height: 27px;
          // padding-top: 22px;
        }
        .SmallTitle{
          // top: 90px;
          margin-top: 8px;
          color: var(--text-dark);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .ButtonStepVeneer{
        padding-right: 20px;
        padding-left: 20px;
        border-radius: 3px;
        background-color: #129EDB;
        font-size: 17px;
        color: #FFFFFF;
        float: right;
        @include smartphone {
          float: none;
        }
        // margin-right: 30px;
        // margin-top: 31px;
        text-transform: none;
      }
      .ButtonDefVeneer{
        border-radius: 3px;
        background-color: #129edb99;
        font-size: 14px;
        color: #FFFFFF;
        float: right;
        text-transform: none;
        @include smartphone {
          float: none;
        }
        // margin-right: 30px;
        // margin-top: 31px;
      }
      .FootVeneer{
        width: 700px;
        border-radius: 0 0 3px 3px;
        // background-color: #E1E2EA;
        text-align: center;
        font-family: Raleway;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-around;

        @include smartphone {
          flex-direction: column;
          margin-top: 90px;
        }

        .TwoChoiceButton{
          button{
            color: var(--text-medium);
            font-size: 12px;
            height: $veneer-input-height;
            width: 88px;
            border-radius: 3px 0 0 3px;
            text-transform: none;
            @include smartphone {
              margin-top: 0;
            }
            &.active {
              background: var(--secondary1);
              color: var(--text-cta);
            }
          }
          &.Front{left:23px
          }
          &.Back{left:500px
          }
        }
        // .Thickness{width:175px;margin-top:30px;margin-left:207px;margin-left: 22px;float: left;
        .Thickness{
          .PlusMinus{
            display: flex;
            justify-content: center;
            .Minus { border-radius: 4px 0px 0px 4px; }
            .Plus { border-radius: 0px 4px 4px 0px; }
            .Minus,.Plus{
              height:$veneer-input-height - 1;
              width:$veneer-input-height;
              font-size: 17px;
              text-align: center;
              cursor: pointer;
              padding:0px;
              border: 0;
              background: var(--secondary1);
              font-weight: 700;
              color: var(--text-cta);
            }
          }
        }
        label{
          // color: #303030;
          font-size: 15px;
        }
        .SpanInputBox{
          // border: 1px solid #CCCCCC;
          font-size: 17px;
          display: inline-flex;
          color: var(--text-cta);
          margin-top: -2px;
          span {margin-top: 7px;}
        }
        .InputBox{
          height: $veneer-input-height - 3;
          width: 40px;
          font-size: 17px;
          padding: 0px;
          margin: 0px;
          border: 0px;
          text-align: right;
          background: none;
          color: var(--text-cta);
          margin-right: 5px;
          &:after{
            padding-right: 10px
          }
        }

        .TwoChoiceButton, .Thickness {
          // width: 33%;
          margin-top: 8px;
          @include smartphone {
            width: 100%;
            > div {
              width: 100%;
              display: flex;
              justify-content: center;
            }
            label {
              font-size: 1.5rem;
            }
          }
        }
      }
      .VeneerDisplay{
        height: 400px;
        border-top: 1px solid var(--background-navcards);
        border-bottom: 1px solid var(--background-navcards);
        @include smartphone {
          transform: scale(0.5) translate(-45%, -100%);
          height: 160px;
          .AddRemoveButton {
            transform: scale(2);
          }
        }
        .ErrorText{color:#E2634A;font-size: 15px;width:300px;line-height: 15px;position: absolute;
        }
        button{	height: 25px;	width: 25px;border:none;z-index: 40;
          padding:0;border-radius: 50%;color:#fff;font-weight: bold;font-size:20px;
          &.Add{background:#149edc;&:after{content:"+"}
        }
        &.Remove{background:#ABABAB;&:after{content:"-"}
      }
      &.back{
        position:absolute; left:506px; top:174px;
      }
      &.front{
        position:absolute; left:164px; top:394px;z-index: 200;
      }
      &.left{
        position:absolute; left:246px; top:194px;
      }
      &.right{
        position:absolute; left:433px; top:364px;z-index: 200;
      }
    }
    .BackImage,.FrontImage{
      position:absolute;
      width:159px;

      .SpanInputBox{
        position: absolute;
        top: 60px;
        left: 40px;
        -moz-transform: rotate(30deg);
        -webkit-transform: rotate(30deg);
        @include smartphone {
          transform: rotate(30deg) scale(2);
        }
      }
    }
    .BackImage{left:394px; top:165px;&.Closoir{z-index: 20;
    }
  }
  .FrontImage{left:142px; top:283px;&.Parpaing{z-index: 50;
  }
}
.LeftImage,.RightImage{
  position:absolute;
  width:293px;
  .SpanInputBox{
    position: absolute;
    top: 86px;
    left: 130px;
    -moz-transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
    @include smartphone {
      transform: rotate(-25deg) scale(2);
    }
  }
}
.LeftImage{
  left:142px;
  top:165px;
  .SpanInputBox{z-index: 200;
  }
}
.RightImage{
  left:260px;
  top:231px;
  &.ClosoirAvant,&.Long{z-index:60;
  }
  &.ClosoirArriere{z-index:40;
  }
}
.SpanInputBox{
  font-size: 17px;
  display:inline-block;
  color: #FFFFFF;
}
.InputBox{
  width:45px;
  font-size: 17px;
  padding:0px;
  margin:0px;
  border:none;
  text-align: center;
  background:none;
  color:#fff;
  height:30px;
  margin-top:-5px;

  &.Height{
    color: #E2634A;padding-top: 12px;padding-left: 8px;
  }
}
.SpanHeightBox{
  font-size: 17px;
  display:inline-block;
  color: #E2634A;
  height: 40px;
  width: 77px;
  border-radius: 3px;
  background-color: #F9E0DB;
  @include smartphone {
    transform: scale(1.5);
  }
  &.rightfront,&.frontright{
    position:relative;top: 10px;
  }
  &.rightfront{left: -20px;
  }
  &.frontright{left: 25px;
  }
  &.rightback,&.backright{
    position:relative}
    &.backright{
      top: -56px;
      left: 107px;
      @include smartphone {
        left: 117px;
      }
    }
    &.rightback{
      top: -174px;
      left: 240px;
      @include smartphone {
        left: 250px;
      }
    }

    &.backleft,&.leftback{
      position:relative}
      &.backleft{left:-16px;top:-183px;
      }
      &.leftback{left:165px;top:-235px;
      }
      &.leftfront,&.frontleft{
        position:relative;
        left: -80px;
        @include smartphone {
          left: -110px;
        }
      }
      &.frontleft{
        top:-120px;
      }
      &.leftfront{
        top:-55px
      }
    }

  }
  .VeneerAction{
    height: 100%;
    width: 320px;
    // border: 1px solid var(--secondary2);
    //border-radius: 3px;
    text-align: center;
    font-family: 'Raleway';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 0;
    margin: 0;
    -webkit-transition: all 0s ease;
    -webkit-transition-delay: 0s;
    transition: all 0s ease false;

    // background-color: #FFFFFF;
    background: unset;
    @include smartphone {
      height: 50px;
      width: auto;
    }
    &.secondary3-hover:hover {
      // background: $color-blue;
      color: var(--text-light) !important;
    }

    &.add-veneer {
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
      border: none !important;
      border-radius: 10px !important;
      &:hover {
        color: var(--text-light) !important;
      }
    }
  }

  .Field {
    display: inline-block;
    padding: 0 5px;
    position: relative;
    z-index: 150;

    .Label {
      color: $veneer-input-color;
      display: block;
      font: font-default(13, normal, 150%);
      text-align: center;
      text-transform: uppercase;
    }

    &.Length,
    &.Thickness {
      .Input {
        border-width: 2px;
      }
    }

    &.filled {

      .Input {
        border-color: $color-orange;
      }
    }

    &.selected {

      .Input {
        border-color: $color-blue;
      }
    }

    &.focused {

      .Input {
        background-color: $color-blue;
        border-color: $color-blue;
        color: $color-white;
      }

      .Label {
        color: $color-grey-6;
      }
    }
  }
}
}
}

.HeadstonePicker {
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 80px;

  .ListViewItem {
    // width: column-width(4);
  }

  .Warning {
    color: var(--text-dark);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 2.5%;
    margin-top: 20px;
  }

  .HeadstoneCard {
    .Star {
      color: #b79d00;
      // float: right;
      // font-size: 1.4em;
      // margin: -3px 0 0 3px;
    }
  }

  .MoreHeadstones {
    // margin: 10px;
    // @include column(left);
    // @include button-text-colors($color-blue, $color-white);
  }

}
