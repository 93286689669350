.ConfigurationAccessories {
  .Footer > .button-navigation {
    &.bottom50 {
      bottom: 50px;
    }

    &.bottom58 {
      bottom: 58px;
    }
  }
  
  .AddDecoration {
    @include square-button-colors($color-bluegreen);
  }
}

.AccessoriesZone {
  background: $color-white;
  bottom: 0;
  left: 0;
  padding-bottom: 70px;
  position: absolute;
  right: 0;
  top: 70px;
  .Footer {
    // background: $color-grey-E;
    background-color: var(--secondary2-hover);
    border-top: 1px solid $color-grey-D;
    bottom: 0;
    height: 70px;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 900;
    .FullSize {
      bottom: 70px;
    }
    .Infos {
      @include column(left);
      margin: 15px;
    }
    .Actions {
      // @include column(right);
      display: flex;
      margin: ((70px - 36px) / 2) 14px;
      a, button {
        margin: 0 1px;
      }
    }
    .EditAccessoryGranite {
      @include button-text-colors($color-blue, $color-white);
    }
  }
}

.Accessory {
  h1 {
    @extend %reset;
    font: font-default(16, normal);
  }
  .Reference {
    color: var(--text-light);
  }
  .Kind {
    @extend %reset;
    // color: $color-grey-9;
    color: var(--text-light);
    display: inline;
    font-size: font-size(1400%/16);
    margin-left: 0.5em;
    text-transform: uppercase;
  }
  .Description {
    @extend %reset, %text-overflow;
    // color: $color-grey-3;
    color: var(--text-light);
    font: font-default(20);
    max-width: 30em;
  }
}

.AccessoryCard {
  /*
  min-height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  */
  @extend %list-item;
  background: #fff;
  border: none;
  margin: 0;
  text-decoration: none;
  box-shadow: none !important;
  .Infos {
    /*
    left: 0;
    padding: 10px;
    position: absolute;
    text-align: left;
    top: 0;
    @include single-transition();
    z-index: 700;
    */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 10px;
  }
  .Description {
    @extend %reset;
    color: var(--text-dark);
    display: block;
    font: font-default(16);
  }
  .Reference {
    @extend %reset;
    color: $color-red;
    display: block;
    font: font-default(16);
    font-variant-numeric: lining-nums proportional-nums;
    text-transform: uppercase;
  }

  @include smartphone {
    figure > img {
      /* margin-top: 50%; */
    }
  }
}
