body.ReactModal__Body--open {
  // position: absolute;
  overflow: hidden;

  /* #gpgConfigurator3d {
    .Catalog,
    .Configuration .Content {
      //Un peu lent... @include filter(blur(4px));
    }
  } */
}

.ReactModalPortal {

  .border-left-top {
    border-left: 0.0006rem solid #CED4DA;
  } 

  @media (max-width: 768px) {
    .border-left-top {
      border-top: 0.0006rem solid #CED4DA;
      border-left: unset;

      padding-top: 1rem;
      margin-top: 0.625rem;
    }
  }

  .required:after {content:" *";color:#991B1B;}

  .monumentImg-compare {
    // width: 80%;
    height: 80%;
  }
  .ReactModal__Overlay {
    //background: rgba($color-white, 0.8);
    background: #2a30327a 0% 0% no-repeat padding-box;
    bottom: 0;
    left: 0;
    @include perspective(2000px);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    @media screen and (max-width: 576px){
      top: 0;
    }
    &.familyspace-login-overlay{
      position: fixed;
      top: 0;
      @media screen and (max-width: 576px){top: 77px;}
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      .ReactModal__Content {
        padding: 25px;
        border: 1px solid #F4F4F4;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 10px;
        width: 600px !important;
        @media screen and (max-width: 576px) {
          width: 100% !important;
          height: 100% !important;
          border-radius: unset !important;
          border: none;
        }
        /*@media screen and (max-width: 490px){
          top: 78px;
        }*/
        &.DocumentsModal{
          border-radius: 0;
        }
      }
    }
  }
  .ReactModal__Content {
    border-radius: 15px;
    bottom: 10px;
    left: 10px;
    margin: 0;
    outline: 0;
    overflow: visible;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1010;
    background-color: #FFF;
    &.fit {
      margin: auto!important;
      height: fit-content;
      width: fit-content;

      img {
        max-height: 500px;
        max-width: 500px;
        margin: 50px 20px 20px 20px;
        @media screen and (max-width: 576px){
          width: 300px;
        }
      }
    }
  }
  div.CloseModal {
    color: $color-grey-6;
    cursor: pointer;
    font: font-default(16, normal, 100%);
    padding: 10px;
    position: absolute;
    right: 0;
    @include single-transition();
    text-decoration: none;
    text-transform: uppercase;
    top: 0;
    vertical-align: middle;
    z-index: 3000;
    span {
      vertical-align: middle;
    }
    .icon {
      @include material-size(24px, 36px);
      vertical-align: middle;
    }
    &:hover {
      color: $color-grey-3;
    }
  }

  span.open-tutorial {
    background-color: $color-grey-6;
    position: absolute;
    right: 60px;
    top: 16px;
    cursor: pointer;
    z-index: 3000;
  }

  .Pdf div.CloseModal {
    color: white;
    margin-top: 50px;
    border-radius: 50px;
    background-color: #C0392B;
    margin-right: 22px;
    &:hover{
      background-color: #7F8C8D;
    }
  }
  .see-more-combinations {
    @extend %reset;
    color: var(--text-dark);
    font: font-default(20, normal, 40px);
    padding: 10px 30px;
    margin-right: 24px;
    // position: absolute;
    // align-self: center;
    // bottom: 0;
    // width: 100%;
    // left: 0;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    @include smartphone {
      color: var(--text-light);
    }
  }
  .Header {
    position: relative;
    z-index: 2000;
    display: flex;
    &::after {
      content: '';
      border-bottom: 1px solid #EAEEF2;
      width: 96%;
      position: absolute;
      bottom: 0;
      left: 2%;
      right: 2%;
    }
    .buttonsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
    }
    .HeaderContainer {
      padding-left: 30px;
      padding-right: 15px;
      padding-top: 15px;
      padding-bottom: 25px;
      @include smartphone {
        padding-bottom: 10px;
        padding-left: 15px;
        .buttons-group {
          flex-direction: column;
          button {
            border-radius: 0;
          }
        }
      }
    }
    .maxSpan{
      display: flex;
      align-items: center;
      font-weight: normal;
      justify-content: flex-start;
      font-size: 12pt;
      color: var(--text-cta);
      margin-left: 30px;
    }
    h1 {
      @extend %reset;
      color: var(--text-dark);
      font: font-default(20, normal, 40px);
      padding: 10px 30px;
      margin-right: 24px;
      font-weight: 600;
      font-size: 18px;
      @include smartphone {
        color: var(--text-light);
      }
    }
    + .ScrollListView {
      top: 109px;
      @include smartphone {
        top: 145px;
        padding: 0;
      }
    }
  }

  // .accessory-type-buttons {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-top: 12px;
  //   button.kindButton {
  //     width: 220px;
  //     color: var(--text-medium);
  //     // margin-left: 10px;
  //     // margin-right: 10px;
  //     // border-radius: 10px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     border: 1px solid #c8c8c8;
  //     border-radius: 0;
  //     i.icon {
  //       width: unset;
  //       display: inline-flex;
  //       margin: unset;
  //     }
  //     span{
  //       margin-left: 10px;
  //     }
  //     &.selectedKind {
  //       background-color: var(--secondary2);
  //       color: var(--text-light);
  //     }
  //     &:first-child {
  //       border-radius: 6px 0 0 6px;
  //     }
  //     &:last-child {
  //       border-radius: 0 6px 6px 0;
  //     }
  //   }
  // }

  .ScrollListView.AccessoryPicker {
    top: 164px;
    @include smartphone {
      top: 180px;
    }
    .ListViewContainer {
      .ListViewItem {
        .LazyImageB {
          img {
            min-height: 215px;
          }
        }
      }
    }
  }

  .Footer {
    @extend %columns;
    background: var(--background-page);
    bottom: 0;
    height: 70px;
    @media screen and (max-width: 1113px) {
      height: unset;
      display: flex;
      flex-direction: column;
    }
    left: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 2000;
    display: flex;
    justify-content: space-between;
    .Actions {
      // @include column(right);
      @media screen and (max-width: 1113px) {
        margin-top: 0;
        // @include column(left);
        > * {
          &.FavoriteStar {
            margin-bottom: 0.2rem;
          }
          width: 100%;
        }
      }
      margin: ((70px - 36px) / 2) 14px;
      a, button {
        margin: 0 1px;
      }
      @media screen and (max-width: 1113px) {
        .SearchPatterns {
          margin-bottom: 8px;
        }
      }
    }

    .Infos {
      margin-top: 0.4375rem !important;
      .Description {
        font-size: 1.7em;
      }

      h1 .PatternSize {
        font-size: 1rem;
      }
    }
  }
  .Scrollable {
    .Header {
      height: 70px;
      position: absolute;
      width: 100%;
      .button-group {
        margin: 2px;
      }
      + .ListView {
        top: 71px;
      }
    }
  }
  .NoGranit {
    font-size: 20px;
    padding: 20px;
  }
}

.custom-fin-modal {

  @media (min-width: 768px) {
    :has(&) {
      &.ReactModal__Content {
        bottom: unset;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.custom-fin-modal {

  @media (min-width: 768px) {
    :has(&) {
      &.ReactModal__Content {
        bottom: unset;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}


.blurBackground, .modal,.familyspace-member-name-modal-overlay {
  backdrop-filter: blur(4px) !important;
}
.ModalElemConfirm {
  .modal-content {
    border-radius: 0;
    color: #757575;
  }
  .header {
    // text-transform: uppercase;
    font: font-default(18, normal, 20px);
    // color: #303030;
    margin: auto;
    border-bottom: 1px solid #abacb2;
    background: #ededed;
    padding: 20px;

    .modal-title {
      color: #1997e5;
      margin: 0;
      font-size: 1.5em;
      font-weight: normal;
    }
    .close {
      float: inherti;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
  }

  .content {
    // text-transform: uppercase;
    font: font-default(16, normal, 20px);
    color: #757575;
    margin: auto;
  }

  .modal-footer {
    justify-content: flex-start;
    border-top: 1px solid #abacb2;
  }

  .modal-footer span {
    // font-size: 2rem;
    font: font-default(16, normal, 20px);
    text-transform: uppercase;
    background: #1997E5;
    color: #fff;
    cursor: pointer;
    padding: 9px;
  }

  .modal-footer span.cancel {
    background: #ededed;
    color: #929298;
  }
}

.ReactModalPortal .ModalElemConfirm {
  width: 30%;
  height: 20%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  .ElemConfirm {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 5000;
    display: none;
    text-align: center;

    .content {
      color: #fff;
      margin: auto;
    }
    display: flex;
  }

  .CloseModal {
    padding: 0;
  }

  .buttons span {
    background: #212121;
    color: #fff;
    padding: 5px 7px;
    border-radius: 2px;
    cursor: pointer;
  }

  .buttons span:hover {
    background: #ccc;
  }
}


.blurBackground, .modal .familyspace-member-name-modal-overlay {
  backdrop-filter: blur(4px) !important;
}

.modal{
  background: rgba(0, 0, 0, 0.6) !important;
}
