$fc-deepgrey: #2A3032;
$fc-darkgrey: #3B4752;
$fc-grey: #667481;
$fc-medgrey: #707070;
$fc-greyish: #7E7E83;
$fc-lightgrey: #8C8E8E;
$fc-ivory: #F4F4F4;
$fc-whitegrey: #E6E6E6;
$fc-whiteish: #F0F0F0;
$fc-blackish: #0e0e0f;
$fc-blueish: #0094EE;
.Family{
  .UpdateNotification{
    width:0px;padding:0px;
    border:none;border-radius: 10px;
    color: #FFFFFF;box-shadow: 0px 0px 20px #0000004D;
   
    .FamilyUpdate {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
      border-radius: 8px !important;
      padding:20px;
      text-align:center;
      background: #FFFFFF; 
      position:fixed;
      bottom:5px;
      @include smartphone{padding:5px;right:5px}
      .LeftOvalAction.BlueA {
        float: unset;
        /* background-color: var(--fcorange); */
        background-color: var(--f-main-color);
        /* color: var(--fcdarkstlblue); */
        color: var(--f-font-color);
        font-size: 16px;
        font-weight: bold;
        font-family: $RalewayFont;
        display: block;
        @include smartphone{font-size:14px}
      }
      .closeIcon{
        cursor:pointer;
        position:absolute;
        right:9px;
        top:9px;
      }
      .title {
        font-family: $RalewayFont;
        color: var(--fcdarkstlblue);
        font-size:16px;
        font-weight: bold;
        text-transform: capitalize;
        padding-bottom: 11px;
        @include smartphone{font-size:14px}
        img {
          // width: 2.3em;
          padding-right:8px
        }
      }
      .text {
        font-size:17px;
        font-style:italic;
        padding-bottom: 15px;
      }
    }
  }
  .fpriceinfo{
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 0 20px;
    margin: -10px 0;
    p.text{
      width: 500px;
      font-size: 12px;
      color: $fc-grey;
      font-style: italic;
    }
  }
    .ScrollListView{
      top: 120px;
      background-color: white;
      @include smartphone{top:unset;position: relative;}
    }
      .ListView{@include smartphone{position: relative;}}
      .Configuration{
        padding: 17px 0;
        @include smartphone {padding:unset}
    }
    .ListView .ListViewItem {@include smartphone{width:90%}}
    button{text-transform:none}
    .LazyImage.hover img{opacity: 0.6;}
    figure.LazyImage:before {@include smartphone{z-index:5}}
    .TitrePage {position: absolute;font-size: 24px;color:$fc-darkgrey;
                margin-left: 20px;margin-top: 5px;}
    .Featured{display:none}
    figure.Monument {&.FullSize {img {@include transform(scale(1.0) translate(6%, 1.5%));right:20px;
                                      @include smartphone{margin-top: 30px;}}}}
    .Close{font-style: italic;color:$fc-lightgrey;font-size: 15px;
        position:absolute;right:15px;top:13px;cursor: pointer;
        .icon{font-weight: bold;position: relative;top: 7px;} }

.FabricActionBar .ButtonFabricFamily{background: #F3F3F3 ;box-shadow: 0px 0px 10px #00000029;
                                width: 44px;height: 43px;border-radius: unset;}

.SocialBar{width:280px;height:98px;background:#EFEFEF;top:20px;margin-top: 20px;left:300px;position:relative;z-index:2000;
  @include smartphone{position:fixed;top: 172px;background: none;left: 80px;height: 55px;}
    .FacebookLogo{margin-left:30px;margin-right:20px;}
    .EmailLogo{margin-left:20px;margin-right:20px}
    .WhatsAppLogo{margin-left:20px;}
    .ShareEmailButton{background-color: transparent;border: none;padding: 0px;cursor: pointer;}
    .SocialLogoTitle{margin-top:10px;font-size: 16px;color:$fc-darkgrey;
      @include smartphone{display:none}}


}
.alert-success, .alert-danger{ position: fixed;top: 25%;left: 25%;font-size: 16px;}
}
.ManualMailModal{
  font-family: $sukhumvitFont;
  .modal-header{background:$fc-darkgrey;color:white;.close{color:white;font-size:32px}}
  .modal-title{font-size: 16pt; font-weight: bold;}
  .modal-content{
    width:800px;
    margin-top: 200px;
    @media screen and (max-width:768px){
      width: 100%;
      margin-top: unset;
    }
  }
  .toolbar{display:none}
  .ManualMailForm{
    margin-left: 30px;
    margin-top:10px;
    width:95%;
    @media screen and (max-width: 768px){
      margin-left: 2px;
      width: 100%;
    }
    .form-group{
      .row{
        input{
          @media screen and (max-width:768px){
            width: 95% !important;
          }
        }
      }
      .react-multi-email{
        /* @media screen and (max-width:768px){
          width: 98%;
        } */
        border: unset;
        padding-left: unset;
        padding-right: unset;
        input{
          border: 1px solid #E4E6F0;
          border-radius: 2px;
          margin-right: unset;
        }
      }
    }
    .public-DraftEditor-content {min-height: 110px;}
    .required:after {content:" *";color:#a51522;}
    input{
      font-size:14px;
      margin-bottom:5px;
      width:100%;

      background: #F5F6FC 0% 0% no-repeat padding-box;
      border: 1px solid #E4E6F0;
      border-radius: 2px;
      margin-top: 5px;
      /* margin-left: 5px;
      margin-right:5px */}
    .react-multi-email [data-tag]{font-size:14px}
    .LargeInput{width:400px}
    label{color: $fc-darkgrey;font-size:16px;margin-top: 10px;}
    .ManualMailSend{
      background: var(--fcorange);
      color: white;
      display: block;
      font-size:16px;
      font-weight:bold;
      border-radius: 50px;
      text-transform: none;
      margin: 10px;
      padding-left: 15px;
      padding-right: 15px;
      border: unset;
      cursor: pointer;
      &:hover:enabled{
        background-color: var(--fcdarkstlblue);
      }
    }
    .invalid-feedback {font-size:12px}
    .MailTextEditor{
      margin-left: -20px;
      @media screen and (max-width:768px){
        margin-left: 0 !important;
        width: 98%;
        .RichTextEditor__root___2QXK-{
          max-height: 190px;
        }
      }
    }
    .ButtonWrapper{ display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .multi-email, .multi-email.focused {
    background: #F5F6FC 0% 0% no-repeat padding-box !important;
    border: 1px solid #E4E6F0 !important;
    padding: 0 0 0 5px !important;
    min-height: 150px;
    input{
      width: 300px !important;
      border: 0 !important;
    }
  }

  .successMsg {
    color: green !important
  }
}

.flexible-modal {
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    background: white;
  }
  .flexible-modal-drag-area{
    height: 50px;
    position:absolute;
    right:0;
    top:0;
    cursor:move;
  }
  @mixin NextPrevious {
        background: #FFFFFF;color:var(--fcblue);border: 1px solid $fc-medgrey;
        .material-icons{font-weight: bold}
  }

.hide-element{
  display: none;
}
