@mixin getColor($color){
    border-radius: 20px;
    border: 0.5px solid #223554;
    margin-left: 7px;
    width: 30px;
    height: 30px;
}
.fGraniteColorsContainer{
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 70px;
  @media screen and (max-width:500px){
    flex-direction: column-reverse;
  }
  .goToInitChoiceBtn{
    width: unset;
    top: unset;
    right: 0;
    margin: 0;
    z-index: 999999;
  }
}
.FfilterCatalog.GraniteColors {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  z-index: 999999;
  .fFilterGraniteContainer{
    display: flex !important;
    z-index: 1;
    position: relative;
    .flex-100{
      span{
        @media screen and (max-width: 400px){
          display: block;
        }
      }
    }
    ._FfilterGranit{
      display: flex;
      margin-right: 12px;
      .wrapper .search-icon {
        height: 24px;
        width: 24px;
      }
      @media screen and (max-width: 1270px){
        margin-bottom: 10px;
        justify-content: center;
      }
    }
    @media screen and (max-width: 1270px) {
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      .reset-filter {
        margin-top: 10px;
      }
    }
  }
  .flex-25{
    flex: 25%;
  }
  .flex-start{
    align-self: flex-start;
  }
  .flex-75{
    flex: 75%;
  }
  .flex-50{
    flex: 50%;
  }
  .flex-100{
    flex: 100%;
    display: block !important;
    margin: 25px 0 0;
  }
  
  .TitrePage {
    margin-top: 0px;
  }
  .AllColors {
    height: 30px;
    background: var(--fcblue);
    border-radius: 30px;
    color: #ffffff;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
    margin-left: 20px;
    font-family: $sukhumvitFont;
  }
  .matchingColors {
    position: absolute;
    display: flex;
    top: 100%;
    margin-top: 8px;
    // @media screen and (min-width: 1270px) {
    //   left: 36%;
    // }
    min-width: calc(100% + 20px);
    
    box-shadow: #a3a3a36b 0px 1px 1px 1px;
    border-radius: 8px;
    z-index: 3399;
    background: white;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    .secondaryColor{
      button{
        margin-bottom: 2px;
      }
    }
    .mainColor{
      button{
        margin-top: 2px;
      }
    }
  }
  .colorFilter {
    font-family: $sukhumvitFont;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 2.5px;
    margin-left: 2.5px;
    border-radius: 0;
    text-transform: capitalize;
    &.extrColor{
        @include getColor("white");
        :active {
            background-color: yellow;
            border: none !important;
        }
        .dGbqhr > input{
          text-transform: uppercase;
        }
        .sc-bwzfXH.bWZouD > ul > li{
            cursor: pointer;
        }
    }
    &.famColor{
        border-radius: unset;
    }
    &:hover {
      border: 3px solid#0095e8;
     }
     &.has-value{
      // height: 2em;
      // width: 2em;
      border: 3px solid#0095e8;
    }
  }
  &.colorsA {
    padding-bottom: 20px;
    margin-top: 20px;
    .colorFilter {
      color: var(--fcblue);
      border: 1px solid var(--fcblue);
      border-radius: 10px;
      &.current {
        background-color: var(--fcblue);
        color: #ffffff;
      }
      &:hover {
        background-color: var(--fcblue);
        color: #ffffff;
      }
    }
  }
  &.colorsB {
    display: flex;
    //align-items: unset;
    //justify-content: unset;
    padding-left: 10px;
    button{
      @media screen and (max-width: 768px) {
        margin-bottom: 5px;
      }
    }
    @media screen and (max-width: 768px) {
      
    }
    .colorFilter {
      color: var(--fcdarkstlblue);
      border: 1px solid var(--fcdarkstlblue);
      background-image: unset !important;
      border-radius: unset;
      width: unset;
      height: 30px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: unset;
      margin-left: 5px;
      @media screen and (max-width: 768px) {
        height: 40px;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 2px;
      }
      @media screen and (max-width: 320px) {
        margin-bottom: 5px;
        float: left;
      }
      &.current {
        background-color: var(--fcdarkstlblue);
        color: #ffffff;
      }
      &:hover {
        background-color: var(--fcdarkstlblue);
        color: #ffffff;
      }
    }
    .AllColors{
        border-radius: unset;
        border: 1px solid var(--fcdarkstlblue);
        background: var(--fcdarkstlblue);
        margin-left: 5px;
        @media screen and (max-width: 768px) {
          height: 40px;
          padding-left: 4px;
          padding-right: 4px;
          margin-left: 2px;
        }
        @media screen and (max-width: 320px) {
          float: left;
          width: unset;
        }
    }
  }
}
.CompleteGranitePicker {
  .FfilterCatalog.GraniteColors {
    // margin-top: 0px;
    // margin-bottom: 10px;
    .AllColors {
      background: #149edc;
    }
    &.colorsX{
        // padding-top: 10px;
        // padding-bottom: 10px;
    }
  }
  .selectColor {
    display: none;
  }
  .ScrollListView {
    top: 150px;
    @include smartphone { // max-width 800
      top: 230px;
    }
  }
}
.Fgranites {
  background: #FFFFFF;
  .CompleteGranitePicker {
    // margin-top: 35px;
  }
  .ScrollListView {
    top: 100px;
    @include smartphone {
      margin-top: 30px;
      top: unset;
      padding: 0;
    }
    .ListView {
      margin: 0;
    }
  }
  .selectColor {
    display: none;
    @include smartphone {
      display: block;
      font-size: 16px;
      position: fixed;
      width: 100%;
      top: 145px;
      right: 0px;
      z-index: 100;
    }
  }
  .GranitePreviewCard {
    font-family: 'sukhumvit',Arial, Helvetica, sans-serif;
    &:hover {
      /* border: 1px solid var(--fcorange); */
      border: 1px solid var(--f-main-color);
      box-shadow: none;
    }
    /* @include smartphone {
      figure img {
        bottom: 20px;
      }
    } */
    &.cardB{
      @media screen and (min-width: 768px) {
        // height: 252px;
      }
      @media screen and (max-width: 768px){
        // height: 250px;
      }
      .LazyImageB{
        @media screen and (max-width: 768px) {
          // padding-bottom: unset;
        }
      }
      .Infos{
        .Price{
          @media screen and (max-width: 860px) {
            bottom: 20px;
          }
        }
      }
      &:hover{
        /* border: 1px solid var(--fcorange); */
        border: none;
      }
      &.selected {
        cursor: pointer;
        /* border-color: var(--fcorange); */
        border-color: var(--f-main-color);
        h3 {
          /* color: var(--fcorange); */
          color: var(--f-main-color);
        }
      }
    }
    &.cardA{
      &:hover{
        border: 1px solid var(--fcblue);
      }
      &.selected {
        cursor: pointer;
        border-color: var(--fcblue);
        h3 {
          color: var(--fcblue);
        }
      }
    }
  }
  h3.Name {
    margin-top: 10px;
    // font-family: $RalewayFont;
    // // position: fixed;
    // bottom: 7px;
    // left: 5px;
    // padding: 0;
    // color: #667481;
    // font-size: 14px;
    // font-weight: bold;
    // color: $fc-darkgrey;
    // margin-right: 10px;
  }
  .Price {
    // position: fixed;
    bottom: 7px;
    right: 5px;
    color: $fc-grey;
    font-size: 14px;
  }
  .StepAction {
    position: absolute;
    left: 10px;
    bottom: 50px;
    float: left;
    &.reset {
      position: unset !important;
      bottom: unset !important;
      float: left !important;
      &.hide {
        @include smartphone {
          display: none;
        }
      }
    }
  }
}
.GraniteCarousel {
  .GraniteCard .Infos {
    float: right;
    color: var(--fcblue);
    z-index: 9999;
    position: absolute;
    right: 15px;
    top: 45px;
    text-align: right;
    .Price {
      color: $fc-lightgrey;
      font-size: 14px;
    }
  }
}
