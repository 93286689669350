%material-icon {
  direction: ltr;
  display: inline-block;
  font: normal 24px/1 'Material Icons';
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

@mixin material-size($font-size: 24px, $size: 100%, $align: none) {
  float: $align;
  font-size: $font-size;
  line-height: $size;
  width: $size;
  @content;
}
