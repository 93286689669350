
.CurrentSlide .Ffooter{position:absolute;}
.NextSlide .Ffooter{display:none}
.Carousel .Ffooter{
    .StepAction.Left {right: 60%;}
    .StepAction.Middle{float:left; margin-left: 46%;color: $fc-medgrey;border: 1px solid $fc-medgrey;
                       &:hover{background: $fc-medgrey;color: #FFFFFF;}}
    .StepAction.Right{left: 67%;} 
}

.Carousel .Ffooter  {@include smartphone  {
    .StepAction.Left{position: fixed;top: unset;bottom:60px;left:15px}
    .StepAction.Right {position: fixed;top: unset;bottom:60px;right:50px}
    .StepAction.Middle {position: fixed;top: unset;bottom:60px;right:90px; span{top:10px}}
    }
}
.Ffooter{
    position:fixed;bottom:15px;width:100%;font-size:14px;z-index: 1000;
    @include smartphone {z-index: 350;}
    .StepAction.Right{position: absolute;bottom: 0;left: 50%;}        
    .StepAction.Left {position: absolute;bottom: 0;right: 55%;}
   .StepLabelSelect {font-weight:bold;color:var(--fcblue)}
   .StepAction.Right{ @include smartphone {position:fixed;bottom: unset;top:65px;right:5px}}
   .StepAction.Left { @include smartphone {position:fixed;bottom: unset;top:65px;left:5px}}
   &.Wide{
    .StepAction.Right{right: 20px; left:unset;@include smartphone {right:5px}}
    .StepAction.Left {left: 20px;@include smartphone {left:5px}}
   }
}
.Family .CatalogModal .Carousel .Next{position: fixed;top: 50%;margin-right: 5px; 
    @include smartphone {top: 93%;margin-right: 35px; }
}
.Family .CatalogModal .Carousel .Previous{position: fixed;top: 50%;margin-left: 5px;
    @include smartphone {top: 93%;margin-left: 35px; }
}
.StepAction{
    width: 205px;height: 40px;background: #FFFFFF;cursor: pointer;font-size:15px;color:var(--fcblue);
    border: 1px solid var(--fcblue);border-radius: 50px;
    line-height:40px;font-weight:bold;    text-align: center;
    .icon{font-size: 20px;font-weight: bold;line-height: 30px;width: 30px;
        @include smartphone {font-size: 15px;padding-left: 5px}}
    &:hover{background: var(--fcblue);color: #FFFFFF;}
    @include smartphone {font-size:12px;background: none;width:70px;height:70px;line-height:12px;border-radius: 5px;}
    span{@include smartphone {position: absolute;left:5px;top:35px;font-weight: bold;}}
}

.StepAction.First,.StepAction.Second{  position: absolute;top: 80px;left: 100px!important;z-index: 3000;}
.StepAction.Second{top: 130px;}
.StepAction.Blue{background: var(--fcblue);border:none;color:#FFFFFF}
.StepButton{ width: 30px;height: 30px; background: var(--fcblue);border-radius: 100%;color:#FFFFFF;
             font-size:20px;border: none; &:hover{color: #FFFFFF;}
             @include smartphone {width: 27px;height: 27px}}
.StepButton.Blue{background:#FFFFFF;color:var(--fcblue)}
.StepButton.right{margin-right: 5px;float: right; margin-top: 5px;
    @include smartphone {margin-right: 20px;}}
.StepButton.left{margin-left: 5px;float: left; margin-top: 5px;
    @include smartphone {margin-left: 20px;}}


 
