// Colors
$color-grey-3: #303030;
$color-grey-6: #676767;
$color-grey-9: #9b9b9b;
$color-grey-B: #b4b4b4;
$color-grey-D: #d0cfcf;
$color-grey-E: #e2e2e2;
$color-white: #fff;
$color-red: #a11525;
$color-blue: #149edc;
$color-bluegreen: #21676e;
$color-green: #76af2c;
$color-orange: #d26b10;
$color-purple: #6F0D6D;
$color-alert: #D4321D;

$color-catalog-001-399: #B44953;
$color-catalog-400-499: #655B5A;
$color-catalog-500-599: #814340;
$color-catalog-600-699: #EC7654;
$color-catalog-700-799: #CC6470;
$color-catalog-800-899: #BC4772;
$color-catalog-CIN: #A37CA5;
$color-catalog-TBL: #C8718B;
$color-new: #EC6F7E;
$color-favorite: #BFA64F;
$color-collection: #C8718B;

$color-sellingprice: $color-blue;
$color-buyingprice: $color-red;
$color-help: $color-purple;

// Fonts
$font-stack-default: 'Cuprum', Geneva, Tahoma, Verdana, sans-serif; 
$font-stack-alt: 'Raleway', sans-serif;
$font-stack-script: 'Caveat', 'Brush Script MT', cursive;
$RalewayFont: 'Raleway',  arial, helvetica, sans-serif;
$sukhumvitFont: 'sukhumvit', arial, helvetica, sans-serif;
// Transitions
$default-transition-duration: 0.35s;

$dim-dashboard-radius: 15px;

// SquareButton
/*$button-square-size: 90px;
$button-square-border-color: #666;
$button-square-background-color-default: #ccc;
$button-square-text-color-defaut: #333;
$button-square-background-color-active: turquoise;
$button-square-text-color-active: white;*/
