%border-radius {
  @include border-radius(3px);
  overflow: hidden;
}

%box-shadow {
  @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
}

%box-shadow-hover {
  // On indique que l'élément va se transformer pour éviter des effets
  // bizarres sur les choix de gravure/motif
  // On pourrait utiliser will-change mais ce n'est pas supporté par IE
  @include transform(translateY(0));
  &:hover {
    @include box-shadow(0 5px 3px rgba(0, 0, 0, 0.3));
    // @include transform(translateY(-2px));
    transform: scale(1.05)
  }
}

%button-text {
  @extend %reset, %border-radius;
  background: $color-white;
  border: 1px solid darken($color-white, 20%);
  color: $color-grey-6;
  cursor: pointer;
  display: inline-block;
  font: font-default(16, normal, 100%);
  margin: -1px;
  padding: 10px;
  outline: 0;
  @include single-transition();
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  .icon, .material-icons {
    @include material-size(24px, 36px, right);
    margin: -10px -10px -10px 0;
    vertical-align: middle;
  }
  &:hover {
    border-color: darken($color-white, 25%);
    color: $color-grey-3;
  }
  &.active {
    background: $color-blue;
    color: $color-white;
    &:hover {
      background: darken($color-blue, 10%);
      border-color: darken($color-blue, 25%);
    }
  }
}

@mixin button-text-colors($backgroundColor: $color-white, $textColor: $color-grey-6) {
  background: $backgroundColor;
  border-color: darken($backgroundColor, 20%);
  color: $textColor;
  &:hover {
    background: darken($backgroundColor, 10%);
    border-color: darken($backgroundColor, 25%);
    color: $textColor;
  }
}

%button-group {
  @extend %reset, %border-radius;
  border: 1px solid darken($color-white, 20%);
  display: inline-block;
  margin: -1px;
  vertical-align: middle;
  .button {
    @extend %button-text;
    border: 0;
    border-left: 1px solid darken($color-white, 20%);
    @include border-radius(0);
    &:first-child {
      border-left: 0;
    }
  }
}

%square-button {
  @extend %reset, %border-radius;
  background: $color-grey-E;
  border: 1px solid $color-grey-D;
  color: $color-grey-6;
  display: inline-block;
  font: font-default(13, normal, 100%);
  height: 70px;
  margin: -1px;
  outline: 0;
  overflow: hidden;
  @include single-transition();
  text-align: center;
  text-transform: uppercase;
  width: 70px;
  .icon, .material-icons {
    @include material-size(32px, 70px, right);
    margin: -5px 0 -15px 0;
  }
  .material-icons {
    margin: -10px 0;
  }

  @include smartphone {
    width: 50px;
    height: 50px;
    font-size: 10px;
    .icon, .material-icons {
      @include material-size(24px, 50px, right);
    }
  }

  &.hasContent, &.hasContent:hover {
    /* color: $color-blue; */
  }
  &:hover {
    @extend %box-shadow;
    background: lighten($color-grey-E, 10%);
    color: darken($color-grey-6, 15%);
  }
  &.active, &.active.hasContent {
    background-color: $color-blue;
    border-color: darken($color-blue, 20%);
    color: $color-white;
    &:hover {
      background: darken($color-blue, 10%);
      border-color: darken($color-blue, 25%);
    }
  }
}

@mixin square-button-colors($backgroundColor: $color-blue, $textColor: $color-white) {
  background: $backgroundColor;
  border-color: darken($backgroundColor, 20%);
  color: $textColor;
  &:hover {
    background: darken($backgroundColor, 10%);
    border-color: darken($backgroundColor, 25%);
    color: $textColor;
  }
}

%list-item {
  @extend %reset, %border-radius, %box-shadow;
  background: $color-white;
  border: 1px solid $color-grey-D;
  cursor: pointer;
  display: block;
  margin: -1px;
  position: relative;
  @include single-transition();
  text-decoration: none;
  @extend %box-shadow-hover;
  &.active, &.selected {
    // border: 2px solid $color-blue;
    // margin: -2px;
  }
}

%figure-height-relative {
  box-sizing: border-box;
  z-index: 10;
  img:not(.configfLoader) {
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    position: absolute;
    top: 0;
    right: 0;
    zoom: 4;
    -ms-zoom: 1; // Problèmes avec IE
    z-index: 20;
  }
}

@mixin figure-height-relative($ratio /* Ratio width / height */
) {
  @extend %figure-height-relative;
  padding-bottom: (1/$ratio)*100%;
}
