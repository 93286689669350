.Carousel {
  @extend %columns;
  height: 100%;

  .Previous, .Next {
    @extend %box-shadow;
    @include border-radius(100%);
    color: $color-white;
    cursor: pointer;
    margin: -1.5rem;
    position: absolute;
    @include single-transition();
    top: 50%;
    z-index: 5000;
    -webkit-user-select: none; // évite les surlignements bizares quand on double clique
    @extend %box-shadow-hover;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary1);
  }
  .Previous {
    left: 0;
  }
  .Next {
    right: 0;
  }

  .ScrollContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
    @include transform(translateX(0%));

    &.animate--next .SlidesWrapper {
      @include animation(nextSlide 0.3s ease); // 0.3s == temps JS
    }
    &.animate--previous .SlidesWrapper {
      @include animation(prevSlide 0.3s ease); // 0.3s == temps JS
    }

    .SlidesWrapper {
      left: -100%;
      height: 100%;
      position: relative;
      width: 300%;
      .Slide {
        bottom: 0;
        background:#e2e2e2;
        position: absolute;
        top: 0;
        width: 33.3333%;
        &.PreviousSlide {
          left: 0;
        }
        &.CurrentSlide {
          left: 33.3333%;
        }
        &.NextSlide {
          right: 0;
        }
      }
    }
  }
  .LabelNext,.LabelPrevious{ position: absolute;   
    color: #8C8E8E;font-size: 0.8125rem;font-style: italic;}
  .LabelPrevious{margin-left: 3.4375rem;margin-top: -2.5rem;}
  .LabelNext{margin-left: -2.8125rem;margin-top: -2.5rem;width: 3.125rem;}
}

@include keyframes(nextSlide) {
  0% {
    @include transform(translateX(+33.3333%));
  }
}

@include keyframes(prevSlide) {
  0% {
    @include transform(translateX(-33.3333%));
  }
}
