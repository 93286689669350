.Family{
    .paragraph-min-line {
        margin-bottom: 0.1rem !important;
    }
    .Header{@include smartphone{height: 145px;background: #303030;}}
    .cfg-steps a.First{max-width: unset}
    .cfg-steps a.disable{cursor: not-allowed;}
    .cfg-steps a:hover{color: var(--fcblue);font-weight:500;}
.Header.cfg-steps {
        a{ font-size:15px;text-transform: none;white-space: pre-wrap;width: 12vw!important;@media screen and (max-width: 1500px){width: 13vw!important;}}
        a.active{background:var(--fcblue)}
        a.active:hover{color: #FFFFFF;}
        a.active + a:after {border-left-color: var(--fcblue)}
        .wrapper-bnav {
          a.active{background:var(--fcblue)}
          a.active + a:after {border-left-color:var(--fcblue)}
        }
        .bigNumber{font-size:38px;margin-right: 5px;}
        .MobileVersion{display:none;width:100%}
        @include smartphone {
            .ClassicVersion{display:none}
            .MobileVersion{display:block;color:white;position:fixed;top:65px;text-align: center;}
            .MobileStep{font-size: 38px;}
            .MobileAction {font-size: 16px;margin-right: 70px;margin-left: 90px;max-width: 200px;}
            .MobileStepActionB {
              width: max-content;
              margin-top: 15px;
              margin-left: 15px;

              .bigNumber {
                i {
                  vertical-align: middle;
                }
              }
            }
        }
        .wrapper-bnav {
          width: 400px;
          margin: auto;

          a.header-bnav {
            color: white;
            cursor: pointer;

            .HeaderLabel {
              .label {
                font-size: 17px;
              }
            }
          }
        }
    }
    .HeaderLabel{
        text-align: center;line-height:100%;
    div{vertical-align: middle;display: inline-block;text-align: left;}
    label{text-align: left}
    }
    .MonumentCard {
        padding-bottom: 10px;
        .Infos{
        top:unset;bottom:-15px;
        .Footstone{display:unset;margin-right:5px;color:#3B4752}
        .Headstone{font-size: 15px;color: #3B4752;/*font-style:italic*/}
        .Pattern, .Description{display:none}
        }
        @include smartphone {figure img{bottom:15px;left:-10px}
        }

    }
    .seeDetail{background: var(--fcblue) 0% 0% no-repeat padding-box;border-radius: 50px;cursor: pointer;
        text-align: center;text-decoration: underline;font-size:14px;font-style:italic;
        color: #FFFFFF;width: 95px;height: 20px;margin-top: 5px;}
    .Header .seeDetail{position:fixed;top:5px;right:unset;text-align: center;padding-top: 3px;}
    .Header .HeaderLabel .seeDetail{width: fit-content;padding: 5px 10px 5px 10px;height: 40px;text-decoration: none;margin-top: 9px;right:15%;width:150px;@media screen and (max-width: 1650px){display: none;}}

    .FsmallDetailB {
      cursor: pointer;
      z-index: 999;
      position: fixed;
      right: 0;
      background-color: #e6e6e6;
      height: 70px;
      width: 165px;
      top: 0px;
      display: none;

      .monumentName {
        margin-top: 5px;
        text-align: center;
        font-family: Cuprum;
        font-size: 16pt;
        color: #0E0E0F;
      }

      .granitName {
        text-align: center;
        font-family: Cuprum;
        font-size: 10pt;
        font-weight: bold;
        color: #0E0E0F;
      }

      .monumentImg-wrapper {
        position: relative;
        padding: 0px;
        margin-top: 10px;
        background: #ffffffc5;
        width: 250px;

        .monumentImg {
          position: absolute;
          right: 50px;
        }

        .glass-wrapper {
          position: absolute;
          background: rgba(255, 255, 255, 0.7);
          top: 60px;
          left: 70px;
          width: 30px;
          height: 30px;
          border-radius: 20px;
          text-align: center;

          img {
            margin-top: 7px;
          }
        }
      }

      .seeDetailB {
        position: relative;
        z-index: 999;
        top: 12px;
        background: #e6e6e6 0% 0% no-repeat padding-box;
        border: 1px solid #3B4752;
        border-radius: 30px;
        color: #0E0E0F;
        font-size: 10pt;
        padding: 5px 10px 5px 10px;
        text-align: center;
        font-weight: bold;

        i {
          font-size: 40px;
          vertical-align: middle;
          margin: -15px;
          margin-left: 0px;
        }
      }
    }

    .FsmallDetail .seeDetail{position: absolute;right: 19px;top:unset}

    .cfg-steps a.active{
        @include smartphone {padding-top: unset}
    }
    .logo{float: left; height: 40px;margin-left: 5px;margin-top: 15px;margin-right: 10px;cursor: pointer;
        /*background-color: white;*/}

    .FsmallDetail {z-index: 301;position: fixed;top: 88px;right: 0;width: 140px;height: 80px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0 0 5px grey;border-radius: 80px 0px 0px 80px;
        text-align: right;padding-top: 8px;padding-right:19px;margin-top:-15px;
        @include smartphone {height:55px;top:20px}
        .monumentName{font-size:20px;color:var(--fcblue);@include smartphone {display:none}}
        .from{font-size:14px;color:$fc-darkgrey;
            .price{font-weight: bold;font-size:16px}}
    }
    .FbigDetail{
      width: 1409px;height: 636px;position: absolute;right: 0;z-index: 3003;top:70px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 30px #00000038;
        border-radius: 900px 0px 0px 900px;overflow: hidden;
        .Loading {left: 25%;color:$fc-lightgrey;text-align: unset;}
        /* .packagePriceInfo{} */
        img{position:relative;height:620px;left:30px; width: unset;
            @include smartphone {    position: fixed;bottom: 10px;height: 200px;}}
        .prices{position:absolute;top:15%;right:19px;width:380px;font-size:16px;color:$fc-medgrey;
          @include smartphone {right:4px}
            &.smaller{font-size:14px}
            &.tiny{font-size:12px}
            .table{color:$fc-medgrey;
                @include smartphone {position: fixed;top: 120px;left:0px}}
            .price{color:var(--fcblue);text-align: right;padding-top: 10px;
                @include smartphone{position: fixed;left: 25%;top: 60px;}}
            td{margin-left: 10px;width:280px;white-space:pre;border-top:unset;border-bottom:1px solid #dee2e6 }
            .Tprice {border-left: 1px solid #dee2e6;width:70px;text-align: right;padding-right:5px}
            .packagePrice{
              font-style:italic;
              font-size:14px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-top: 10px;
              @include smartphone {position: fixed;top: 90px;left:0px}
              .packagePriceInfo{
                width: 94px !important;
                height: 94px !important;
                left: unset !important;
              }
              p{
                font-family: 'Cuprum', sans-serif;
                font-size: 14px;
                color: #707070;
                font-style: normal;
                font-weight: normal;
                span{
                  font-size: 16px;
                  font-weight: bold;
                }
              }
            }
        }
        .actions {
          position: absolute;
          top: 30px;
          right: 30px;
          width: 380px;
          @include smartphone {top:10px;}

          .reset {
            img {
              position: absolute;
              height: 30px;
              left: unset;
              top: 10px;
              left: 40px;

            }

            &.hide {
              img {
                left: 36px!important;
              }
            }

            span {
              margin-left: 10px!important;
            }
          }

          .Close{
            top: 0px;
            right: 0px;
          }
        }
        @include smartphone {z-index: 353;}
      .priceListContainer{
        .priceListRow{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-bottom: 1px solid #dee2e6;
          .priceListColTitle{
            width: 80%;
            border-right: 1px solid #dee2e6;
            p{
              margin-top: 1rem;
            }
          }
          .priceListColPrice{
            font-weight: bold;
            height: 100%;
            width: 20%;
            text-align: right;
          }
        }
      }
    }

    @keyframes FbigDetailB-animation {
      from {
        top: -100%;
      }
      to {
        top: 70px;
      }
    }
    @keyframes FbigDetailB-animation-close {
      from {
        top: 70px;
      }
      to {
        top: -100%;
      }
    }

    .FbigDetailB-open {
      animation: FbigDetailB-animation .5s;
      top: 70px;
      height: 100%;

      @include smartphone {
        top: 130px;
      }
    }
    .FbigDetailB-close {
      animation: FbigDetailB-animation-close .5s;
      top: -100%;
      height: 0;
      overflow: hidden;
      display: none;
    }
    .priceListContainer{
      .priceListRow{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid #dee2e6;
        padding-top: 5px;
        padding-bottom: 5px;
        .priceListColTitle{
          width: 80%;
          border-right: 1px solid #dee2e6;
        }
        .priceListColPrice{
          height: 100%;
          width: 20%;
          text-align: right;
          font-weight: 700 !important;
        }
      }
    }
    .FbigDetailB {
      /* 
      position: absolute;
      left: 36% !important;
      top: 25% !important;
      background-color: white;
      width: 50%;
      transition: all .5s ease-in-out; 
      padding-top: 50px;
      padding-right: 10px;
      border-radius : 6px !important;
      overflow: unset;
      z-index: 9999;
      */
      /* @media screen and (min-width: 576px){
        position: absolute;
        left: 110px !important;
        width: 300px;
        top: 40px !important;
      }
      @media screen and (max-width: 658px){
        left: 0px !important;
        //top: 36px !important;
        width: 96%;
      }
      @media screen and (max-width: 768px){
        z-index: 99;
      } */
      /* @media screen and (max-width: 576px){
        width: 100%;
        left: 0 !important;
        top: 0 !important;
        position: fixed;
        height: 100%;
        overflow: auto;
      } */
      /* .mobilePrices{
        margin-top: inherit;
      } */
      .packagePriceInfo{display: none;}
      .packagePrice{

      }
      .prices {
        top:15%;
        right:19px;
        background: #FFFFFF;
        font-size:16px;
        color: $fc-medgrey;
        margin-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        & .smaller{ font-size:14px }
        & .tiny{ font-size:12px }

        .table {
          color:$fc-medgrey;
          @include smartphone {top: 120px;}
        }

        .price{
          color:var(--fcdarkstlblue);
          text-align: right;
          padding-top: 10px;
          @include smartphone{}
        }
   
        td{
          border-top:unset;
          border-bottom:1px solid #dee2e6;
          margin-left: 10px;
          width:280px;
          white-space: pre;}

        .Tprice {
          border-left: 1px solid #dee2e6;
          width:70px;
          text-align: right;
          padding-right:5px;
        }

        .packagePrice{
          /* font-style: italic;
          font-size: 14px;
          text-align: right; */
        }
        .LeftOvalAction{
          text-align: center;
          width: 100%;
          /* padding-right: 10px; */
          margin: 20px auto 10px auto;
          position: relative !important;
          top: unset !important;
          z-index: 99;
        }
        .FquoteHelp {
          /* cursor: pointer;
          color: var(--fcdarkstlblue);
          font-size: 16px;
          font-weight: bold; */
        }
        .FamilyHelpText {
          /* */
          // background-color: white;
          transform: translate(-50%, 30px); 
          top: unset;
          left: 50%;
        }
      }



    }
    .shadow-box-equal {
      /* box-shadow: 0 0 5px 2px rgba(71, 71, 71, .2) !important;
      -webkit-box-shadow: 0 0 5px 2px rgba(71, 71, 71, .2) !important;
      -moz-box-shadow: 0 0 5px 2px rgba(71, 71, 71, .2) !important; */
      border-radius : 6px !important;
      padding-right: 0;
      padding-bottom: 15px;
      margin-right: 15px;
      opacity: .98;
      left: 190px;
      top: 50px;
      @include smartphone{
        left: 2%;
      }
    }
    .Fquote {
      right:0px;
      overflow: hidden;
      background:white;
      text-align: left;
      padding: 20px 40px 0px 40px;
      width: 360px;
      padding-right: 10px;
      box-shadow: -5px 0px 5px 1px #aaaaaa;
      box-shadow:8px 0 8px -10px #aaaaaa,-8px 0 8px -10px #aaaaaa;
      border-top-style: none;

      @include smartphone {
        box-shadow: none;
        width: 100%;
        height: 100%;
        margin-top: 70px;
        padding-left: 40px;
        padding-top: 20px;
        padding-right: 0px;
        right: 0;
        overflow: auto;
      }

        .required:after {content:" *";color:#a51522;}
        .FquoteTitle{ margin-bottom:25px;position: relative;
            img{border-radius: 50%;max-width: 104px;&.square{border-radius:0px};
              max-height: 104px;}
            span{font-size: 24px;color:$fc-deepgrey;position: absolute;margin-left: 115px;}
            .TheSupplier{font-size:16px;margin-left: 115px;position: absolute;
                a{cursor: pointer;}}
        }
        .FquoteHelp{color: var(--fcdarkstlblue);font-size:16px;font-weight:bold;margin-bottom:10px;position: relative;cursor: pointer;
        span{margin-left:45px;position:absolute;text-decoration: underline;}}
        .FquoteHelpBottom{color:black;font-size:13px;margin-bottom:20px;margin-left: auto;}
        label{color: $fc-darkgrey;font-size:16px;}
        input{font-size:14px;margin-bottom:5px;width:293px;background: #F5F6FC 0% 0% no-repeat padding-box;
            border: 1px solid #E4E6F0;border-radius: 2px;}
        .FquoteForm{
          font-size:18px;

          .FrenchAddress{font-size:16px}
        }
        textarea{height:100px;}
        .FquoteSend{
          /*
          background: var(--fcdarkstlblue);
          color: white;
          display: block;
          font-size:16px;
          font-weight:bold;
          border-radius: 50px;
          padding: 10px 20px;
          */
          width: 100%;
          margin-top:20px;
        }
        .OutsideFrance{background: white;color:var(--fcdarkstlblue);border:1px solid var(--fcdarkstlblue);font-size:12px;border-radius: 20px;
          float:right;margin-right: 5px;}
        .FquoteError{margin-top: -30px;color:red;}
        .messageSent{font-size: 18px;font-weight: bold;}
        .FquoteAsk {
          /* background: var(--fcorange); */
          /* color: var(--fcdarkstlblue); */
          background: var(--f-main-color);
          color: var(--f-font-color);
          text-transform: uppercase;
          display: block;
          font-size:16px;
          font-weight:bold;
          border-radius: 50px;
          border-style: none;
          padding: 10px 20px;
          width: 90%;
          &:hover{
            background-color: var(--fcdarkstlblue);
            color: #FFFFFF;
          }
        }
    }
    .Fquote-informations::before {
      background-color: #EFEFEF;
      content: "\00a0";
      display: block;
      left: -10px;
      height: 16px;
      position: absolute;
      top: 11px;
      transform: rotate( 29deg ) skew( -35deg );
      -moz-transform: rotate( 29deg ) skew( -35deg );
      -ms-transform: rotate( 29deg ) skew( -35deg );
      -o-transform: rotate( 29deg ) skew( -35deg );
      -webkit-transform: rotate( 29deg ) skew( -35deg );
      width: 20px;
    }

      .Fquote-customize {
        position:fixed;
        left:0px;
        top: 70px;
        overflow: hidden;
        background: #F4F4F4 0% 0% no-repeat padding-box;
        text-align: left;
        padding: 20px 40px 0px 20px;
        width: 400px;
        height: 100%;
        padding-right: 20px;
        box-shadow:8px 0 8px -10px #aaaaaa,-8px 0 8px -10px #aaaaaa;
        border-top-style: none;
        z-index: 99999;

        .close-me {
          text-align: right;
          cursor: pointer;

          span {
            font-size: 10pt;
          }
          i {
            vertical-align: middle;
          }
        }

        @media screen and (max-width: 576px)  {
          border:none;
          width: 100%;
          padding-left: 35px;
          padding-right: 35px;
          padding-top: 10px;
          top: 20px;
          z-index: 150;
        }
          .required:after {content:" *";color:#a51522;}
          .FquoteTitle{
            margin-bottom:25px;
            position: relative;
            min-height: 45px;
            .titleText{font-size: 24px}
              img{ @media screen and (max-width: 576px) {display:none};
                border-radius: 50%;&.square{border-radius:0};
                max-width: 104px;
                max-height: 104px;
              }
              span{
                font-size: 24px;
                color:$fc-deepgrey;
                position: absolute;
                margin-left: 115px;
                @media screen and (max-width: 576px){
                  position: unset;
                  text-align: center;
                  margin: 20px auto 20px auto;
                }
              }
              .TheSupplier{font-size:16px;margin-left: 115px;position: absolute;
                  a{cursor: pointer;}}
          }
          .FquoteHelp{color: var(--fcnavyblue);font-size:16px;font-weight:bold;margin-bottom:10px;position: relative;cursor: pointer;
            @media screen and (max-width: 576px)  {display:none};
          span{margin-left:45px;position:absolute;text-decoration: underline;} .svg-ffinalizationAsk{fill:var(--fcnavyblue);}
          }
          .FquoteHelpBottom{color:black;font-size:13px;margin-bottom:20px;margin-left: auto;}
          label{color: $fc-darkgrey;font-size:16px;}
          input{font-size:14px;margin-bottom:5px;width:293px;background: #F5F6FC 0% 0% no-repeat padding-box;
              border: 1px solid #E4E6F0;border-radius: 2px;}
          .FquoteForm{font-size:18px;
            .FrenchAddress{font-size:16px}
            .form-group{
              width: 100%;
              input{
                width: 100%;
              }
            }
          }
          textarea{height:100px;}
          .FquoteSend{
            /*
            background: var(--f-main-color); 
            color: var(--f-font-color);
            display: block;
            font-size: 16px;
            font-weight: bold;
            border-radius: 50px;
            padding: 10px 20px;
            */
            width: 100%;
            margin-top:20px;
          }
          .OutsideFrance{
            width: 100%;
            /* background: white;color:var(--f-main-color);border:1px solid var(--f-main-color);font-size:12px;border-radius: 20px;
            float:right;margin-right: 5px; */
          }
          .FquoteError{margin-top: -30px;color:red;}
          .messageSent{font-size: 18px;font-weight: bold;}
      }

  }

  .ReactModal__Content.big-picture-header-modal {
    border-style: none;
    width: auto;
    text-align: center;
    background-color: white;

    img {
      height: 100%!important;
    }
  }
  .ReactModal__Overlay.big-picture-header-modal-overlay {
    background: rgba(0, 0, 0, .5)!important;
    z-index: 150;
  }

  .ReactModal__Overlay.customize-modal-overlay {
    background: rgba(42, 48, 50, 0.5);
  }

  .customize-modal {
    width: 530px!important;
    height: 210px!important;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border-radius: 10px!important;
    font-size: 24px;
    color: $fc-deepgrey;
    text-align:center;
    max-width: none;
    top: 48%!important;left: 50%!important;
    transform: translate(-50%, -50%)!important;

    @include smartphone {
      width: 90%!important;
      height: 100%;

      .actions {
        display: contents;

        button {
          margin: auto!important;
          margin-top: 5px!important;
        }
      }
    }

    .CloseModal {
      span, i {
        font-size: 10pt!important;
        padding-top: 0px!important;
      }

      span {
        font-style: italic;
      }

      i {
        font-weight: bold;
      }
    }

    .modal-content{
      border: 0px;
      border-radius: 10px;
      height: 209px;
      padding-bottom: 30px;
      padding-top: 50px;
      
      .actions {
        button {
          width: 206px;
          margin: 5px;
          height: 40px;
          background: var(--fcblue) 0% 0% no-repeat padding-box; //CHANGED #0095E8
          border-radius: 50px;
          text-align: center;
          font-family: Cuprum;
          font-size: 14pt;
          letter-spacing: 0px;
          color: #FFFFFF;
          text-transform: none!important;
        }
      }
    }
  }

  .FdetailInfosB{
    &.hidden{visibility:hidden}
    .FbigDetailB{
      left: unset !important;
      top: 69px!important;
      /* 
      right: 0;
      height: 93%;
      overflow-y: auto;
      overflow-x: hidden; 
      */
      .prices{
        .FquoteHelp{
          color: var(--fcdarkstlblue);
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
          margin-top: 10px;
          position: relative;
          cursor: pointer;
          svg{
            margin-right: 10px;
          }
        }
      }
      .Fquote{
        width: 103%;
        padding: 5px 12px 5px 12px;
        .row{
          margin-left: 0;
          margin-right: 0;
          button{
            width: 100%;
          }
          .form-group{
             input{
               @media screen and (max-width: 576px){
                 width: 100%;
               }
             }
          }
        }
      }
    }
    .FbigDetailB{
      padding-top: 10px;padding-left: 5px;padding-right: 5px;
      .closePanel {
        span{
          font-family: $sukhumvitFont;
        }
        float: right;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -10px;
        color: var(--fcdarkstlblue);
        cursor: pointer;
        &:hover{
          /* color: var(--fcorange); */
          color: var(--f-main-color);
        }
      }

      .Fquote{
        width: 100%;
        box-shadow: unset;
        margin-top: 15px;
        overflow-y: auto;
        .FquoteTitle{
          margin-bottom: 5px;
          //height: 25px;
          display: block;
          .titleText{
            margin-left: 0;
            width: 100%;
            font-size: 20px;
          }
          .square{
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
            display: block;
          }
        }
        .FquoteForm{
          .row{
            display: unset;
            flex-wrap: unset;
            .form-group{
              input{
                width: 100%;
              }
            }
          }
        }
        .FquoteSend{
          margin-top: 0px;
          background: var(--f-main-color);
          color: var(--f-font-color);
          /* background-color: var(--fcorange); */
          /* color: var(--fcdarkstlblue); */
          text-transform: uppercase;
          cursor: pointer;
          border: unset;
          font-family: $sukhumvitFont;
          font-size: 16px;
          height: unset;
          &:hover{
            background-color: var(--fcdarkstlblue);
            color: #FFFFFF;
          }
        }
      }
      .FquoteHelp{
        color: var(--fcdarkstlblue);
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
        padding: 0 15px;
        position: relative;
        cursor: pointer;
        svg{
          margin-right: 10px;
        }
      }
      .FamilyHelpText {
        .arrow {
          border:solid 10px transparent;
          border-left-color:#EFEFEF;
          position:absolute;
          left: unset;
          right: 0;
          top: 50%;
          -webkit-transform: rotate(0deg);
          transform: translate(100%,-50%) rotate(0deg);
          @media screen and (max-width: 768px) {
            display:none;
          }
        }
      }
    } 
  }
