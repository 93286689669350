form {
  @extend %reset;
}

input, textarea, select {
  @extend %border-radius;
  background: $color-white;
  border: 0.125rem solid $color-grey-B;
  color: $color-grey-3;
  font: font-default(16, normal, 1rem);
  margin: -0.125rem;
  padding: 0.5rem 0.625rem;
}

input[type=number] {
  @include border-radius(1.125rem);
  font: font-default(18, normal, 1.125rem);
  font-variant-numeric: lining-nums proportional-nums;
  padding: 0.4375rem 0.625rem;
  text-align: right;
  &.disabled {
    opacity: 0.5;
  }
}

select {
  height: 2.25rem;
}

label.checkbox, label.radio {
  color: $color-grey-6;
  cursor: pointer;
  display: block;
  font: font-default(16, normal, 1rem);
  padding: 0.625rem 0;
  padding-left: 0.625rem;
  input[type=checkbox],
  input[type=radio] {
    margin-right: 0.25em;
    &:checked + * {
      color: $color-grey-3;
    }
  }
}

button, input[type=button], input[type=submit] {
  @extend %button-text;
  border: 0;
}

// button[type=submit], input[type=submit], button.main {
//   @include button-text-colors($color-blue, $color-white);
// }

// button.disabled {
//   cursor: not-allowed;
//   @include opacity(0.25);
// }
