.AdminSelect{ margin:10px;cursor: pointer;color:#676767;position:absolute;z-index:4000;
  .AdminSelectItem {font-size: 14px;width:200px;cursor: pointer;}}

.AdminFamilyDefConf {background: blue;font-size: 15px;margin-right: 100px;font-weight: bold;}
    .AdminFamily,.AdminFamilyInput, .AdminFamilyList,.AdminFamilyButton, .AdminFamilyAddConf, .AdminFamilyBack {
    font-size: 15px;
    }
    .AdminFamilyTitle{
        font-size: 18px;
    }
    .adminContainer {
      margin-top: 10px;
      .last-removed {
        font-size: 12px;
        color: red;
      }
    }
    .SelectAdmin{
        margin-bottom: 5px;
        margin-top: 15px;
        input, select {
            width:200px;
        }
    }
    .pagination-box {
      display: inline;
      input {
        font-size: 8pt;
      }
      .pagination-dots {
        font-size: 22pt;
        vertical-align: bottom;
        line-height: 0.8;
      }
    }
    .dashboard-wrap-filters {
      .text-info {
        color: red!important;
        font-size: 10px;
      }
      .dashboard-filters {
        div {
          label {
            font-size: 16pt!important;
          }
          input {
            height: 30px;
            font-size: 12pt;
          }
          select {
            height: 30px;
            font-size: 12pt;
          }
        }
        .dashboard-filters-actions {
          button {
            font-size: 12pt;
          }
          input {
            height: 40px;
          }
        }

        .react-datepicker {
          font-size: 1.4em;
        }
        .react-datepicker__header {
          padding-top: 1.6em;
        }
        .react-datepicker__month {
          margin: 0.4em 1em;
        }
        .react-datepicker__day-name, .react-datepicker__day {
          width: 1.9em;
          line-height: 1.9em;
          margin: 0.166em;
        }
        .react-datepicker__current-month {
          font-size: 1em;
        }
        .react-datepicker__navigation {
          top: 1em;
          line-height: 1.7em;
          border: 0.45em solid transparent;
        }
        .react-datepicker__navigation--previous {
          border-right-color: #ccc;
          left: 1em;
        }
        .react-datepicker__navigation--next {
          border-left-color: #ccc;
          right: 1em;
        }
      }
    }
    .dashboard-alert {
      font-size: 14pt;
    }
    .lead-modal.container {
      margin: auto;
      bottom: auto;
      min-height: 250px!important;
      .lead-modal-header {
        text-align: center;
        padding: 15px;
        h2 {
          font-size: 18pt;
        }
      }
      .react-dropdown-select-content {
        input {
          font-size: 16pt;
          margin-left: 0rem!important;
          padding-left: 5px!important;
        }
        span {
          padding-left: 5px;
          font-size: 16pt;
        }
      }
      .react-dropdown-select-dropdown {
        font-size: 14pt;
        color: #585858;
        text-align: start;
      }
      .form-selection {
        min-height: 35px!important;
        .react-dropdown-select-option {
          padding-bottom: 5px;
          .react-dropdown-select-option-label {
            font-size: 12pt;
            margin-top: 7px!important;
          }
        }
      }
      .lead-modal-content {
        width: 350px;
        text-align: center;
        margin: auto;
        .actions-lead-wrap {
          margin-top: 20px;
          font-size: 11pt;
          color: grey;
          text-align: center;
          p {
            font-size: 14pt;
            font-weight: bold;
          }
          .actions-lead-button {
            button {
              font-size: 14pt;
              margin: 15px;
            }
          }
        }
        .caption {
          color: red;
          width: 350px;
          font-size: 10pt;
          margin-top: 10px!important;
        }
        .lead-list {
          .list-title {
            font-size: 12pt;
            margin-top: 10px;
            margin-right: 5px;
          }
          .list {
            margin-top: 10px;
            justify-content: flex-start;
            text-align: left;
          }
          .badge {
            padding: 5px;
            font-size: 10pt;
            margin-right: 5px;
            margin-top: 1px;
            cursor: pointer;
            i {
              vertical-align: middle;
              font-size: 10pt;
              margin-left: 5px;
            }
          }
        }
        .change-lead {
          width: 350px!important;
        }
        ul {
          margin-top: 10px!important;
          list-style-type: none;
          justify-content: flex-start!important;
          padding-inline-start: 5px;
          font-size: 12pt;
          color: grey;
          height: 250px;
          overflow-y: scroll;
          li {
            text-align: left!important;
            padding: 5px;
            cursor: pointer;
          }
          li:hover {
            background: rgba(0, 181, 204, 0.1)!important;
            border-radius: 2px;
          }
          .active-lead {
            background: rgba(0, 181, 204, 0.2)!important;
            border-radius: 2px;
          }
        }
      }
    }
    .react-dropdown-select-content {
      input {
        font-size: 14pt!important;
        margin-left: 0rem!important;
        padding-left: 5px!important;
        min-width: 40px!important;
      }
      span {
        font-size: 12pt;
        margin-top: 10px;
        padding-left: 5px;
      }
    }
    .form-selection {
      min-height: 30px!important;
      margin-top: -2px;
      margin-bottom: -3px;
    }
    .react-dropdown-select-clear {
      font-size: 16pt;
    }
    .dashboard-table {
      margin-top: 20px;
      tr {
        td {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 14pt;
          color: "#4D4D4D";
          button {
            font-size: 16pt;
          }
        }
        small {
          color: #585858;
          font-size: 11pt;
          font-weight: bold;
        }
      }
      th {
        button {
          background-color: rgba(0, 0, 0, 0);
          text-transform: none;
          border-style: none;
          color: white;
          font-size: 16pt;
          font-weight: bold;
          padding-left: 0;
          margin-left: 0;
        }
        i {
          vertical-align: middle;
          font-size: 12pt;
          padding-left: 5px;
        }
        input {
          vertical-align: middle;
          content: '';
          left: 0;
          top: 0;
          width: 1em;
          height: 1em;
          border: 1px solid #aaa;
          background: #FFF;
          border-radius: .2em;
          box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
          transition: all .275s;
        }
      }
    }
    .AdminFamilyBack{
        margin-top:5px;
    }
    .AdminFamilyBackArrow{
        float: left!important;
        padding-right: 10px;
    }
    .AdminFamilyList{
        margin-left:20px;
        .AdminFamilyAddConf {
            margin-top:30px;
            margin-bottom:30px;
        }
        .AdminFamilyHeader{
            margin-top:20px
        }
        .AdminFamilyButton{
          margin-top: 10px;
            margin-left:15px
        }
        .AdminFamilyInput{
            margin-left:20px;
            margin-top: 30px;
            margin-bottom: 30px;
            width:200px;
        }
    }

    .preview-box {
      position: inherit;
      height: 85%;
      width: 100%;
      background: rgba(226, 226, 226, 0.6);
      margin-top: 25px;
      bottom: 50px;
      overflow-y: scroll!important;
      i {
        position: fixed;
        float: right!important;
        right: 35px;
        top: 15px;
        cursor: pointer;
      }
      .list-box {
        .item-box {
          width: 150px;
          height: 150px;
          display: inline-block;
          margin: 10px 10px 10px 10px;
          border-style: solid;
          border-width: 1px;
          border-color: grey;
          text-align: center;
          font-weight: bold;
          font-size: 12pt;
          padding-top: 60px;
          overflow: auto;
        }
        .item-box-client {
          border-color: blue!important;
          border-width: 2px!important;
        }
      }
    }
    .AdminConfigurator, .AdminCatalog, .AdminMain, .AdminFamily {
        .admin-main-title {
          background: #fff;
          margin-bottom: 10px;
          font-size: 14pt;
          padding: 10px
        }
        .close-icon {
          font-size: 20pt;cursor: pointer;
          position: relative;float:right;margin-right:10px;
        }
        .show-icon {
          vertical-align: middle;
          font-size: 26pt;
          cursor: pointer;
        }
        .admin-list-restricted {
          padding: 0;
          // max-height: 400px;
          overflow: auto;
          overflow-x: hidden;
          .admin-list-wrapper {
            display: inline-flex;
            width: 100%!important;
            .admin-list-actions {
              display: inline-flex;
              background-color: white;
              border-bottom-style: solid;
              border-bottom-color: grey;
              border-bottom-width: 1px;
              i {
                font-size: 22pt;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
              }
              input {
                border-style: none;
                max-width: 40px;
                margin-left: 10px!important;
                border-radius: 0px;
                padding: 0px 0px;
                margin: 0px;
                background-color: whitesmoke;
              }
              button {
                margin-top: 0px;
                margin-bottom: 0px;
                border-style: none;
                border-radius: 0px;
              }
            }
            li {
              width: 100%!important;
            }
          }
        }
        .custom-content-render {
          width: 100%;
        }
        .search-monument-input {
          border-style: none;
          margin-left: 0px!important;
          padding: 0px 0px;
          margin: 0px;;
        }
        .search-monument-input:focus {
          outline: none!important;
        }
        .btn-secondary{
          background-color:black;
      }
        .admin-reorder, .admin-bookmarks {
          vertical-align: middle;
        }
        .admin-weight {
          vertical-align: middle;
          float: right;
          color: grey;
          margin-right: 15px;
          font-size: 16px;
        }
        .header-admin-principal {
          background-color: white;
          width: '100%';
          height: auto;
          border-bottom-style: solid;
          border-bottom-width: 1px;
          padding-left: 10px;
          padding-top: 20px;
          padding-bottom: 20px;
          .addMonument{position: absolute;right: 100px;cursor: pointer;
             .add{color: green; padding-right:5px;position:relative;top:5px}}
          .monumentSelectMain{z-index: 3000;}
          .AdminFilterButtons{ margin-left:5px;
            .FilterButton{font-size:16px;border-radius: 30px;margin-left: 10px}
          }
        }
        .admin-list-restricted-monument {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 12px;
          background-color: white;
          list-style-type: none;
          border-bottom-style: solid;
          border-width: 1px;
          background-color: rgba(250, 250, 250, 0.7);
          border-color: grey;
          cursor: grab;
          .delete-icon-granit {
            float: right;
            margin-right: 10px;
            cursor: pointer;
            font-size: 20pt;
          }
        }
    }
    .AdminConfigurator{

        .TabButton{
            background: black;
            color:white;
            font-size: 11px;
            text-transform: none;
            width:105%
        }
        .TabButton.inactif{
            background: grey;
        }
        .btn-secondary{
            background-color:black;
        }
        .SubtabButton.inactif{
            color:grey
        }

    }
