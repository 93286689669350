

.SelectionStyle {
  font-size:1.8em;
  margin-left:20px;
  line-height:40px;
    .HeaderSel {
      margin-top: 30px;
    }
    .ButtonSel {
      margin-top: 40px;
    }
    .SelAddConf{
      font-size:1em;
      background-color: #154247;
    }
    .sort{
      cursor: pointer;
    }
    .FullFontWidth {
      font-size:1em;
      width:100%
      }
    .FullFontWidthRo {
      font-size:1em;
      width:100%;
      background-color: #ccc;
      }      
    .SelImgMini{
      width:60px;
      display: inline-block;
      margin-left: 10px;
    }
    .SelError{
      color:red
    }
}
.SelectionClientCopy {font-size:1.8em;width: 300px; label{padding-left:5px}; input{font-size:0.9em}; span{font-size:1.4em} }
.react-confirm-alert{
  .SelDeleteConf{
    font-size:1.8em;
    button{
      font-size:1em;
    }
  }
}