.topFcatalog {
  .modal {
    top: 30%;
  }
}
.FfilterCatalog {
  background: white;
  font-size: 16px;
  color: $fc-lightgrey;
  text-align: center;
  display: flex;
  margin-top: 25px;
  // padding-top: 30px;
  // padding-bottom: 10px;
  // display: flex;
  // align-items: center;
  // justify-content: start;
  @media screen and (max-width: 768px) {
    display: unset;
    align-items: unset;
    justify-content: unset;
    padding-top: 10px;
    margin-top: 0;
  }
}
.fCatalogTopBar{
  height: 0;
  &.heightActive{
    height: 100px;
    @media screen and (max-width: 768px){
      height: 200px;
    }
  }

}
.goToInitChoiceBtn {
  font-family: 'sukhumvit', arial, helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 26px;
  right: 15px;
  cursor: pointer;
  height: 40px;
  @media screen and (max-width: 768px) {
    justify-content: flex-end;
    right: 4%;
    top: unset;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: auto;
    cursor: pointer;
    width: 100%;
  }
  span {
    font-size: 16px;
  }
  &:hover {
    /* color: var(--fcorange); */
    color: var(--f-main-color);
  }
}
.finitial-circle,
.svg-ffinalizationAsk {
  /* fill: var(--fcorange); */
  fill: var(--f-main-color);
}

.Fcatalog {
  background: none;
  /*padding-top:37px;*/
  @include smartphone {
    padding-top: 0;
  }
  .FfilterCatalog {
    .FilterButton {
      @include smartphone {
        font-size: 12px;
        height: 37px;
        padding-right: 5px;
        padding-left: 5px;
        height: 37px;
        margin-bottom: 5px;
      }
      font-size: 16px;
      font-family: $sukhumvitFont;
      background: #ffffff;
      border: 1px solid $fc-medgrey;
      border-radius: unset;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.5px;
      opacity: 1;
      text-transform: none;
      margin-left: 10px;
      height: 37px;
      &.active {
        background: var(--fcdarkstlblue);
        border: none;
        outline-color: unset;
      }
      &:hover {
        background: var(--fcdarkstlblue);
        color: #ffffff;
      }
    }
  }
  .MonumentCard{
    .Infos{
      .Headstone{
        font-family: $sukhumvitFont;
        font-weight: 400;
      }
      .Price{
        font-family: $sukhumvitFont;
        font-weight: bold;
        span{
          font-weight: 400;
        }
      }
      .Footstone,.Description{
        font-family: $sukhumvitFont;
        font-weight: bold;
      }
    }
  }
  .MonumentCard {
    .Featured,
    .FavoriteStar {
      display: none;
    }
    .Infos {
      font-weight: bold;
      width: 100%;
      .Price {
        position: absolute;
        right: 15px;
        bottom: 20px;
        font-size: 14px;
        color: $fc-grey;
      }
    }
    &:hover {
      /* border: 1px solid var(--fcorange); */
      border: 1px solid var(--f-main-color);
      box-shadow: none;
    }
    figure {
      padding-bottom: 0;
      min-height: 252px;
    }
  }
  .catalogA {
    .MonumentCard {
      &:hover {
        border: 1px solid var(--fcblue);
      }
    }
  }
  .FfilterMain {
    display: flex;
    justify-content: center;
    &.fFilterA {
      padding-top: 20px;
      .FfilterCatalog {
        .filterButtons {
          .FilterButton {
            border: 1px solid var(--fcblue);
            border-radius: 15px;
            color: var(--fcblue);
            &:hover {
              background-color: var(--fcblue);
              color: #ffffff;
            }
            &.active {
              background-color: var(--fcblue);
              color: #ffffff;
            }
          }
        }
      }
    }
    .search-filter-input-box {
      border-radius: 6px;
      border: 1px solid #707070;
      margin-left: 10px;
      padding: 4px;
      background-color: #fff;
      display: -webkit-inline-box;
      width: 400px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      i {
        vertical-align: middle;
        direction: unset;
        display: unset;
      }
      .search-filter-input {
        font-family: $sukhumvitFont;
        font-size: 16px;
        border-style: none;
        margin-left: 5px;
        padding-left: 0;
        width: 100%;
        height: 33px;
      }
      .search-filter-input:focus {
        outline: none;
      }
    }
    .filterButtons {
      @media screen and (max-width: 768px) {
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
      }
      .FilterButton {
        @media screen and (max-width: 768px) {
          margin-left: 0;
          float: left;
          margin-right: 5px;
          margin-top: 5px;
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
  }
}
.FfilterSelect {
  display: none;
  @include smartphone {
    display: block;
    font-size: 16px;
    padding: 14px;
    margin-bottom: 10px;
    .dropdownList {
      width: 100%;
      position: fixed;
      top: 145px;
      right: 0;
      z-index: 1000;
    }
  }
}

.familyspace-login-modal {
  // top: 279px!important;
  width: 530px !important;
  @include smartphone {
    width: unset !important;
  }
  margin-top: 50px !important;
  height: fit-content !important;
  margin: auto !important;
  padding-bottom: 15px !important;
  background: var(--unnamed-color-f4f4f4) 0% 0% no-repeat padding-box !important;
  background: #f4f4f4 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  @include smartphone {
    position: absolute;
    width: 100% !important;
    top: 0;
    height: 120% !important;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0;
    box-shadow: unset;
    margin-top: -78px !important;
    margin-bottom: 0 !important;
    z-index: 10000;
  }

  .invite-more {
    margin-left: 10%;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/18px Cuprum;
    letter-spacing: 0px;
    color: #3568ba;
    opacity: 1;
    cursor: pointer;
  }
  .invite-buttons {
    width: 80%;
    margin: auto;

    .validate {
      width: 206px;
      @include smartphone {
        width: 150px;
      }
      background: #3568ba 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      color: white;
      float: left;
    }
    .cancel {
      width: 206px;
      @include smartphone {
        width: 150px;
      }
      background: #e6e6e6 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      color: #0e0e0f;
      float: right;
    }
  }
  .CloseModal {
    span {
      text-transform: none !important;
      font-style: italic;
      color: #8c8e8e !important;
    }
    i {
      color: #8c8e8e !important;
      font-size: 18px !important;
      font-weight: bold !important;
    }
  }
}

.familyspace-member-invite-modal {
  width: 812px !important;
  height: auto; //295px!important;
  text-align: center;

  h3.invite {
    width: 80%;
    font-size: 30px;
    letter-spacing: 0px;
    color: var(--fcnavyblue);
    opacity: 1;
    margin-top: 50px;
    margin-left: 10%;
  }

  .invite-more {
    margin-left: 10%;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/18px Cuprum;
    letter-spacing: 0px;
    color: #3568ba;
    opacity: 1;
    cursor: pointer;
  }

  .invite-buttons {
    width: 80%;
    margin: auto;

    .validate {
      width: 206px;
      background: #3568ba 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      color: white;
      float: left;
    }
    .cancel {
      width: 206px;
      background: #e6e6e6 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      color: #0e0e0f;
      float: right;
    }
  }
}

.familyspace-member-name-modal {
  // height: 175px!important;
  text-align: center;
  margin-top: 50px !important;

  h3 {
    margin-top: 50px;
    text-align: left;
    margin-left: 10%;
  }

  input {
    width: 80%;
    margin-bottom: 10px;
  }

  button {
    width: unset;
    text-align: center;
    font: normal normal bold 18px/21px Cuprum;
    letter-spacing: -0.27px;
    color: #f5f5ff;
    padding-left: 15px;
    padding-right: 15px;
  }

  .validate {
    background: #3568ba 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
    margin-right: 5px;
  }
  .cancel {
    background: #a04c4b 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
    margin-left: 5px;
  }
}

.BurialCremation {
  position: fixed;
  width: 688px;
  background: #f4f4f4 !important;
  border-radius: 10px;
  padding-bottom: 10px !important;
  font-size: 30px;
  color: $fc-deepgrey;
  text-align: center;
  max-width: none;
  top: 48% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .modal-content {
    border: 0px;
    border-radius: 10px;
  }
  .title {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 576px){
      font-size: 20pt;
    }
  }
  .CloseModal {
    display: none;
  }
  .LogoFamily {
    max-height: 70px;
    margin-top: 10px;
    @media screen and (max-width: 576px) {
      margin-top: 25px;
    }
  }
  .EmptyImg {
    height: 70px;
    @media screen and (max-width: 576px) {
      height: 0;
      margin-top: 25px;
    }
  }
  .MonumentTypes {
    margin-left: 0;
    padding-bottom: 8px;
    margin-top: 70px;
    @media screen and (max-width: 576px) {
      margin-top: 15px;
      display: block;
      width: 100%;
      height: 65vh;
      overflow-y: hidden;
    }
    .TypeA {
      .finitial-circle,
      .svg-ffinalizationAsk {
        fill: var(--fcblue);
      }
    }
  }
  .TypeMonument {
    width: 200px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 15px;
    font-size: 20px;
    @media screen and (max-width: 576px) {
      margin-left: auto !important;
      margin-right: auto !important;
      margin-bottom: 10px;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 22px;
    cursor: pointer;
    /*@include smartphone {margin-left:25%;height:120px;margin-bottom:10px;font-size:18px}*/
    img {
      padding-bottom: 10px;
    }
    .title-type-monument {
      color: var(--f-main-color);
      font-weight: bold;
    }
    /*
    .blueTitle {
      color: var(--fcblue);
      font-weight: bold;
    }
    .orangeTitle {
      color: var(--fcorange);
      margin-top: -10px;
      font-weight: bold;
    }
    */
  }

  hr {
    margin-top: 10px;
    margin-bottom: 17px;
  }
  .AccessFamilySpace {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
    text-decoration: unset;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12pt;
    font-weight: bold;
    background-color: $color-white;
    color: var(--fcdarkstlblue);
    border: 1px solid var(--fcdarkstlblue);
    cursor: pointer;
    margin-top: 15px;
    .familyIcon{
      margin-right: 4px;
    }
    &:hover{
      color: $color-white;
      background-color: var(--fcdarkstlblue);
      .familyIcon{
        svg{
          path{
            fill: $color-white;
          }
        }
      }
    }
  }
  .videoFamily {
    display: none;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 154px;
    @media screen and (max-width: 576px) {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      top: 0;
      right: 0;
    }
    .videotext {
      font-size: 16px;
      color: #6a7684;
      text-decoration: underline;
      text-align: center;
    }
  }
}
.Family {
  .LoadingIcon {
    position: fixed;
    left: 40%;
    top: 35%;
    z-index: 9999;
    text-align: center;
  }
  .LoadingMonument {
    font-size: 24px;
    width: 250px;
    color: $fc-medgrey;
  }
}

.CatalogModalOverlay.Family .MonumentCarousel .Footer {
  background: none;
  top: 0;
  right: 0;
  bottom: unset;
  left: unset;
  height: 150px;
  .Infos {
    float: right;
    margin-top: 45px;
  }
  .Footstone {
    color: var(--fcblue);
    right: 15px;
    position: absolute;
  }
  .Actions,
  .Headstone,
  .Granite,
  .Description {
    display: none;
    font-family: $sukhumvitFont;
  }
  .Price {
    font-size: 14px;
    text-align: right;
    margin-top: 25px;
    color: $fc-lightgrey;
  }
}
.CatalogModalOverlay.Family .CloseModal span {
  font-style: italic;
  text-transform: none;
  color: $fc-lightgrey;
}
.CatalogModalOverlay.Family .CloseModal i {
  color: $fc-lightgrey;
}
.CatalogModalOverlay.Family .ReactModal__Content {
  margin-top: 50px;
}
.ReactModal__Overlay.BurialCremationOverlay {
  .BurialCremation {
    font-family: $sukhumvitFont;
    height: 410px;
    @media screen and (max-width: 576px) {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 0;
    }
    .AccessSpace {
      width: 100%;
      height: 36px;
      border-top: 1px solid #e6e6e6;
      margin-top: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      font-family: $sukhumvitFont;
      font-size: 18pt;
      @media screen and (max-width: 576px){
        // bottom: 15px;
        position: relative;
      }
      .LeftOvalAction {
        margin-top: 10px;
      }
      hr {
        @media screen and (max-width: 576px) {
          margin-bottom: unset;
          position: fixed;
        }
      }
    }
    .title {
      font-family: $RalewayFont;
      font-weight: bold;
      color: var(--fcdarkstlblue);
      @media screen and (max-width: 576px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  background: rgba(42, 48, 50, 0.5);
  @media screen and (max-width: 576px) {
    height: 103%;
  }
}

.CatalogModalOverlay {
  .Carousel .Next,
  .Carousel .Previous {
    @include NextPrevious();
  }
}
