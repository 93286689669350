.ReactModal__Content.ReactModal__Content--after-open.FamilyCreateSpaceModal {
  position: fixed;/*top: -70px;*/left: 0;right: 0;bottom: 0;margin: unset;width: 100%;height: 100%;border: unset;border-radius: unset;
  @media screen and (max-width:576px){
    top:0;
  }
  /*margin: auto;height:fit-content;width:fit-content;border-radius: 10px;*/}
.FamilyCreateSpaceModal {
 div.CloseModal{font-size:15px;font-style: italic;color:$fc-lightgrey;text-transform: unset;padding:5px}
 .ScreateSpace{
   overflow-y: auto;
   overflow-x:hidden;
   height: 100%;
   width:100%;
   background: #F4F4F4;
   border-radius: unset;
   padding-top:35px;
   @media screen and (min-width:576px) and (min-height: 759px){
     height: 115%;
   }
     .clientLogo {
       height: 70px;
       display: block;
       margin-left: auto;
       margin-right: auto;
     }
     .ScreateTitle{font-size:30px;color:$fc-deepgrey;text-align: center;margin-top: 50px;margin-bottom: 22px;}
     .actions{display: flex; justify-content: center; text-align: center;
       @media screen and (max-width: 576px){width: 90%;}
       max-width: 500px; margin-left: auto; margin-right: auto;margin-bottom: 5px;
      button{
        padding: unset;
        @media screen and (max-width: 576px){width: 100%;}
        /*margin-bottom: 5px;*/
        margin: unset;
        /*border: 1px solid #cccccc;*/
        &.Grey{
          &:hover{
            background-color: #545454;
            color: #FFFFFF;
          }
        }
      }
     }
     button{width:206px;font-size: 18px;border-radius: 2px;border-radius: 50px;text-transform: unset;
     &.validate{
       background: var(--f-main-color);
       color:var(--f-font-color);
       margin-right:16px;
       &:hover:not([disabled]) {
         background-color: var(--f-font-color);
         color: var(--f-main-color);
       }
      
    }
     &.cancel{background: $fc-whitegrey;color: $fc-blackish;}}
     .connect{font-size:18px;color:var(--fcnavyblue);text-decoration: underline;text-align: center;cursor: pointer;margin-bottom:20px;}
 }
}

.ReactModal__Content.ReactModal__Content--after-open.ClientCreateSpaceModal{margin-left:auto;margin-right:auto;height:fit-content;width:fit-content}
.ClientCreateSpaceModal{
  margin-left: auto;margin-right: auto;
  .ScreateSpaceConfig{width: 768px;background: #FFFFFF;padding-bottom:37px;
    .ScreateTitle{width:100%;background: #EDEDED;font-size:30px;color:$fc-blueish;text-align: center;padding:16px;
                  margin-bottom: 40px;}
    .actions{text-align: center;}
    button{width:236px;text-transform: uppercase;font-size: 17px;border-radius: 2px;
      &.validate{background: $fc-blueish;color:#FFFFFF;margin-right:16px}
      &.cancel{background: $fc-whitegrey;color: $fc-greyish;}
      
    }
  }
}
.BlockCreate{margin-bottom:15px;text-align: center;margin-left: auto;margin-right: auto;width: 90%;
  max-width: 500px;
  .TitleInputCreate{color: #3B4752;font-size: 16px;margin-bottom:4px;
                    text-align: left;}

  .InputCreate {display: inline-block;width: 100%;height:40px;position: relative;
    border: 1px solid #E4E6F0;background: #FFFFFF;
    input{border:none;background: none; width: 88%; height: 100%; margin: 0; float: left;&:focus{outline: none;}}
    i{ position: absolute;margin-top: 6px;color:$fc-lightgrey;float: right;}
    }
    .InputCreate.error {
     border: 1px solid red!important;
    }
  }
  .OverallInvitation{
    background-color: #FFFFFF;height: 100%;padding-top: 50px;
    .MainInvitation{
      margin-left: auto;margin-right: auto;text-align: center;width: 812px;@include smartphone{width:unset}
      .WelcomeInvite{font-size:30px; .Intro{margin-top:20px;margin-bottom: 30px;}@include smartphone{font-size:20px;}}
      .WelcomeSubBlock{font-size:17px;margin-bottom:30px;@include smartphone{display:none}}
      .WelcomeDetail1{font-weight: bold;margin-bottom:20px;padding-top: 10px;}
      .WelcomeDetail2{font-style: italic;}
      .invite{color:#2A3032;font-size:30px; padding-top:30px;padding-bottom: 26px;}
      .LeftOvalAction{width: 206px;padding: unset}
      .PasswordBlock{
        height:318px;
        background: #F4F4F4;
        border-radius: 10px;
        .actions{
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            &:hover:not([disabled]) {
              // background-color: var(--f-font-color);
              // color: var(--f-main-color);
            }
          }
        }
      }
    }
  }
