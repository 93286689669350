.ReactModal__Content.ReactModal__Content--after-open.ClientModal{ margin-left:auto;margin-right:auto; 
  height:fit-content;width:768px;background: #FFFFFF;}
  .ReactModal__Content.ReactModal__Content--after-open.ClientErrorModal{margin-left:auto;margin-right:auto; 
    height: 200px; width: 400px;text-align:center;
    .errorContent{font-size: 20px;padding-top: 80px;color: #676767;font-weight: bold;}
  }

// .SClientSpace{
//   .saveFspaceButton{
//     button{position:absolute;right:257px;width:383px;height:57px;background:$fc-blueish;border-radius: 2px;
//           margin:6px 10px 6px 10px;font-size: 1.6em;}
//     i{padding-top:5px}
//   }
// } 

.saveFspaceButton{
  float: left;
  margin: 11px 9px;
  display: flex;
  align-items: center;
}

.ClientTitleBlock{height:70px;background: #EDEDED;color: $fc-blueish;font-size:30px;text-align: center;
                  margin-bottom:40px;
                .ClientTitle{padding:17px}
                i{position:absolute;top:8px;right:14px;font-size:12px;color: $fc-greyish;cursor: pointer;}}
.ClientSearchSpace{margin-bottom:40px;
  .header{text-transform: uppercase;font-size: 16px;font-weight: bold;margin-left:77px;margin-bottom: 5px;}
  .SearchInputTitle{margin-left:77px;color: #3B4752;text-transform: uppercase;font-size:16px;margin-bottom: 4px;}
    input{width:425px;margin-left:77px;border:1px solid #E4E6F0;border-radius: 2px; font-size:14px;color: $fc-lightgrey;}
    button{margin-left:16px;background: $fc-whitegrey;border-radius: 2px;color: $fc-greyish;text-transform: uppercase;
           font-size: 17px;height:40px;width:173px}}
.ClientConfirmSave{margin-bottom:40px;text-align:center;
  .ConfirmBlock{width:614px;height:60px;margin-left: auto;margin-right:auto;margin-bottom:20px;color: #3B4752;font-size:16px;
                border-top:1px solid $fc-whitegrey;border-bottom:1px solid $fc-whitegrey;padding:10px 0px 10px 0px;
              .Found{
                .idBlock{float:left;text-align: left;}
                div{margin-bottom: 4px;}
                .name{text-transform: uppercase;}
                button{float:right;background:$fc-blueish;color:#FFFFFF;font-size:17px}}
              }
              .fileUpload{color: #3B4752;font-size:16px;margin-top:5px}
              .Found.Create{button{background: #76AF2D}}
              .SearchAgain{cursor: pointer;color: $fc-blueish;font-size:16px;text-decoration: underline;
                           float: left;margin-top: 15px;border-top: 1px solid #E6E6E6;width:100%;padding:15px}
              .InputBlock{float:left;text-align: left;width:100%;padding-top: 15px;}
              .upLoad{float:right}
              .quoteChoice{input{margin-right:10px}}
            }
.ClientSaved{text-align: center;
  .title{height:70px;background: #EDEDED;color: $fc-blueish;font-size:30px;text-align: center;padding-top: 16px;margin-bottom:30px;}
  .title.Error{color:red}
  i{position:absolute;top:8px;right:14px;font-size:14px;color: $fc-greyish;cursor: pointer;}
  img{margin-bottom:15px}
  .text_fs_project_saved{color:#665F65;font-size: 17px;text-align: center;margin-bottom:25px}
  .text_fs_project_saved.Error{color:red}
  button{background: #E6E6E6;border-radius: 40px;height: 40px;width: 206px;margin-bottom:30px}}