.ListView {
  @extend %columns;
  @include smartphone {
    margin: 0 !important;
  }
  .ListViewContainer {
    margin: 0 10px;
  }
  .ListViewChunk {
    @extend %columns;
  }
  .ListViewItem {
    @include column(left);
    width: 25%;


    @media only screen and (max-width: 1280px) {
      width: 33.333333%;
    }

    @media only screen and (min-width: 1980px) {
      width: 25%;
    }

    @include smartphone { // max-width 800
      width: 100% !important;
    }
  }
  .ListViewItemContainer {
    margin: 12px 12px;
  }
}

.ScrollListView {
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 0;
  padding: 0 40px;
  .ListView {
    margin: 10px;
  }
}

.FrameGranitePickerPopupModal, .VeneerGranitePickerPopupModal, .AccessoriesGranitePickerPopupModal, .AccessoryGranitePickerPopupModalOverlay{
  .ScrollListView {
    top: 150px;
    @include smartphone { // max-width 800
      top: 230px;
    }
  }
  ._FfilterGranit {
    // background: #F4F4F4;
    // font-size: 16px;
    // color: #8C8E8E;
    // text-align: -webkit-right;
    // padding: 10px 25px;
    // z-index: 1;
    // position: relative;
    .wrapper .search-icon {
      height: 24px;
      width: 24px;
    }
  }
  .SearchGranit{
    height: 30px;
    background: var(--fcblue);
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
    margin-left: 20px;
    float: right;
  }
  .dGbqhr > input{
      text-transform: uppercase;
  }
  .sc-bwzfXH.bWZouD > ul > li{
      cursor: pointer;
  }
}