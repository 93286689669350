figure {
  @extend %reset;
  &.FullSize {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    img:not(.configfLoader) {
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      overflow: auto;
      position: absolute;
      top: 0;
      right: 0;
      zoom: 4;
      -ms-zoom: 1; // Problèmes avec IE
    }
  }
}

img {
  @extend %responsive-image;
}
.loaderdiv{
  width: 100%;
  height: 100%;
  position: absolute;
  .loader{
    width: 0.75rem !important;
  }
}

.custom-loaderdiv {
  .loader{
    margin: 0 40% !important;
    width: 60px !important;
  }
}
figure.LazyImageB {

  /*&:before {
    background: $color-white url("../../images/loader-config-500-new.gif") no-repeat center center;
    background-size: 25%;
    bottom: 0;
    content: '';
    left: 0;
    @include opacity(0);
    position: absolute;
    right: 0;
    @include transition(all 0.15s ease);
    top: 0;
    z-index: 500;
    @mixin mdpi {
      background-image: url("../../images/loader@4x.gif");
      background-size: 25% 25%;
    }
  }*/
  img {
    @include opacity(1);
    @include transition(all 0.15s ease);
    z-index: 600;
    &.LowResolution {
      @include opacity(0);
    }
    &.small{ margin-top: -5px};
    &.middle{ margin-top: -15px};
    &.big{ margin-top: -25px};
  }
  &.loading {
    &:before {
      @include transition(all 1s ease);
      @include opacity(1);
    }
    img:not(.configfLoader) {
      @include opacity(1);
    }
  }
  &.hasLowResolution {
    img.LowResolution {
      @include opacity(0);
      z-index: 600;
    }
    &.loading img.LowResolution {
      @include opacity(1);
    }
    &:before {
      background-color: rgba(255, 255, 255, 0.75);
      @include border-radius(100%);
      bottom: auto;
      height: 50px;
      margin: -25px;
      left: 50%;
      right: auto;
      top: 50%;
      width: 50px;
      z-index: 601;
    }
  }
  &.monumentImg{
    .configfLoader{
      position: absolute;
      left: calc(50% - 2vw);
    }
  }
}

figure.LazyImage {

  &:before {
    background: $color-white url("../../images/loader.gif") no-repeat center center;
    // background: $color-white url("../../images/loader-config-500-new.gif") no-repeat center center;
    // background-size: 25% 25%;
    bottom: 0;
    content: '';
    left: 0;
    @include opacity(0);
    position: absolute;
    right: 0;
    @include transition(all 0.15s ease);
    top: 0;
    z-index: 500;
    @mixin mdpi {
      background-image: url("../../images/loader@4x.gif");
      background-size: 25% 25%;
    }
  }
  img {
    @include opacity(1);
    @include transition(all 0.15s ease);
    z-index: 600;
    &.LowResolution {
      @include opacity(0);
    }
    &.small{ margin-top: -5px};
    &.middle{ margin-top: -15px};
    &.big{ margin-top: -25px};
  }
  &.loading {
    &:before {
      @include transition(all 1s ease);
      @include opacity(1);
    }
    img {
      @include opacity(0);
    }
  }
  &.hasLowResolution {
    img.LowResolution {
      @include opacity(0);
      z-index: 600;
    }
    &.loading img.LowResolution {
      @include opacity(1);
    }
    &:before {
      background-color: rgba(255, 255, 255, 0.75);
      @include border-radius(100%);
      bottom: auto;
      height: 50px;
      margin: -25px;
      left: 50%;
      right: auto;
      top: 50%;
      width: 50px;
      z-index: 601;
    }
  }
}

figure.Monument {
  overflow: hidden;
  img {
    @include transform(scale(1.05) translate(6%, 2.5%));
    @include smartphone {
      @include transform(scale(1.05) translate(15%, 15%));
    }
  }
  &.FullSize {
    img {
      @include transform(scale(1.0) translate(6%, 1.5%));
    }
  }
}
